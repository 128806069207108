import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Players from "views/rules/Players.js";
import Parents from "views/rules/Parents.js";

export default function Rules() {
  return (
    <>
      {/* <Sidebar /> */}
      <div className="relative md:ml-0 bg-blueGray-100">
        <IndexNavbar />
        <div className="relative bg-lightBlue-100 :pt-32 pb-16 pt-12">
          <div className="px-4 md:px-10 mx-auto w-full">
          </div>
        </div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/rules/players" exact component={Players} />
            <Route path="/rules/parents" exact component={Parents} />
            <Redirect from="/rules" to="/rules/players" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
