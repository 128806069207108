import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createPopper } from "@popperjs/core";
import { changeCurrentTeam } from "teams/teamSlice";
import { showTeams } from "teams/teamSlice";
import { changeCurrentPlayer } from "developments/developmentSlice";

class IndexDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      dropdownPopoverShow: false,
      playerPopoverShow: false
    }; 
  }

  componentDidMount() {
    const { currentUser, teams } = this.props;
    if (currentUser) {
      if (currentUser.teams && (!teams || !teams.length)) {
        this.props.dispatch(showTeams());
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    if (currentUser && currentUser !== prevProps.currentUser) {
      if (currentUser.teams) {
        this.props.dispatch(showTeams());
      }
    }
  }

  setDropdownPopoverShow = dropDownShow => {
    this.setState({
      dropdownPopoverShow: dropDownShow
    });
  }

  setPlayerPopoverShow = dropDownShow => {
    this.setState({
      playerPopoverShow: dropDownShow
    });
  }

  handleChangeCurrentTeam = team => {
    this.props.dispatch(changeCurrentTeam(team));
  }

  handleChangeCurrentPlayer = player => {
    this.props.dispatch(changeCurrentPlayer(player));
  }

  render() {
    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();
    const openDropdownPopover = () => {
      createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start",
      });
      this.setDropdownPopoverShow(true);
    };
    const closeDropdownPopover = () => {
      this.setDropdownPopoverShow(false);
    };

    const playerDropdownRef = React.createRef();
    const playerPopoverDropdownRef = React.createRef();
    const openPlayerPopover = () => {
      createPopper(playerDropdownRef.current, playerPopoverDropdownRef.current, {
        placement: "bottom-start",
      });
      this.setPlayerPopoverShow(true);
    };
    const closePlayerPopover = () => {
      this.setPlayerPopoverShow(false);
    };

    const { currentUser, teams } = this.props;
    // const currentUser = {teams: ['123', '456']};
    // const teams = [{id:'123', name:'pasc'}, {id:'456', name:'mvla'}];
    var teamLinks;
    if (currentUser && currentUser.teams && currentUser.teams.length > 0 && teams && teams.length > 0) {
      const teamWithName = currentUser.teams.map(team => teams.find(cur => cur.id === team)).filter(team => team !== undefined);
      if (teamWithName && teamWithName.length) {
        teamLinks = teamWithName.map((team) => <Link to={`/games`} key={team.id} onClick={() => (this.handleChangeCurrentTeam(team.id))}
          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">{team.name}</Link>)
      }
    }
    var playerLinks;
    if (currentUser && currentUser.players && currentUser.players.length > 0) {
      const playerDevelop = currentUser.players.filter(player => player.club !== 'Falinks');
      playerLinks = playerDevelop.map((player) => <Link to={`/players`} key={player.id} onClick={() => (this.handleChangeCurrentPlayer(player.id))}
          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">{player.name}</Link>)
    }
    return (
      <>
        <a
          className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
          href="#team"
          ref={btnDropdownRef}
          onClick={(e) => {
            e.preventDefault();
            this.state.dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
          }}
        >
          <i className="text-blueGray-400 fas fa-video text-lg leading-lg mr-2" />{" "}
          Games
        </a>
        <div
          ref={popoverDropdownRef}
          className={
            (this.state.dropdownPopoverShow ? "block " : "hidden ") +
            "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
          }
        >
         {teamLinks}
        </div>
        <a
          className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
          href="#team"
          ref={playerDropdownRef}
          onClick={(e) => {
            e.preventDefault();
            this.state.playerPopoverShow ? closePlayerPopover() : openPlayerPopover();
          }}
        >
          <i className="text-blueGray-400 fas fa-chalkboard-teacher text-lg leading-lg mr-2" />{" "}
          Players
        </a>
        <div
          ref={playerPopoverDropdownRef}
          className={
            (this.state.playerPopoverShow ? "block " : "hidden ") +
            "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
          }
        >
         {playerLinks}
        </div>
      </>
    );
  };
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  currentTeam: state.teams.currentTeam,
  teams: state.teams.teams
});

export default connect(mapStateToProps)(IndexDropdown);
