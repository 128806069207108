import React from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import { forwardRef } from 'react';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { changeUserId } from "engagements/engagementSlice";
import { changeUserName } from "engagements/engagementSlice";
import { changeMonth } from "engagements/engagementSlice";
import { changeTeamId } from "engagements/engagementSlice";
import { changeSignup } from "engagements/engagementSlice";
import { changeParticipation } from "engagements/engagementSlice";
import { changePayments } from "engagements/engagementSlice";
import { changeVolunteers } from "engagements/engagementSlice";
import { removeEngagement } from "engagements/engagementSlice";
import { changeUserEmail } from "engagements/engagementSlice";
import { changeUserPhone } from "engagements/engagementSlice";
import { formatDate } from "utils/utils";
import { formatDateArray } from "utils/utils";
import { formatPaymentArray } from "utils/utils";
import { formatVolunteerArray } from "utils/utils";
import { showEngagements } from "engagements/engagementSlice";
import { changeId } from "engagements/engagementSlice";
import { changeNumOfPlayers } from "engagements/engagementSlice";
import { formatNumArray } from "utils/utils";

const tableIcons = {
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
};

class EngagementTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      selectedRow: null
    }; 
  }

  componentDidMount() {
    const { currentUser } = this.props;
    if (currentUser) {
      if (currentUser.roles) {
        if (currentUser.roles.includes('ADMIN')  || currentUser.roles.includes('MANAGER')) {
          this.props.dispatch(showEngagements(currentUser.teams));
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    if (currentUser && currentUser !== prevProps.currentUser) {
      if (currentUser.roles) {
        if (currentUser.roles.includes('ADMIN')  || currentUser.roles.includes('MANAGER')) {
          this.props.dispatch(showEngagements(currentUser.teams));
        }
      }
    }
  }

  setSelectedRow = row => {
    this.setState({
      selectedRow: row
    });
    const { engagements } = this.props;
    const engagement = engagements[row];
    this.props.dispatch(changeId(engagement.id));
    this.props.dispatch(changeUserId(engagement.userId));
    this.props.dispatch(changeUserName(engagement.userName));
    this.props.dispatch(changeUserEmail(engagement.userEmail));
    this.props.dispatch(changeUserPhone(engagement.userPhone));
    this.props.dispatch(changeMonth(engagement.month));
    this.props.dispatch(changeTeamId(engagement.teamId));
    this.props.dispatch(changeSignup(engagement.datesOfSignup));
    this.props.dispatch(changeParticipation(engagement.datesOfParticipation));
    this.props.dispatch(changePayments(engagement.payments));
    this.props.dispatch(changeVolunteers(engagement.volunteers));
    this.props.dispatch(changeNumOfPlayers(engagement.numOfPlayers));
  }

  clickDelete = event => {
    this.props.dispatch(removeEngagement(event))
  }

  render() {
    const title = "Engagements";
    const columns = [
      { title: 'id', field: 'id', hidden: true },
      { title: 'userId', field: 'userId', hidden: true },
      { title: 'userName', field: 'userName' },
      { title: 'userEmail', field: 'userEmail' },
      { title: 'userPhone', field: 'userPhone' },
      { title: 'month', field: 'month' },
      { title: 'teamId', field: 'teamId', hidden: true },
      { title: 'signup', field: 'datesOfSignup' },
      { title: 'join', field: 'datesOfParticipation' },
      { title: 'payments', field: 'payments' },
      { title: 'volunteers', field: 'volunteers' },
      { title: 'numOfPlayers', field: 'numOfPlayers' }
    ];
    const { engagements, error } = this.props;
    const data = engagements ? engagements.map(engagement => {
      return {
        id: engagement.id,
        userId: engagement.userId,
        userName: engagement.userName,
        userEmail: engagement.userEmail,
        userPhone: engagement.userPhone,
        month: formatDate(engagement.month + 86400 * 1000),
        teamId: engagement.teamId,
        datesOfSignup: formatDateArray(engagement.datesOfSignup),
        datesOfParticipation: formatDateArray(engagement.datesOfParticipation),
        payments: formatPaymentArray(engagement.payments),
        volunteers: formatVolunteerArray(engagement.volunteers),
        numOfPlayers: formatNumArray(engagement.numOfPlayers)
      }
    }) : [];
    const tableActions = [
      {
        icon: () => <DeleteIcon />,
        tooltip: 'Delete Entry',
        onClick: (event, rowData) => this.clickDelete(rowData)
      }
    ];
    
    const { selectedRow } = this.state;
    return (
      <div style={{ maxWidth: "100%", paddingTop: "12px" }}>
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        icons={tableIcons}
        actions={tableActions}
        onRowClick={((evt, selectedRow) => this.setSelectedRow(selectedRow.tableData.id))}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF"
          },
          rowStyle: 
            rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
          padding: 'dense',
          actionsColumnIndex: -1,
          pageSize: 10
        }}
      />
      <div className="text-red-500 text-center">{error}</div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  engagements: state.engagements.engagements,
  error: state.engagements.error
});

export default connect(mapStateToProps)(EngagementTable);
