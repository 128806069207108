import React from "react";
import { connect } from "react-redux";
import TeamInfo from "components/Cards/TeamInfo.js";
import TeamTable from "components/Cards/TeamTable.js";
import { addTeam } from "teams/teamSlice";
import { updateTeam } from "teams/teamSlice";
import { changeNewMember } from "teams/teamSlice";
import { addMember } from "teams/teamSlice";
import { removeMember } from "teams/teamSlice";
import { changeNewPlayer } from "teams/teamSlice";
import { removePlayer } from "teams/teamSlice";
import { addPlayer } from "teams/teamSlice";
import PlayerTable from "components/Cards/PlayerTable";

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      button: 1
    }; 
  }

  handleChangeNewMember = event => {
    event.preventDefault();
    this.props.dispatch(changeNewMember(event.target.value));
  }

  handleChangeNewPlayer = event => {
    event.preventDefault();
    this.props.dispatch(changeNewPlayer(event.target.value));
  }

  handleSubmit = event => {
    event.preventDefault();
    const { name, id, city, state, playerBirthYear, practiceField, competitiveLevel, coach, coachEmail, manager, managerEmail, newMember, newPlayer } = this.props;
    if (this.state.button === 1) {
      this.props.dispatch(addTeam({
        name: name, city: city, state: state, playerBirthYear: playerBirthYear, practiceField: practiceField, 
        competitiveLevel: competitiveLevel, coach: coach, coachEmail: coachEmail, manager: manager, managerEmail: managerEmail
      }));
    } else if (this.state.button === 2) {
      this.props.dispatch(updateTeam({
        name: name, id: id, city: city, state: state, playerBirthYear: playerBirthYear, practiceField: practiceField, 
        competitiveLevel: competitiveLevel, coach: coach, coachEmail: coachEmail, manager: manager, managerEmail: managerEmail
      }));
    } else if (this.state.button === 3) {
      this.props.dispatch(addMember({id: id, member: newMember}));
    } else if (this.state.button === 4) {
      this.props.dispatch(removeMember({id: id, member: newMember}));
    } else if (this.state.button === 5) {
      this.props.dispatch(addPlayer({id: id, player: newPlayer}));
    } else {
      this.props.dispatch(removePlayer({id: id, player: newPlayer}));
    }
  }

  render() {
    const { id, currentUser, newMember, newPlayer, aError } = this.props;
    var addTeam = false;
    if (currentUser && currentUser.roles) {
      if (currentUser.roles.includes('ADMIN')) {
        addTeam = true;
      }
    }
    return (
      <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={this.handleSubmit}>
                <TeamInfo />
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-2 py-5">
                    <div className="relative w-full mb-1 text-center">
                    {(addTeam) && <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
                        type="submit" name="add" onClick={() => (this.setState({button: 1}))}
                      >
                        Add
                      </button>}
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ml-2 mr-2 ease-linear transition-all duration-150"
                        type="submit" name="add" onClick={() => (this.setState({button: 2}))}
                      >
                        Update
                      </button>
                    </div>
                    <div className="relative w-full mt-4  mb-2 text-center">
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="New Member"
                        name="newMember" 
                        value={newMember} onChange={this.handleChangeNewMember} 
                      />
                    </div>
                    <div className="relative w-full mb-1 text-center">
                       <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ml-2 mr-2 ease-linear transition-all duration-150"
                        type="submit" name="add" onClick={() => (this.setState({button: 3}))}
                      >
                        <i className="text-white-400 fas fa-user-plus text-lg leading-lg mr-2" />
                      </button>
                       <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ml-2 mr-2 ease-linear transition-all duration-150"
                        type="submit" name="add" onClick={() => (this.setState({button: 4}))}
                      >
                        <i className="text-white-400 fas fa-trash text-lg leading-lg mr-2" />
                      </button>
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Member
                      </label>
                    </div>
                    <div className="relative w-full mt-4  mb-2 text-center">
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="New Player"
                        name="newPlayer" 
                        value={newPlayer} onChange={this.handleChangeNewPlayer} 
                      />
                    </div>
                    <div className="relative w-full mb-1 text-center">
                       <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ml-2 mr-2 ease-linear transition-all duration-150"
                        type="submit" name="add" onClick={() => (this.setState({button: 5}))}
                      >
                        <i className="text-white-400 fas fa-user-plus text-lg leading-lg mr-2" />
                      </button>
                       <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ml-2 mr-2 ease-linear transition-all duration-150"
                        type="submit" name="add" onClick={() => (this.setState({button: 6}))}
                      >
                        <i className="text-white-400 fas fa-trash text-lg leading-lg mr-2" />
                      </button>
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Player
                      </label>
                    </div>
                    <div className="text-red-500 text-center">{aError}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-4">
          <TeamTable />
        </div>
        <div className="w-full lg:w-6/12 px-4">
          <PlayerTable  teamId={id} />
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  name: state.teams.name,
  id: state.teams.id,
  city: state.teams.city,
  state: state.teams.state,
  playerBirthYear: state.teams.playerBirthYear,
  practiceField: state.teams.practiceField,
  competitiveLevel: state.teams.competitiveLevel,
  coach: state.teams.coach,
  coachEmail: state.teams.coachEmail,
  manager: state.teams.manager,
  managerEmail: state.teams.managerEmail,
  newMember: state.teams.newMember,
  newPlayer: state.teams.newPlayer,
  teamList: state.teams.teamList,
  error: state.teams.error,
  aError: state.teams.aError
});

export default connect(mapStateToProps)(Team);
