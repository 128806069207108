import React from "react";

// components

import ParentRule from "components/Cards/ParentRule.js";

export default function Parents() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <ParentRule />
        </div>
      </div>
    </>
  );
}
