import React from "react";
import { connect } from "react-redux";

import DevSide from "components/Sidebar/DevSide.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import CardCanvas from "components/Cards/CardCanvas";
import CardPreview from "components/Cards/CardPreview";
import Cookies from 'universal-cookie';
import { getCurrentUser } from "users/userSlice";
import { showDevelopments } from "developments/developmentSlice";
import { changeCurrentMatch } from "developments/developmentSlice";

class Sessions extends React.Component {
  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    if ((!currentUser || !currentUser.roles) && token) {
      this.props.dispatch(getCurrentUser(token));
    }
  }

  componentDidMount() {
    const { currentUser, currentPlayer, currentMonth } = this.props;
    if (currentUser && currentPlayer && currentMonth) {
      this.props.dispatch(showDevelopments({time: currentMonth, playerId: currentPlayer}));
      this.props.dispatch(changeCurrentMatch(0));
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser, currentPlayer, currentMonth } = this.props;
    if (currentUser && currentPlayer && currentMonth && (currentPlayer !== prevProps.currentPlayer || 
      currentUser !== prevProps.currentUser || currentMonth !== prevProps.currentMonth )) {
      this.props.dispatch(showDevelopments({time: currentMonth, playerId: currentPlayer}));
      this.props.dispatch(changeCurrentMatch(0));
    }
  }

  render() {
    const { currentMatch, curMatches } = this.props;

    var shareLink, wipLink, learnLink, advice;
    if (curMatches && currentMatch < curMatches.length) {
      shareLink = curMatches[currentMatch].shareLink;
      wipLink = curMatches[currentMatch].wipLink;
      learnLink = curMatches[currentMatch].learnLink;
      advice = curMatches[currentMatch].advice;
    }

    return (
      <>
      <DevSide />
      <div className="relative md:ml-64 bg-blueGray-100">
        {/* <IndexNavbar /> */}
        <div className="relative bg-lightBlue-100 :pt-32 pb-4 pt-16">
          <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-4 text-center">
            Session
          </div>
          <div className="flex w-full xl:w-12/12 mb-4 xl:mb-0 px-4 justify-center">
            <CardCanvas />
          </div>
          <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-4 text-center">
            Script
          </div>
          <div className="flex w-full xl:w-12/12 mb-4 xl:mb-0 px-4 justify-center">
            <CardPreview url={"http://"} />
          </div>
        </div>
        <FooterAdmin />
      </div>
    </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  currentPlayer: state.developments.currentPlayer,
  currentMonth: state.developments.currentMonth,
  currentMatch: state.developments.currentMatch,
  curMatches: state.developments.curMatches,
  error: state.developments.error
});

export default connect(mapStateToProps)(Sessions);
