import React from "react";
import { connect } from "react-redux";
import EventInfo from "components/Cards/EventInfo.js";
import PlayInfo from "components/Cards/PlayInfo.js";
import EventTable from "components/Cards/EventTable.js";
import { updateEvent } from "events/eventSlice";
import { playAdded } from "events/eventSlice";
import { scheduleAdded } from "events/eventSlice";
import ScheduleInfo from "components/Cards/ScheduleInfo";
import { statsAdded } from "events/eventSlice";
import StatsInfo from "components/Cards/StatsInfo";
import { participantAdded } from "events/eventSlice";
import ParticipantInfo from "components/Cards/ParticipantInfo";
import { showTeams } from "teams/teamSlice";
import { addAllPlayers } from "events/eventSlice";

class Event extends React.Component {

  handleParticipantAdded = event => {
    event.preventDefault();
    this.props.dispatch(showTeams());
    this.props.dispatch(participantAdded());
  }

  handlePlayAdded = event => {
    event.preventDefault();
    this.props.dispatch(playAdded());
  }

  handleScheduleAdded = event => {
    event.preventDefault();
    this.props.dispatch(scheduleAdded());
  }

  handleStatsAdded = event => {
    event.preventDefault();
    this.props.dispatch(statsAdded());
  }

  handleAddAllPlayers = event => {
    event.preventDefault();
    const { name, id, eventTime, eventLocation, tags} = this.props;
    this.props.dispatch(addAllPlayers({
        name: name, id: id, eventTime: eventTime, eventLocation: eventLocation, tags: tags
      })
    );
  }

  handleUpdateEvent = event => {
    event.preventDefault();
    const { name, id, type, eventState, eventTime, eventLocation, url, result, summary, tags, plays, participants, schedules, teamStats} = this.props;
    this.props.dispatch(updateEvent({
        name: name, id: id, type: type, eventState: eventState, eventTime: eventTime, eventLocation: eventLocation, url: url, 
        result: result, summary: summary, plays: plays, schedules: schedules, teamStats: teamStats, tags: tags, participants: participants
      })
    );
  }

  render() {
    const { uError } = this.props;
    const { type, participants, plays, attendees, schedules, teamStats } = this.props;
    // const plays = [{name: "first goal", id: 1}];
    const participantInput = !participants ? <div></div> : participants.map(curParticipant => {
      return (<div key={curParticipant.id}><ParticipantInfo id={curParticipant.id} participant={curParticipant} /><hr className="mt-6 border-b-1 border-blueGray-300" /></div>);
    });
    const playInput = !plays ? <div></div> : plays.map(curPlay => {
      return (<div key={curPlay.id}><PlayInfo id={curPlay.id} play={curPlay} attendees={attendees}/><hr className="mt-6 border-b-1 border-blueGray-300" /></div>);
    });
    const scheduleInput = !schedules ? <div></div> : schedules.map(curSchedule => {
      return (<div key={curSchedule.id}><ScheduleInfo id={curSchedule.id} schedule={curSchedule} /><hr className="mt-6 border-b-1 border-blueGray-300" /></div>);
    });
    const statsInput = !teamStats ? <div></div> : teamStats.map(curStats => {
      return (<div key={curStats.id}><StatsInfo id={curStats.id} stats={curStats} /><hr className="mt-6 border-b-1 border-blueGray-300" /></div>);
    });
    return (
      <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">My Event</h6>
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={this.handleParticipantAdded}
                >
                  <i className="fas fa-user-plus text-white-400 mr-2 text-sm"></i>{" "}
                  Add Participant
                </button>
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={this.handleAddAllPlayers}
                >
                  <i className="fas fa-user-plus text-white-400 mr-2 text-sm"></i>{" "}
                  Add All Players
                </button>
                {(type !== 'TOURNAMENT') && <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={this.handlePlayAdded}
                >
                  <i className="fas fa-user-plus text-white-400 mr-2 text-sm"></i>{" "}
                  Add Play
                </button>}
                {(type === 'TOURNAMENT') && <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={this.handleScheduleAdded}
                >
                  <i className="fas fa-user-plus text-white-400 mr-2 text-sm"></i>{" "}
                  Add Schedule
                </button>}
                {(type === 'TOURNAMENT') && <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={this.handleStatsAdded}
                >
                  <i className="fas fa-user-plus text-white-400 mr-2 text-sm"></i>{" "}
                  Add Stats
                </button>}
              </div>
            </div>
            <div className="flex-auto px-0 lg:px-0 py-10 pt-0">
              <form onSubmit={this.handleUpdateEvent}>
                <EventInfo />
                <hr className="mt-6 border-b-1 border-blueGray-300" />
                {participantInput}
                {playInput}
                {scheduleInput}
                {statsInput}
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-2 py-5">
                    <div className="relative w-full mb-1 text-center">
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit" name="submit"
                      >
                        Update
                      </button>
                    </div>
                    <div className="text-red-500 text-center">{uError}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-4">
          <EventTable />
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  name: state.events.name,
  id: state.events.id,
  type: state.events.type,
  eventState: state.events.eventState,
  eventTime: state.events.eventTime,
  eventLocation: state.events.eventLocation,
  url: state.events.url,
  result: state.events.result,
  summary: state.events.summary,
  tags: state.events.tags,
  participants: state.events.participants,
  plays: state.events.plays,
  attendees: state.events.attendees,
  schedules: state.events.schedules,
  teamStats: state.events.teamStats,
  uError: state.events.uError
});

export default connect(mapStateToProps)(Event);
