import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { resetRegistered, resetError } from "users/userSlice";
import { userRegister } from "users/userSlice";
import { changeEmail, changePassword, changeFullName } from "users/userSlice";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(resetError());
  }

  handleChangeEmail = event => {
    event.preventDefault();
    this.props.dispatch(changeEmail(event.target.value));
  }

  handleChangePassword = event => {
    event.preventDefault();
    this.props.dispatch(changePassword(event.target.value));
  }

  handleChangeFullName = event => {
    event.preventDefault();
    this.props.dispatch(changeFullName(event.target.value));
  }

  handleSubmit = event => {
    event.preventDefault();
    const { emailAddress, password, fullName } = this.props;
    this.props.dispatch(userRegister({
      userName: fullName, 
      password: password, 
      emailAddress: emailAddress,
      fullName: fullName
    }));
  }

  render() {
    const { isRegistered, error } = this.props;
    if (isRegistered) {
      alert('Please check your email to activate your account');
      this.props.dispatch(resetRegistered());
      this.props.dispatch(resetError());
      return <Redirect to={`/auth/login`} />;
    }
    const { emailAddress, password, fullName } = this.props;
    return (
      <>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                {/* <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Sign up with
                    </h6>
                  </div>
                  <div className="btn-wrapper text-center">
                    <button
                      className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                      type="button"
                    >
                      <img
                        alt="..."
                        className="w-5 mr-1"
                        src={require("assets/img/google.svg").default}
                      />
                      Google
                    </button>
                  </div>
                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div> */}
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  {/* <div className="text-blueGray-400 text-center mb-3 font-bold">
                    <small>Or sign up with credentials</small>
                  </div> */}
                  <form onSubmit={this.handleSubmit}>
                    <div className="relative w-full mt-8 mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Name"
                        name="fullName" 
                        value={fullName} onChange={this.handleChangeFullName} 
                        required
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        name="email"
                        value={emailAddress} onChange={this.handleChangeEmail} 
                        required
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        minlength="8"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                        name="password" 
                        value={password} onChange={this.handleChangePassword} 
                        required
                      />
                    </div>

                    <div>
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          id="customCheckLogin"
                          type="checkbox"
                          className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                          required
                        />
                        <span className="ml-2 text-sm font-semibold text-blueGray-600">
                          I agree with the{" "}
                          <a
                            href="#pablo"
                            className="text-lightBlue-500"
                            onClick={(e) => e.preventDefault()}
                          >
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                    <div className="text-red-500">{error}</div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                        name="submit"
                      >
                        Create Account
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  emailAddress: state.users.emailAddress,
  password: state.users.password,
  fullName: state.users.fullName,
  isRegistered: state.users.isRegistered,
  error: state.users.error
});

export default connect(mapStateToProps)(Register);