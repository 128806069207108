import React from "react";
import { connect } from "react-redux";

import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import CardPreview from "components/Cards/CardPreview";
import Cookies from 'universal-cookie';
import { showEvents } from "events/eventSlice";
import { changeCurView } from "events/eventSlice";
import { getCurrentUser } from "users/userSlice";

class Games extends React.Component {
  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    if ((!currentUser || !currentUser.roles) && token) {
      this.props.dispatch(getCurrentUser(token));
    }
  }

  componentDidMount() {
    const { currentUser, currentTeam } = this.props;
    if (currentUser) {
      if (currentUser.teams && currentTeam) {
        this.props.dispatch(showEvents(currentTeam));
        this.props.dispatch(changeCurView(0));
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser, currentTeam } = this.props;
    if (currentUser) {
      if (currentUser.teams && (currentTeam !== prevProps.currentTeam || currentUser !== prevProps.currentUser)) {
        this.props.dispatch(showEvents(currentTeam));
        this.props.dispatch(changeCurView(0));
      }
    }
  }

  render() {
    const { curView, filteredGames, playerSelected } = this.props;
    const { viewType } = this.props;
    var videoCaption, videoUrl, videoSummary, videoPlay;
    if (filteredGames && filteredGames.length > 0 && curView < filteredGames.length) {
      videoCaption = <div>
          <div>{(new Date(filteredGames[curView].eventTime)).toLocaleString()}</div>
          <div>{filteredGames[curView].name}</div>
          <div>{"(" + filteredGames[curView].result + ")"}</div>
        </div>;
      videoUrl = filteredGames[curView].url;
      videoSummary = filteredGames[curView].summary;

      if (filteredGames[curView].plays) {
        const filteredPlays = filteredGames[curView].plays.filter(play => ((play.players && play.players.includes(playerSelected)) || playerSelected === 'all'));
        if (filteredPlays) {
          videoPlay = filteredPlays.map(curPlay => {
            return (
            <div key={curPlay.id} className="relative bg-lightBlue-100 :pt-32 pb-4 pt-16">
              <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-4 text-center">
                {curPlay.name + " "} {(curPlay.link) && <button onClick={() => {navigator.clipboard.writeText(curPlay.link)}}>
                {" "}<i className="fas fa-copy text-white-400 mr-2 text-sm"></i>
                </button>}
              </div>
              <div className="flex w-full xl:w-12/12 mb-4 xl:mb-0 px-4 justify-center">
                <CardPreview id={curPlay.id} url={curPlay.link} />
              </div>
              {(curPlay.suggestion) && <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-4 text-center">
                {curPlay.suggestion} 
              </div>}
            </div>);
          });
        }
      }
    }
    // var viewType = 'Highlights';
    // videoCaption = <div><div>{"2023-12-18"}</div><div>{"218 BLUE LOBSTER AYSO DAVIS ALLIANCE vs 26 Elit"}</div></div>;
    // videoUrl = 'https://falinks.net/video/95532/1df8092223ca6c845304e86337654b0c.mov';
    // videoSummary = "Great Game, we could hav won, fighting till the end, with someone score some goals";
    return (
      <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        {/* <IndexNavbar /> */}
        {(viewType === 'Highlights') && <div className="relative bg-lightBlue-100 :pt-32 pb-4 pt-16">
          <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-4 text-center">
            {videoCaption}
          </div>
          <div className="flex w-full xl:w-12/12 mb-4 xl:mb-0 px-4 justify-center">
            {(videoUrl) && <CardPreview url={videoUrl} />}
          </div>
          {(videoSummary) && <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-1 text-center">
            {videoSummary + " "}
            {(videoUrl) && <button onClick={() => {navigator.clipboard.writeText(videoUrl)}}>
            {" "}<i className="fas fa-copy text-white-400 mr-2 text-sm"></i>
            </button>}
          </div>}
          {(!videoUrl) && <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-1 text-center">No Games, check other times</div>}
        </div>}
        {(viewType === 'Plays') && <div>{videoPlay}</div>}
        <FooterAdmin />
      </div>
    </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  currentTeam: state.teams.currentTeam,
  curView: state.events.curView,
  filteredGames: state.events.filteredGames,
  viewType: state.events.viewType,
  playerSelected: state.events.playerSelected,
  error: state.events.error
});

export default connect(mapStateToProps)(Games);
