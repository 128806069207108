import React from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { forwardRef } from 'react';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

const tableIcons = {
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
};

class ScheduleTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      selectedRow: null
    }; 
  }

  render() {
    const columns = [
      { title: 'Game', field: 'name' },
      { title: 'Time', field: 'time' },
      { title: 'Location', field: 'location' },
      { title: 'Teams', field: 'teams' },
      { title: 'Theme', field: 'theme' }
    ];
    const { title, schedules, error } = this.props;
    
    const data = schedules? schedules.map(schedule => {
      return {
        name: schedule.name,
        time: (new Date(schedule.time)).toLocaleString(),
        location: schedule.location ? schedule.location.name : "",
        teams: schedule.teams,
        theme: schedule.theme
      }
    }) : [];
    
    const { selectedRow } = this.state;
    return (
      <div style={{ maxWidth: "100%", paddingTop: "12px" }}>
      <MaterialTable
        title={title + " Schedule"}
        columns={columns}
        data={data}
        icons={tableIcons}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF"
          },
          rowStyle: 
            rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
          paging: false,
          padding: 'dense',
          actionsColumnIndex: -1,
          search: false
        }}
      />
      <div className="text-red-500 text-center">{error}</div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.events.error
});

export default connect(mapStateToProps)(ScheduleTable);
