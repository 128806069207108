import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { paymentAdded } from "engagements/engagementSlice";
import EngagementInfo from "components/Cards/EngagementInfo";
import PaymentInfo from "components/Cards/PaymentInfo";
import { volunteerAdded } from "engagements/engagementSlice";
import VolunteerInfo from "components/Cards/VolunteerInfo";
import { updateEngagement } from "engagements/engagementSlice";
import EngagementTable from "components/Cards/EngagementTable";
import { changeTeamId } from "engagements/engagementSlice";
import { changeMonth } from "engagements/engagementSlice";
import { changeUserEmail } from "engagements/engagementSlice";
import { engagementAdded } from "engagements/engagementSlice";

class Engagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      month: new Date(),
      teamName: '',
      teamId: '',
      userEmail: ''
    }; 
  }
   
  handleChangeMonth = engagement => {
    engagement.preventDefault();
    this.setState({
      month: engagement.target.value
    });
    this.props.dispatch(changeMonth(engagement.target.value));
  }
  
  handleChangeTeam = team => {
    this.setState({
      teamName: team.label,
      teamId: team.value
    });
    this.props.dispatch(changeTeamId(team.value));
  }

  handleChangeUserEmail = userEmail => {
    this.setState({
      userEmail: userEmail.target.value
    });
    this.props.dispatch(changeUserEmail(userEmail.target.value));
  }

  handlePaymentAdded = e => {
    e.preventDefault();
    this.props.dispatch(paymentAdded());
  }

  handleVolunteerAdded = e => {
    e.preventDefault();
    this.props.dispatch(volunteerAdded());
  }

  handleEngagementAdded = e => {
    e.preventDefault();
    const { userEmail, month, teamId } = this.state;
    // const { userEmail, month } = this.state;
    // const teamId = "38680";
    if (!userEmail || !teamId || !month) {
      alert("Please choose a user / team / date");
    } else {
      this.props.dispatch(engagementAdded(teamId, (new Date(month)).getTime(), userEmail));
    }
  }

  handleUpdateEngagement = e => {
    e.preventDefault();
    const { id, userName, userId, userEmail, userPhone, month, teamId, datesOfSignup, datesOfParticipation, payments, volunteers, numOfPlayers } = this.props;
    this.props.dispatch(updateEngagement({
        id: id, userName: userName, userId: userId, userEmail: userEmail, userPhone: userPhone, month: (new Date(month)).getTime(), teamId: teamId, 
        datesOfSignup: datesOfSignup, datesOfParticipation: datesOfParticipation, payments: payments, volunteers: volunteers, numOfPlayers: numOfPlayers
      })
    );
  }

  render() {
    const { uError, teams } = this.props;
    const { month, teamName, teamId, userEmail } = this.state;
    const teamSelected = {label: teamName, value: teamId};
    const teamOptions = teams.map(team => { return {label: team.name, value: team.id} });
    const { payments, volunteers } = this.props;
    const paymentInput = !payments ? <div></div> : payments.map(curPay => {
      return (<div key={curPay.id}><PaymentInfo id={curPay.id} payment={curPay} /><hr className="mt-6 border-b-1 border-blueGray-300" /></div>);
    });
    const volunteerInput = !volunteers ? <div></div> : volunteers.map(curVolunteer => {
      return (<div key={curVolunteer.id}><VolunteerInfo id={curVolunteer.id} volunteer={curVolunteer} /><hr className="mt-6 border-b-1 border-blueGray-300" /></div>);
    });
    return (
      <>
      <div className="flex flex-wrap">
        <form className="w-full lg:w-12/12 px-4" onSubmit={this.handleEngagementAdded}>
          <div className="relative bg-lightBlue-100 :pt-3 pb-4 pt-4">
            <div className="px-1 md:px-1 mx-auto w-full text-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                    Month
                  </label>
                  <input
                    type="month"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Time"
                    name="month"
                    value={month} onChange={this.handleChangeMonth} 
                    required
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                    Team
                  </label> 
                  <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <Select options={teamOptions} value={teamSelected} required onChange={this.handleChangeTeam} />
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                    User
                  </label> 
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="User Email"
                    name="userEmail" 
                    value={userEmail} onChange={this.handleChangeUserEmail} 
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4 py-6">
                <div className="relative w-full mb-3">
                  <button
                    className="bg-lightBlue-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit" name="submit"
                  >
                    new
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Engagement</h6>
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={this.handlePaymentAdded}
                >
                  <i className="fas fa-user-plus text-white-400 mr-2 text-sm"></i>{" "}
                  Add Payment
                </button>
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={this.handleVolunteerAdded}
                >
                  <i className="fas fa-user-plus text-white-400 mr-2 text-sm"></i>{" "}
                  Add Volunteer
                </button>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={this.handleUpdateEngagement}>
                <EngagementInfo />
                <hr className="mt-6 border-b-1 border-blueGray-300" />
                {paymentInput}
                <hr className="mt-6 border-b-1 border-blueGray-300" />
                {volunteerInput}
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-2 py-5">
                    <div className="relative w-full mb-1 text-center">
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit" name="submit"
                      >
                        Update
                      </button>
                    </div>
                    <div className="text-red-500 text-center">{uError}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-4">
          <EngagementTable />
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.teams.teams,
  id: state.engagements.id,
  userEmail: state.engagements.userEmail,
  userId: state.engagements.userId,
  userName: state.engagements.userName,
  userPhone: state.engagements.userPhone,
  numOfPlayers: state.engagements.numOfPlayers,
  month: state.engagements.month,
  teamId: state.engagements.teamId,
  datesOfSignup: state.engagements.datesOfSignup,
  datesOfParticipation: state.engagements.datesOfParticipation,
  payments: state.engagements.payments,
  volunteers: state.engagements.volunteers,
  uError: state.engagements.uError
});

export default connect(mapStateToProps)(Engagement);
