import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@mui/lab/Autocomplete';
import { changeCurView } from "events/eventSlice";
import { changeGameFilter } from "events/eventSlice";
import { changeViewType } from "events/eventSlice";
import { changePlayerSelected } from "events/eventSlice";
import { TextField } from "@mui/material";

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      collapseShow: "hidden"
    }; 
  }

  componentDidMount() {
  }

  setCollapseShow(collapse) {
    this.setState({
      collapseShow: collapse
    });
  }

  nextGame() {
    const { curView } = this.props;
    this.props.dispatch(changeCurView(curView-1));
  }

  prevGame() {
    const { curView } = this.props;
    this.props.dispatch(changeCurView(curView+1));
  }

  handleChangeFilter = event => {
    event.preventDefault();
    this.props.dispatch(changeGameFilter(event.target.value));
    this.props.dispatch(changeCurView(0));
    this.props.dispatch(changeViewType("Highlights"));
  }

  handleChangeViewType = type => {
    this.props.dispatch(changeViewType(type.target.value));
  }

  handleChangePlayerSelection = player => {
    if (player.target && player.target.textContent) {
      this.props.dispatch(changePlayerSelected(player.target.textContent));
    }
  }

  render() {
    const {curView, viewTotal, filteredGames, filter, viewType, playerSelected } = this.props;
    // console.log("side " + this.state.collapseShow);
    var disableNext = false;
    var disablePrev = false;
    if (!filteredGames || !filteredGames.length) {
      disableNext = true;
      disablePrev = true;
    }
    if (filteredGames && filteredGames.length) {
      disablePrev = curView >= filteredGames.length - 1;
      disableNext = curView <= 0;
    }
    var curGame;
    var playerOptions = [];
    var playerInOption = {label: playerSelected, value: playerSelected};
    if (filteredGames && filteredGames.length > 0 && curView < filteredGames.length) {
      curGame = 'Game ' + (curView + 1) + ' / ' + viewTotal;
      const { attendees } = filteredGames[curView];
      if (attendees) {
        playerOptions = attendees.map(attendee => {
          return { label: attendee.player, value: attendee.player }
        });
        playerOptions.push({label: 'all', value: 'all'});
      }
    }
    const label = 'Player';

    return (
      <>
        <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
          <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
            {/* Toggler */}
            <button
              className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              type="button"
              onClick={() => this.setCollapseShow("bg-white m-2 py-3 px-6")}
            >
              <i className="fas fa-bars"></i>
            </button>
            <Link
              className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
              to="/"
            >
              Falinks Net
            </Link>
   
            {/* Collapse */}
            <div
              className={
                "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                this.state.collapseShow
              }
            >
              {/* Collapse header */}
              <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                <div className="flex flex-wrap">
                  <div className="w-6/12">
                    <Link
                      className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                      to="/"
                    >
                      Falinks Net
                    </Link>
                  </div>
                  <div className="w-6/12 flex justify-end">
                    <button
                      type="button"
                      className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                      onClick={() => this.setCollapseShow("hidden")}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              {/* Divider */}
              {/* <hr className="my-4 md:min-w-full" />
              <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                Rules
              </h6>
              <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                <li className="items-center">
                  <Link
                    className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                    to="/rules/players"
                  >
                    <i className="fas fa-futbol text-blueGray-400 mr-2 text-sm"></i>{" "}
                    Players
                  </Link>
                </li>
  
                <li className="items-center">
                  <Link
                    className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                    to="/rules/parents"
                  >
                    <i className="fas fa-user-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
                    Parents
                  </Link>
                </li>
              </ul> */}
  
              {/* Divider */}
              <hr className="my-4 md:min-w-full" />
              <div className="md:min-w-full px-2">
                <div className="relative w-full mb-3">
                  <input
                    type="month"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Month"
                    name="month" 
                    value={filter} onChange={this.handleChangeFilter} 
                  />
                </div>
              </div>
              {/* Heading */}
              <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                {curGame}
              </h6>
              {/* Navigation */}
              <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                {(disablePrev === false) && <li className="items-center">
                  <Link
                    className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                    to={`/games`} onClick={() => this.prevGame()}
                  >
                    <i className="fas fa-angle-left text-blueGray-400 mr-2 text-sm"></i>{" "}
                    Prev
                  </Link>
                </li>}

                {(disableNext === false) && <li className="items-center">
                  <Link
                    className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                    to="/games" onClick={() => this.nextGame()}
                  >
                    <i className="fas fa-angle-right text-blueGray-400 mr-2 text-sm"></i>{" "}
                    Next
                  </Link>
                </li>}
              </ul>
              {(curGame) && <fieldset>
                <div className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                  <input type="radio" id="highlights" name="viewType" value="Highlights" checked={viewType === 'Highlights'}  onChange={this.handleChangeViewType} />Hightlights
                </div>
                <div className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">  
                  <input type="radio" id="plays" name="viewType" value="Plays" checked={viewType === 'Plays'} onChange={this.handleChangeViewType}/>Plays
                </div>
              </fieldset>}
              {(curGame && viewType === 'Plays') && <Autocomplete
                className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-5 font-bold block"
                options={playerOptions}
                renderInput={params => (
                  <TextField {...params} label={label} size="small" variant="outlined" />
                )}
                getOptionLabel={option => option.value}
                style={{ width: 180 }}
                value={playerInOption}
                onChange={this.handleChangePlayerSelection}
                // onInputChange={this.handleChangePlayerSelection}
              />}
              {/* {(curGame && viewType === 'Plays') && <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                  <Select options={playerOptions} value={playerInOption} onChange={this.handleChangePlayerSelection} />
                </div>} */}
            </div>
          </div>
        </nav>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  curView: state.events.curView,
  viewTotal: state.events.viewTotal,
  filter: state.events.filter,
  filteredGames: state.events.filteredGames,
  viewType: state.events.viewType,
  playerSelected: state.events.playerSelected
});

export default connect(mapStateToProps)(Sidebar);
