import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { changeRole } from "users/userSlice";
import { changePhoneNumber } from "users/userSlice";

class UserInfo extends React.Component {

  handleChangeRoles = event => {
    this.props.dispatch(changeRole(event.value));
  }

  handleChangePhoneNumber = event => {
    event.preventDefault();
    this.props.dispatch(changePhoneNumber(event.target.value));
  }

  render() {
    const { emailAddress, phoneNumber } = this.props;
    const roleOptions = [{label: 'ADMIN', value: 'ADMIN'}, {label: 'PARENT', value: 'PARENT'}, {label: 'COACH', value: 'COACH'}, {label: 'MANAGER', value: 'MANAGER'}, {label: 'FILMING', value: 'FILMING'}];
    return (
      <>
      <div>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Email
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Email"
                name="emailAddress" 
                disabled={true}
                value={emailAddress} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Phone Number
              </label>
              <input
                type="tel"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="PhoneNumber"
                name="phoneNumber" 
                value={phoneNumber} onChange={this.handleChangePhoneNumber} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Roles
              </label>
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={roleOptions} onChange={this.handleChangeRoles} />
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  fullName: state.users.fullName,
  emailAddress: state.users.emailAddress,
  phoneNumber: state.users.phoneNumber,
  role: state.users.role,
  error: state.users.error
});

export default connect(mapStateToProps)(UserInfo);
