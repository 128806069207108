import React from "react";
import { connect } from "react-redux";
import { changeDescription } from "venues/venueSlice";
import { changeUrl, changeAddress } from "venues/venueSlice";
import { changeLatitude, changeLongitude } from "venues/venueSlice";
import { changeName, changeZip } from "venues/venueSlice";

class VenueInfo extends React.Component {

  handleChangeName = event => {
    event.preventDefault();
    this.props.dispatch(changeName(event.target.value));
  }

  handleChangeZip = event => {
    event.preventDefault();
    this.props.dispatch(changeZip(event.target.value));
  }

  handleChangeLatitude = event => {
    event.preventDefault();
    this.props.dispatch(changeLatitude(event.target.value));
  }

  handleChangeLongitude = event => {
    event.preventDefault();
    this.props.dispatch(changeLongitude(event.target.value));
  }

  handleChangeUrl = event => {
    event.preventDefault();
    this.props.dispatch(changeUrl(event.target.value));
  }

  handleChangeAddress = event => {
    event.preventDefault();
    this.props.dispatch(changeAddress(event.target.value));
  }

  handleChangeDescription = event => {
    event.preventDefault();
    this.props.dispatch(changeDescription(event.target.value));
  }

  render() {
    const { name, zip, latitude, longitude, url, address, description } = this.props;
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Venue Information
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Name"
                name="name" 
                value={name} onChange={this.handleChangeName} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Zip Code
              </label>
              <input
                type="text"
                pattern="[0-9]{5,6}"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="ZipCode"
                name="zip" 
                value={zip} onChange={this.handleChangeZip} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Latitude
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Latitude"
                name="latitude" 
                value={latitude} onChange={this.handleChangeLatitude} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Longitude
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Longitude"
                name="longitude" 
                value={longitude} onChange={this.handleChangeLongitude} 
              />
            </div>
          </div>
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                URL
              </label>
              <input
                type="url"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Url"
                name="url" 
                value={url} onChange={this.handleChangeUrl} 
              />
            </div>
          </div>
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Address
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Address"
                name="address" 
                value={address} onChange={this.handleChangeAddress} 
              />
            </div>
          </div>
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Description
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Description"
                name="description" 
                value={description} onChange={this.handleChangeDescription} 
              />
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  name: state.venues.name,
  type: state.venues.type,
  zip: state.venues.zip,
  latitude: state.venues.latitude,
  longitude: state.venues.longitude,
  url: state.venues.url,
  address: state.venues.address,
  description: state.venues.description,
  error: state.venues.error
});

export default connect(mapStateToProps)(VenueInfo);
