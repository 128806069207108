import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import Cookies from 'universal-cookie';

export const listTeams = createAsyncThunk("teams/listTeams", async (team, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.get("/api/list_teams", {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const listMyTeams = createAsyncThunk("teams/listMyTeams", async (team, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.get("/api/show_teams", {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const showTeams = createAsyncThunk("teams/showTeams", async (team, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.get("/api/show_teams", {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const addTeam = createAsyncThunk("teams/addTeam", async (team, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/add_team", team, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateTeam = createAsyncThunk("teams/updateTeam", async (team, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/update_team", team, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const addMember = createAsyncThunk("teams/addMember", async (team, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.put("/api/add_member/" + team.id + "/" + team.member, team, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const removeMember = createAsyncThunk("teams/removeMember", async (team, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.delete("/api/remove_member/" + team.id + "/" + team.member, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


export const addPlayer = createAsyncThunk("teams/addPlayer", async (team, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.put("/api/add_player/" + team.id + "/" + team.player, team, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const removePlayer = createAsyncThunk("teams/removePlayer", async (team, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.delete("/api/remove_player/" + team.id + "/" + team.player, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const removeTeam = createAsyncThunk("teams/removeTeam", async (team, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.delete("/api/remove_team/" + team.id, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

const initialState = {
  name: '',
  id: '',
  city: '',
  state: '',
  playerBirthYear: '',
  practiceField: '',
  competitiveLevel: '',
  coach: '',
  coachEmail: '',
  manager: '',
  managerEmail: '',
  newMember: '',
  newPlayer: '',
  members: [],
  players: [],
  teamList: [],
  teams: [],
  currentTeam: '',
  loading: false,
  aError: null,
  error: null
}

const teamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    changeName(state, action) {
      state.name = action.payload;
      state.aError = null;
    },
    changeId(state, action) {
      state.id = action.payload;
      state.aError = null;
    },
    changeCity(state, action) {
      state.city = action.payload;
      state.aError = null;
    },
    changeState(state, action) {
      state.state = action.payload;
      state.aError = null;
    },
    changePlayerBirthYear(state, action) {
      state.playerBirthYear = action.payload;
      state.aError = null;
    },
    changePracticeField(state, action) {
      state.practiceField = action.payload;
      state.aError = null;
    },
    changeCompetitiveLevel(state, action) {
      state.competitiveLevel = action.payload;
      state.aError = null;
    },
    changeCoach(state, action) {
      state.coach = action.payload;
      state.aError = null;
    },
    changeCoachEmail(state, action) {
      state.coachEmail = action.payload;
      state.aError = null;
    },
    changeManager(state, action) {
      state.manager = action.payload;
      state.aError = null;
    },
    changeManagerEmail(state, action) {
      state.managerEmail = action.payload;
      state.aError = null;
    },
    changeNewMember(state, action) {
      state.newMember = action.payload;
      state.aError = null;
    },
    changePlayers(state, action) {
      state.players = action.payload;
      state.aError = null;
    },
    changeNewPlayer(state, action) {
      state.newPlayer = action.payload;
      state.aError = null;
    },
    changeCurrentTeam(state, action) {
      state.currentTeam = action.payload;
      state.aError = null;
    },
    resetError(state, action) {
      state.aError = null;
      state.error = null;
    }
  },
  extraReducers: {
    [listTeams.pending]: (state, action) => {
      state.loading = true;
    },
    [listTeams.fulfilled]: (state, action) => {
      state.loading = false;
      state.teamList = action.payload;
    },
    [listTeams.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [listMyTeams.pending]: (state, action) => {
      state.loading = true;
    },
    [listMyTeams.fulfilled]: (state, action) => {
      state.loading = false;
      state.teamList = action.payload;
    },
    [listMyTeams.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [showTeams.pending]: (state, action) => {
      state.loading = true;
    },
    [showTeams.fulfilled]: (state, action) => {
      state.loading = false;
      state.teams = action.payload;
    },
    [showTeams.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [addTeam.pending]: (state, action) => {
      state.loading = true;
    },
    [addTeam.fulfilled]: (state, action) => {
      state.loading = false;
      state.teamList = state.teamList.concat({
        name: state.name, id: action.payload, city: state.city, 
        state: state.state, playerBirthYear: state.playerBirthYear, 
        practiceField: state.practiceField, competitiveLevel: state.competitiveLevel, 
        coach: state.coach, coachEmail: state.coachEmail, manager: state.manager,
        managerEmail: state.managerEmail
      });
      state.error = null;
    },
    [addTeam.rejected]: (state, action) => {
      state.aError = action.payload;
      state.loading = false;
    },
    [updateTeam.pending]: (state, action) => {
      state.loading = true;
    },
    [updateTeam.fulfilled]: (state, action) => {
      state.loading = false;
      state.teamList = state.teamList.map(team => {
        if (team.id === action.payload) {
          return {
            name: state.name, id: action.payload, city: state.city, 
            state: state.state, playerBirthYear: state.playerBirthYear, 
            practiceField: state.practiceField, competitiveLevel: state.competitiveLevel, 
            coach: state.coach, coachEmail: state.coachEmail, manager: state.manager,
            managerEmail: state.managerEmail
          };
        } else {
          return team;
        }
      });
      state.error = null;
    },
    [updateTeam.rejected]: (state, action) => {
      state.aError = action.payload;
      state.loading = false;
    },
    [removeTeam.pending]: (state, action) => {
      state.loading = true;
    },
    [removeTeam.fulfilled]: (state, action) => {
      state.loading = false;
      state.teamList = state.teamList.filter((current) => current.id !== action.payload);
    },
    [removeTeam.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [addMember.pending]: (state, action) => {
      state.loading = true;
    },
    [addMember.fulfilled]: (state, action) => {
      state.loading = false;
      state.teamList = state.teamList.map(team => {
        if (team.id === state.id) {
          return {
            ...team,
            members: (!team.members) ? [state.newMember] : team.members.concat(state.newMember)
          };
        } else {
          return team;
        }
      });
      state.error = null;
    },
    [addMember.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [addPlayer.pending]: (state, action) => {
      state.loading = true;
    },
    [addPlayer.fulfilled]: (state, action) => {
      state.loading = false;
      state.teamList = state.teamList.map(team => {
        if (team.id === state.id) {
          return {
            ...team,
            players: (!team.players) ? [action.payload] : team.players.concat(action.payload)
          };
        } else {
          return team;
        }
      });
      state.error = null;
    },
    [addPlayer.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [removeMember.pending]: (state, action) => {
      state.loading = true;
    },
    [removeMember.fulfilled]: (state, action) => {
      state.loading = false;
      state.teamList = state.teamList.map(team => {
        if (team.id === state.id) {
          return {
            ...team,
            members: (!team.members) ? team.members : team.members.filter(member => member !== state.newMember)
          };
        } else {
          return team;
        }
      });
      state.error = null;
    },
    [removeMember.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [removePlayer.pending]: (state, action) => {
      state.loading = true;
    },
    [removePlayer.fulfilled]: (state, action) => {
      state.loading = false;
      state.teamList = state.teamList.map(team => {
        if (team.id === state.id) {
          return {
            ...team,
            players: (!team.players) ? team.players : team.players.filter(player => player.id !== state.newPlayer)
          };
        } else {
          return team;
        }
      });
      state.error = null;
    },
    [removePlayer.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
})

export const { changeName, changeId, changeCity, changeState, changeLatitude, changePlayerBirthYear, changePracticeField, changeCurrentTeam, 
  changeCompetitiveLevel, changeCoach, changeCoachEmail, changeManager, changeManagerEmail, changeNewMember, changeNewPlayer, changePlayers, 
  resetError } = teamSlice.actions
export default teamSlice.reducer