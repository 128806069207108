import React from "react";
import { connect } from "react-redux";
import { getProfile } from "users/userSlice";
import CardPlayer from "components/Cards/CardPlayer.js";
import PlayerInfo from "components/Cards/PlayerInfo.js";
import ParentInfo from "components/Cards/ParentInfo.js";
import { playerAdded } from "users/userSlice";
import { updateProfile } from "users/userSlice";
import { changeToNewPassword } from "users/userSlice";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    const { emailAddress } = this.props;
    if (emailAddress) {
      this.props.dispatch(getProfile(emailAddress));
    }
  }

  handlePlayerAdded = event => {
    event.preventDefault();
    this.props.dispatch(playerAdded());
  }

  handleUpdateProfile = event => {
    event.preventDefault();
    const { emailAddress, id, fullName, userName, phoneNumber, zipCode, address, oldPassword, newPassword, players } = this.props;
    if (oldPassword && newPassword) {
      this.props.dispatch(changeToNewPassword({oldPassword: oldPassword, newPassword: newPassword}));
    } else {
      this.props.dispatch(updateProfile({userName: userName, emailAddress: emailAddress, id: id, fullName: fullName, phoneNumber: phoneNumber, zipCode: zipCode, address: address, players: players}));
    }
  }

  render() {
    const { error, players } = this.props;
    const playerInput = !players ? <div></div> : players.map(curPlayer => {
      return (<div key={curPlayer.id}><PlayerInfo id={curPlayer.id} player={curPlayer} /><hr className="mt-6 border-b-1 border-blueGray-300" /></div>);
    });
    const playerCard = players.map((curPlayer) => <div key={curPlayer.id}><CardPlayer player={curPlayer} /></div>);

    return (
      <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">My account</h6>
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={this.handlePlayerAdded}
                >
                  <i className="fas fa-user-plus text-white-400 mr-2 text-sm"></i>{" "}
                  Add Player
                </button>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={this.handleUpdateProfile}>
                <ParentInfo />
                <hr className="mt-6 border-b-1 border-blueGray-300" />
                {playerInput}
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-2 py-5">
                    <div className="relative w-full mb-1 text-center">
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit" name="submit"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="text-red-500 text-center">{error}</div>
        </div>
        <div className="w-full lg:w-4/12 px-4">
          {playerCard}
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  emailAddress: state.users.emailAddress,
  id: state.users.id,
  fullName: state.users.fullName,
  userName: state.users.userName,
  phoneNumber: state.users.phoneNumber,
  zipCode: state.users.zipCode,
  address: state.users.address,
  players: state.users.players,
  oldPassword: state.users.oldPassword,
  newPassword: state.users.newPassword,
  isRegistered: state.users.isRegistered,
  error: state.users.error
});

export default connect(mapStateToProps)(Profile);
