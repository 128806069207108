import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import videosReducer from 'videos/videosSlice';
import userReducer from 'users/userSlice';
import eventReducer from 'events/eventSlice';
import venueReducer from 'venues/venueSlice';
import teamReducer from 'teams/teamSlice';
import engagementReducer from 'engagements/engagementSlice';
import developmentReducer from 'developments/developmentSlice';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Games from "layouts/Games.js";
import Players from "layouts/Players.js";
import Rules from "layouts/Rules.js";
import Auth from "layouts/Auth.js";
import Admin from "layouts/Admin.js";

// views without layouts
import Index from "views/Index.js";
import PageNotFound from "views/PageNotFound";
import { ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import Sessions from "layouts/Sessions";
// import { makeStyles } from "@mui/styles";

const theme = createTheme();

// const useStyles = makeStyles((theme) => {
//   root: {
//     // some CSS that accesses the theme
//   }
// });

const store = configureStore({
  reducer: {
    videos: videosReducer,
    users: userReducer,
    events: eventReducer,
    venues: venueReducer,
    teams: teamReducer,
    engagements: engagementReducer,
    developments: developmentReducer
  }
})

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        {/* add routes with layouts */}
        <Route path="/games" component={Games} />
        <Route path="/players" component={Players} />
        <Route path="/sessions" component={Sessions} />
        <Route path="/rules" component={Rules} />
        <Route path="/auth" component={Auth} />
        <Route path="/admin" component={Admin} />
        {/* add routes without layouts */}
        <Route path="/" exact component={Index} />
        {/* add redirect for first page */}
        <Route path="*" component={PageNotFound} />
        {/* <Redirect from="*" to="/" /> */}
      </Switch>
    </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
