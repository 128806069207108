import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { scheduleName } from "events/eventSlice";
import { scheduleTheme } from "events/eventSlice";
import { removeSchedule } from "events/eventSlice";
import { scheduleLocation } from "events/eventSlice";
import { scheduleTime } from "events/eventSlice";
import { scheduleTeams } from "events/eventSlice";
import { getLocalDate } from "utils/utils";

class ScheduleInfo extends React.Component {
  constructor(props) {
    super(props);
    const { schedule } = this.props;
    const { time } = schedule;
    this.state = { 
      eventTime: getLocalDate(new Date(time))
    }; 
  }

  handleRemoveSchedule = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(removeSchedule(id));
  }

  handleChangeName = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(scheduleName(id, event.target.value));
  }

  handleChangeTime = event => {
    event.preventDefault();
    const { id } = this.props;
    this.setState({
      eventTime: event.target.value
    });
    this.props.dispatch(scheduleTime(id, (new Date(event.target.value)).getTime()));
  }

  handleChangeLocation = location => {
    const { venueList } = this.props;
    const currentLocation = venueList.find((venue) => venue.name === location.value);
    const { id } = this.props;
    this.props.dispatch(scheduleLocation(id, currentLocation));
  }

  handleChangeTeam = team => {
    const { id } = this.props;
    const teamSelected = team.map(cur => cur.value);
    this.props.dispatch(scheduleTeams(id, teamSelected));
  }

  handleChangeTheme = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(scheduleTheme(id, event.target.value));
  }

  render() {
    const { schedule, venueList } = this.props;
    const { eventTime } = this.state;
    const { name, location, teams, theme } = schedule;
    const locationOptions = venueList ? venueList.map(venue => { return { label: venue.name, value: venue.name } }) : [];
    const locationSelected = {label: location.name, value: location.name};
    // const teamOptions = teams.map(team => { return {label: team.name, value: team.id} });
    const teamOptions = [
      {label: 'MCI vs GUEST / RMD vs BAR', value: 'MCI vs GUEST / RMD vs BAR'}, 
      {label: 'RMD vs BAR', value: 'RMD vs BAR'}, 
      {label: 'RMD vs DOR', value: 'RMD vs DOR'}, 
      {label: 'Wukong vs Nezha', value: 'Wukong vs Nezha'}, 
      {label: 'Yellow vs Red', value: 'Yellow vs Red'}, 
      {label: 'RMD vs BAR / MCI vs PSG', value: 'RMD vs BAR / MCI vs PSG'}, 
      {label: 'RMD vs MCI / BAR vs PSG', value: 'RMD vs MCI / BAR vs PSG'}, 
      {label: 'Orange vs Blue / Red vs Sky', value: 'Orange vs Blue / Red vs Sky'}, 
      {label: 'TBD', value: 'TBD'}, 
      {label: 'Flex', value: 'Flex'}, 
    ];
    const teamSelected = teams ? teams.map(cur => { return {label: cur, value: cur} }) : [];
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Schedule Information
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Name"
                name="name" 
                value={name} onChange={this.handleChangeName} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                Time
              </label>
              <input
                type="datetime-local"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Time"
                name="time"
                value={eventTime} onChange={this.handleChangeTime} 
                required
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                Location
              </label> 
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={locationOptions} value={locationSelected} required onChange={this.handleChangeLocation} />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                Team
              </label> 
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={teamOptions} value={teamSelected} required isMulti onChange={this.handleChangeTeam} />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Theme
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Theme"
                name="theme" 
                value={theme} onChange={this.handleChangeTheme} 
              />
            </div>
          </div>
          <div className="px-4 py-4 md:px-10 mx-auto w-full text-center">
            <button
                className="font-normal text-lightBlue-500 px-6"
                onClick={this.handleRemoveSchedule}
            >
              <i className="fas fa-trash-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
              remove schedule
            </button>
         </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  venueList: state.venues.venueList,
  error: state.events.error
});

export default connect(mapStateToProps)(ScheduleInfo);
