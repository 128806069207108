import React from "react";

// components

export default function PlayerRule() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
        <div className="px-6">
          <div className="text-center mt-12">
            <ul>
              <li className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                 No Sliding Tackle
              </li>
              <li className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                 No Pushing / Shoving
              </li>
              <li className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                 No Yelling / Shouting
              </li>
              <li className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                 Wear shin guards / cleats
              </li>
              <li className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                 Younger kids shouldn't be goalie, center defender, or part of freekick wall
              </li>
              <li className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                 Listen to coach / goalie
              </li>
              <li className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                 Play position
              </li>
            </ul>
          </div>
          <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-9/12 px-4">
                <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                  Most kids were born in 2012. We don't have a hard age limit, can be flexible 
                  (+/- 1/2 years). We want to provide a safe, supporting environment. We encourage 
                  kids to play smart, competitive, as a team, and most importantly play safe. <br></br>
                  
                  Coach / goalie have the authority to pull kids to bench to cool off if necessary.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
