import React from "react";
import { connect } from "react-redux";
import { statsTeamId } from "events/eventSlice";
import { statsTeamName } from "events/eventSlice";
import { statsMatchesPlayed } from "events/eventSlice";
import { statsWins } from "events/eventSlice";
import { statsLosses } from "events/eventSlice";
import { statsDraws } from "events/eventSlice";
import { statsGoalsFor } from "events/eventSlice";
import { statsGoalsAgainst } from "events/eventSlice";
import { statsYellowCards } from "events/eventSlice";
import { statsRedCards } from "events/eventSlice";
import { statsPoints } from "events/eventSlice";
import { removeStats } from "events/eventSlice";

class StatsInfo extends React.Component {
  
  handleRemoveTeamStats = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(removeStats(id));
  }

  handleChangeTeamId = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(statsTeamId(id, event.target.value));
  }

  handleChangeTeamName = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(statsTeamName(id, event.target.value));
  }

  handleChangeMatchesPlayed = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(statsMatchesPlayed(id, event.target.value));
  }

  handleChangeWins = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(statsWins(id, event.target.value));
  }

  handleChangeLosses = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(statsLosses(id, event.target.value));
  }

  handleChangeDraws = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(statsDraws(id, event.target.value));
  }

  handleChangeGoalsFor = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(statsGoalsFor(id, event.target.value));
  }

  handleChangeGoalsAgainst = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(statsGoalsAgainst(id, event.target.value));
  }

  handleChangeYellowCards = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(statsYellowCards(id, event.target.value));
  }

  handleChangeRedCards = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(statsRedCards(id, event.target.value));
  }

  handleChangePoints = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(statsPoints(id, event.target.value));
  }

  render() {
    const { stats } = this.props;
    const { teamId, teamName, matchesPlayed, numOfWins, numOfLosses, numOfDraws, goalsFor, goalsAgainst, numOfYellowCards, numOfRedCards, points } = stats;
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Stats Information
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Team Id
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="TeamId"
                name="teamId" 
                value={teamId} onChange={this.handleChangeTeamId} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Team Name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="TeamName"
                name="teamName" 
                value={teamName} onChange={this.handleChangeTeamName} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Matches Played
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Matches Played"
                name="matchesPlayed" 
                value={matchesPlayed} onChange={this.handleChangeMatchesPlayed} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Wins
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="NumOfWins"
                name="numOfWins" 
                value={numOfWins} onChange={this.handleChangeWins} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Losses
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="NumOfLosses"
                name="numOfLosses" 
                value={numOfLosses} onChange={this.handleChangeLosses} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Draws
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="NumOfDraws"
                name="numOfDraws" 
                value={numOfDraws} onChange={this.handleChangeDraws} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Goals For
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="GoalsFor"
                name="goalsFor" 
                value={goalsFor} onChange={this.handleChangeGoalsFor} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Goals Against
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="GoalsAgainst"
                name="goalsAgainst" 
                value={goalsAgainst} onChange={this.handleChangeGoalsAgainst} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Yellow Cards
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="NumOfYellowCards"
                name="numOfYellowCards" 
                value={numOfYellowCards} onChange={this.handleChangeYellowCards} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Red Cards
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="NumOfRedCards"
                name="numOfRedCards" 
                value={numOfRedCards} onChange={this.handleChangeRedCards} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Points
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Points"
                name="ponts" 
                value={points} onChange={this.handleChangePoints} 
              />
            </div>
          </div>
          <div className="px-4 py-4 md:px-10 mx-auto w-full text-center">
            <button
                className="font-normal text-lightBlue-500 px-6"
                onClick={this.handleRemoveTeamStats}
            >
              <i className="fas fa-trash-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
              remove stats
            </button>
         </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  error: state.events.error
});

export default connect(mapStateToProps)(StatsInfo);
