import React from "react";
import { connect } from "react-redux";

class CardPlayer extends React.Component {

  render() {
    const { player } = this.props;
    const { name, team, club, birthDate, position, number } = player;
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
          <div className="px-6">
            <div className="flex flex-wrap justify-center">
              <div className="w-full px-4 flex justify-center">
                <div className="relative">
                  <img
                    alt="..."
                    src={require("assets/img/profile-bg.jpeg").default}
                    className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                  />
                </div>
              </div>
            </div>
            <div className="text-center mt-12">
              <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                {name}
              </h3>
              <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                <i className="fas fa-running mr-2 text-lg text-blueGray-400"></i>{" "}
                {position}
              </div>
              <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                <i className="fas fa-tshirt mr-2 text-lg text-blueGray-400"></i>{" "}
                {number}
              </div>
              <div className="mb-2 text-blueGray-600 mt-10">
                <i className="fas fa-birthday-cake mr-2 text-lg text-blueGray-400"></i>
                {birthDate}
              </div>
              <div className="mb-2 text-blueGray-600">
                <i className="fas fa-school mr-2 text-lg text-blueGray-400"></i>
                {club} {team}
              </div>
            </div>
            <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(CardPlayer);
