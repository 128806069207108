import React from "react";

// components

import PlayerRule from "components/Cards/PlayerRule.js";

export default function Players() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <PlayerRule />
        </div>
      </div>
    </>
  );
}
