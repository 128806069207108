import React from "react";
import { connect } from "react-redux";
import { changePracticeField } from "teams/teamSlice";
import { changeCoach } from "teams/teamSlice";
import { changeManager } from "teams/teamSlice";
import { changeCoachEmail } from "teams/teamSlice";
import { changeManagerEmail } from "teams/teamSlice";
import { changeCompetitiveLevel } from "teams/teamSlice";
import { changePlayerBirthYear } from "teams/teamSlice";
import { changeState } from "teams/teamSlice";
import { changeCity } from "teams/teamSlice";
import { changeName } from "teams/teamSlice";

class TeamInfo extends React.Component {

  handleChangeName = event => {
    event.preventDefault();
    this.props.dispatch(changeName(event.target.value));
  }

  handleChangeCity = event => {
    event.preventDefault();
    this.props.dispatch(changeCity(event.target.value));
  }

  handleChangeState = event => {
    event.preventDefault();
    this.props.dispatch(changeState(event.target.value));
  }

  handleChangePlayerBirthYear = event => {
    event.preventDefault();
    this.props.dispatch(changePlayerBirthYear(event.target.value));
  }

  handleChangePracticeField = event => {
    event.preventDefault();
    this.props.dispatch(changePracticeField(event.target.value));
  }

  handleChangeCompetitiveLevel = event => {
    event.preventDefault();
    this.props.dispatch(changeCompetitiveLevel(event.target.value));
  }

  handleChangeCoach = event => {
    event.preventDefault();
    this.props.dispatch(changeCoach(event.target.value));
  }

  handleChangeCoachEmail = event => {
    event.preventDefault();
    this.props.dispatch(changeCoachEmail(event.target.value));
  }

  handleChangeManager = event => {
    event.preventDefault();
    this.props.dispatch(changeManager(event.target.value));
  }

  handleChangeManagerEmail = event => {
    event.preventDefault();
    this.props.dispatch(changeManagerEmail(event.target.value));
  }

  render() {
    const { name, city, state, playerBirthYear, practiceField, competitiveLevel, coach, coachEmail, manager, managerEmail } = this.props;
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Team Information
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Name"
                name="name" 
                value={name} onChange={this.handleChangeName} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Practice Field
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="PracticeField"
                name="practiceField" 
                value={practiceField} onChange={this.handleChangePracticeField} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                City
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="city"
                name="city" 
                value={city} onChange={this.handleChangeCity} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                State
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="State"
                name="state" 
                value={state} onChange={this.handleChangeState} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Player Birth Year
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Player Birth Year"
                name="playerBirthYear" 
                value={playerBirthYear} onChange={this.handleChangePlayerBirthYear} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Competitive Level
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Competitive Level"
                name="competitiveLevel" 
                value={competitiveLevel} onChange={this.handleChangeCompetitiveLevel} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Coach
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Coach"
                name="coach" 
                value={coach} onChange={this.handleChangeCoach} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Coach Email
              </label>
              <input
                type="email"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Coach Email"
                name="coachEmail" 
                value={coachEmail} onChange={this.handleChangeCoachEmail} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Manager
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Manager"
                name="manager" 
                value={manager} onChange={this.handleChangeManager} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Manager Email
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Manager Email"
                name="managerEmail" 
                value={managerEmail} onChange={this.handleChangeManagerEmail} 
              />
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  name: state.teams.name,
  city: state.teams.city,
  state: state.teams.state,
  playerBirthYear: state.teams.playerBirthYear,
  practiceField: state.teams.practiceField,
  competitiveLevel: state.teams.competitiveLevel,
  coach: state.teams.coach,
  coachEmail: state.teams.coachEmail,
  manager: state.teams.manager,
  managerEmail: state.teams.managerEmail,
  error: state.teams.error
});

export default connect(mapStateToProps)(TeamInfo);
