import { playPlayers } from "events/eventSlice";
import { playLink } from "events/eventSlice";
import { playSuggestion } from "events/eventSlice";
import { removePlay } from "events/eventSlice";
import { playCall } from "events/eventSlice";
import { playStart } from "events/eventSlice";
import { playChangeType } from "events/eventSlice";
import { playEnd } from "events/eventSlice";
import { playSource } from "events/eventSlice";
import { playRating } from "events/eventSlice";
import { playName } from "events/eventSlice";
import CardPreview from "components/Cards/CardPreview";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import ActionInfo from "./ActionInfo";
import { playActionAdded } from "events/eventSlice";

class PlayInfo extends React.Component {

  handleRemovePlay = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(removePlay(id));
  }

  handleChangeName = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playName(id, event.target.value));
  }

  handleChangePlayType = playType => {
    const { id } = this.props;
    this.props.dispatch(playChangeType(id, playType.value));
  }

  handleChangeLink = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playLink(id, event.target.value));
  }

  handleChangePlayers = player => {
    const { id } = this.props;
    const playerSelected = player.map(cur => cur.value);
    this.props.dispatch(playPlayers(id, playerSelected));
  }

  handleChangeSuggestion = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playSuggestion(id, event.target.value));
  }

  handleChangeSource = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playSource(id, event.target.value));
  }

  handleChangeStartOffset = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playStart(id, event.target.value));
  }

  handleChangeEndOffset = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playEnd(id, event.target.value));
  }

  handleChangeCall = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playCall(id, event.target.value));
  }

  handleChangeRating = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playRating(id, event.target.value));
  }

  handlePlayActionAdded = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playActionAdded(id));
  }

  render() {
    const { id, play, attendees } = this.props;
    const { name, playType, source, link, players, suggestion, startOffset, endOffset, actionCodes } = play;
    const playTypeSelected = {label: playType, value: playType};
    const playTypeOptions = [{label: 'SCORE', value: 'SCORE'}, {label: 'CHANCE', value: 'CHANCE'}, {label: 'EXECUTION', value: 'EXECUTION'}, {label: 'DRIBBLING', value: 'DRIBBLING'}, {label: 'PASSING', value: 'PASSING'}, {label: 'SHOOTING', value: 'SHOOTING'}, {label: 'OTHER', value: 'OTHER'}];
    const playerSelected = players ? players.map(cur => { return {label: cur, value: cur} }) : [];
    const playerOptions = attendees ? attendees.map(attendee => {
      return { label: attendee.player, value: attendee.player }
    }) : [];
    const actionInput = !actionCodes ? <div></div> : actionCodes.map(curAction => {
      return (<div key={curAction.acid}><ActionInfo id={id} acid={curAction.acid} actionCode={curAction} /><hr className="mt-6 border-b-1 border-blueGray-300" /></div>);
    });
    return (
      <>
      <div className="flex flex-wrap">
        <h6 className="text-blueGray-400 w-full lg:w-6/12 text-sm mt-3 px-4 mb-6 font-bold uppercase">
          Play Information
        </h6>
        <div>
        <button
          className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 mt-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          onClick={this.handlePlayActionAdded}
        >
          <i className="fas fa-user-plus text-white-400 mr-2 text-sm"></i>{" "}
          Add Action
        </button>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-12/12 px-0">
            <div className="relative w-full mb-3">
              <CardPreview url={link} />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Name"
                name="name" 
                value={name} onChange={this.handleChangeName} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Type
              </label>
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={playTypeOptions} value={playTypeSelected} onChange={this.handleChangePlayType} />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Source
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Source"
                name="source" 
                value={source} onChange={this.handleChangeSource} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Link
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Link"
                name="link" 
                value={link} onChange={this.handleChangeLink} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Start
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Start"
                name="startOffset" 
                value={startOffset} onChange={this.handleChangeStartOffset} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                End
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="End"
                name="endOffset" 
                value={endOffset} onChange={this.handleChangeEndOffset} 
              />
            </div>
          </div>
          <div className="w-full lg:w-8/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Players
              </label>
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={playerOptions} value={playerSelected} isMulti onChange={this.handleChangePlayers} />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Coach's Note
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Suggestion"
                name="suggestion" 
                value={suggestion} onChange={this.handleChangeSuggestion} 
              />
            </div>
          </div>
          <hr className="mt-6 border-b-1 border-blueGray-300" />
          {actionInput}
          <div className="px-4 py-4 md:px-10 mx-auto w-full text-center">
            <button
                className="font-normal text-lightBlue-500 px-6"
                onClick={this.handleRemovePlay}
            >
              <i className="fas fa-trash-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
              remove play
            </button>
         </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  players: state.users.players,
  error: state.users.error
});

export default connect(mapStateToProps)(PlayInfo);
