import React from 'react';
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { forwardRef } from 'react';

import AddBox from '@mui/icons-material/AddBox';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import { removeEvent } from 'events/eventSlice';
import { getEvent } from 'events/eventSlice';
import { addAttendee } from 'events/eventSlice';
import { updateAttendee } from 'events/eventSlice';
import { removeAttendee } from 'events/eventSlice';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
};

class CardTable extends React.Component {

  addAttendee(data) {
    const { id } = this.props;
    const { eventList } = this.props;
    const currentEvent = eventList.find((event) => event.id === id);
    const newEvent = {...currentEvent, attendee: data};
    this.props.dispatch(addAttendee(newEvent));
  }

  editAttendee(index, data) {
    const { id } = this.props;
    const { eventList } = this.props;
    const currentEvent = eventList.find((event) => event.id === id);
    const newEvent = {...currentEvent, index: index, attendee: data};
    this.props.dispatch(updateAttendee(newEvent));
  }

  deleteAttendee(index) {
    const { id } = this.props;
    const { eventList } = this.props;
    const currentEvent = eventList.find((event) => event.id === id);
    const newEvent = {...currentEvent, index: index};
    this.props.dispatch(removeAttendee(newEvent));
  }

  handleRemoveGame = event => {
    event.preventDefault();
    const { eventList, id } = this.props;
    const currentEvent = eventList.find((event) => event.id === id);
    if (currentEvent && currentEvent.attendees && currentEvent.attendees.length > 0) {
      alert("Please check with people also interested in participating");
    } else {
      this.props.dispatch(removeEvent(currentEvent));
    }
  }

  handleReloadAttendee = event => {
    event.preventDefault();
    const { eventList, id } = this.props;
    const currentEvent = eventList.find((event) => event.id === id);
    this.props.dispatch(getEvent(currentEvent));
  }

  render () {
    const { id } = this.props;
    const { eventList } = this.props;
    const currentEvent = eventList.find((event) => event.id === id);
    var title = currentEvent ? (new Date(currentEvent.eventTime)).toLocaleString() + " " + currentEvent.location : '';
    if (title && currentEvent) {
      if (currentEvent.name) {
        title += " " + currentEvent.name;
      }
    }
    if (currentEvent && currentEvent.type === 'TOURNAMENT') {
      title = currentEvent.name + " Signup";
    }
    const columns = [
      { title: 'player', field: 'player' },
      { title: 'available', field: 'available' },
      // { title: 'available', field: 'available', lookup: { 38: 'Yes', 67: 'No' } },
      { title: 'position', field: 'position' },
      { title: 'parent', field: 'parent' },
      { title: 'email', field: 'emailAddress' },
      { title: 'phone', field: 'phoneNumber' },
      { title: 'volunteer', field: 'volunteer' },
      { title: 'id', field: 'id', hidden: true },
    ];
    const data = !(currentEvent && currentEvent.attendees) ? [] : currentEvent.attendees.map(o => ({ ...o }));
    // const data = [];

    return (
      <div style={{ maxWidth: "100%", paddingTop: "12px" }}>
        <MaterialTable
          title={title}
          columns={columns}
          data={data}
          icons={tableIcons}
          options={{
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF"
            },
            actionsColumnIndex: 0,
            paging: false,
            padding: 'dense',
            grouping: false,
            showEmptyDataSourceMessage: false,
            search: false,
            fixedColumns: {
              // left: 1
            }
          }}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  this.addAttendee(newData);

                  resolve();
                }, 5)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  // const dataUpdate = [...data];
                  const index = oldData.tableData.id;
                  // dataUpdate[index] = newData;
                  this.editAttendee(index, newData);

                  resolve();
                }, 5)
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  // const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  // dataDelete.splice(index, 1);
                  this.deleteAttendee(index);
                  
                  resolve()
                }, 5)
              }),
          }}
        />
        <div className="px-1 py-4 md:px-1 mx-auto w-full text-center">
          <button
              className="font-normal text-lightBlue-500 px-6"
              onClick={this.handleRemoveGame}
          >
            <i className="fas fa-trash-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
            remove game
          </button>
          <button
              className="font-normal text-lightBlue-500 px-6"
              onClick={this.handleReloadAttendee}
          >
            <i className="fas fa-sync-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
            reload
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  eventList: state.events.eventList,
  error: state.events.error
});

export default connect(mapStateToProps)(CardTable);