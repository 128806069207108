import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { customAlphabet } from 'nanoid'
import axios from 'axios';
import Cookies from 'universal-cookie';

const nanoid = customAlphabet('1234567890', 8)

export const userLogin = createAsyncThunk("users/userLogin", async (user, { rejectWithValue }) => {
  try {
    const res = await axios.post("/api/login", user)
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    const cookies = new Cookies();
    cookies.set('fa-access-token', res.data, { path: '/', secure: false, expires: (new Date(Date.now() + 30 * 86400 * 1000))});
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getCurrentUser = createAsyncThunk("users/getCurrentUser", async (token, { rejectWithValue }) => {
  try {
    const res = await axios.get("/api/current_user", {
      headers: {
        'x-auth-token': token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const userRegister = createAsyncThunk("users/userRegister", async (user, { rejectWithValue }) => {
  try {
    const res = await axios.post("/api/register", user)
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const forgotPassword = createAsyncThunk("users/forgotPassword", async (email, { rejectWithValue }) => {
  try {
    const res = await axios.put("/api/forgot-password/" + email)
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getProfile = createAsyncThunk("users/getProfile", async (email, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.get("/api/get_profile", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        email: email
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const listUsers = createAsyncThunk("users/listUsers", async (team, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.get("/api/list_users", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        team: team
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const updateProfile = createAsyncThunk("users/updateProfile", async (user, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/update_profile", user, {
      headers: {
        'Authorization': 'Bearer ' + token
      },
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const updatePlayerContact = createAsyncThunk("users/updatePlayerContact", async (user, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/update_player_contact", user, {
      headers: {
        'Authorization': 'Bearer ' + token
      },
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const changeToNewPassword = createAsyncThunk("users/changeToNewPassword", async (user, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/change-password", user, {
      headers: {
        'Authorization': 'Bearer ' + token
      },
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const addRole = createAsyncThunk("users/addRole", async (user, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.put("/api/add_role/" + user.emailAddress + "/" + user.role, user, {
      headers: {
        'Authorization': 'Bearer ' + token
      },
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const deleteRole = createAsyncThunk("users/deleteRole", async (user, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.delete("/api/delete_role/" + user.emailAddress + "/" + user.role, {
      headers: {
        'Authorization': 'Bearer ' + token
      },
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const deleteUser = createAsyncThunk("users/deleteUser", async (email, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.delete("/api/delete_user/" + email, {
      headers: {
        'Authorization': 'Bearer ' + token
      },
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

const initialState = {
  emailAddress: '',
  id: '',
  password: '',
  fullName: '',
  userName: '',
  phoneNumber: '',
  zipCode: '',
  address: '',
  role: '',
  isAuthenticated: false,
  isRegistered: false,
  userList: [],
  players: [],
  currentUser: undefined,
  oldPassword: '',
  newPassword: '',
  loading: false,
  error: null
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    changeId(state, action) {
      state.id = action.payload;
      state.error = null;
    },
    changeEmail(state, action) {
      state.emailAddress = action.payload;
      state.error = null;
    },
    changeUserName(state, action) {
      state.userName = action.payload;
      state.error = null;
    },
    changePassword(state, action) {
      state.password = action.payload;
      state.error = null;
    },
    changeFullName(state, action) {
      state.fullName = action.payload;
      state.error = null;
    },
    changePhoneNumber(state, action) {
      state.phoneNumber = action.payload;
      state.error = null;
    },
    changeAddress(state, action) {
      state.address = action.payload;
      state.error = null;
    },
    changeZipCode(state, action) {
      state.zipCode = action.payload;
      state.error = null;
    },
    changeOldPassword(state, action) {
      state.oldPassword = action.payload;
      state.error = null;
    },
    changeNewPassword(state, action) {
      state.newPassword = action.payload;
      state.error = null;
    },
    changeRole(state, action) {
      state.role = action.payload;
      state.error = null;
    },
    changePlayers(state, action) {
      state.players = action.payload;
      state.error = null;
    },
    resetError(state, action) {
      state.error = null
    },
    resetAuthenticated(state, action) {
      state.isAuthenticated = false;
    },
    resetRegistered(state, action) {
      state.isRegistered = false;
    },
    resetCurrentUser(state, action) {
      state.currentUser = undefined;
    },
    playerAdded: {
      reducer(state, action) {
        if (!state.players) {
          state.players = [action.payload];
        } else {
          state.players.push(action.payload);
        }
      },
      prepare() {
        return {
          payload: {
            id: nanoid(),
            name: '',
            club: '',
            team: '',
            birthDate: '',
            position: '',
            number: '',
            birthYear: 2012
          }
        }
      }
    },
    playerName: {
      reducer(state, action) {
        return {
          ...state,
          players: state.players.map(player => {
            if (player.id === action.payload.id) {
              return { 
                ...player, 
                name: action.payload.name
              };
            } else {
              return player;
            }
          })
        };
      },
      prepare(id, name) {
        return {
          payload: {
            id: id,
            name: name
          }
        }
      }
    },
    playerId: {
      reducer(state, action) {
        return {
          ...state,
          players: state.players.map(player => {
            if (player.id === action.payload.id) {
              return { 
                ...player, 
                id: action.payload.playerId
              };
            } else {
              return player;
            }
          })
        };
      },
      prepare(id, playerId) {
        return {
          payload: {
            id: id,
            playerId: playerId
          }
        }
      }
    },
    playerBirthDate: {
      reducer(state, action) {
        return {
          ...state,
          players: state.players.map(player => {
            if (player.id === action.payload.id) {
              return { 
                ...player, 
                birthDate: action.payload.birthDate
              };
            } else {
              return player;
            }
          })
        };
      },
      prepare(id, birthDate) {
        return {
          payload: {
            id: id,
            birthDate: birthDate
          }
        }
      }
    },
    playerTeam: {
      reducer(state, action) {
        return {
          ...state,
          players: state.players.map(player => {
            if (player.id === action.payload.id) {
              return { 
                ...player, 
                team: action.payload.team
              };
            } else {
              return player;
            }
          })
        };
      },
      prepare(id, team) {
        return {
          payload: {
            id: id,
            team: team
          }
        }
      }
    },
    playerClub: {
      reducer(state, action) {
        return {
          ...state,
          players: state.players.map(player => {
            if (player.id === action.payload.id) {
              return { 
                ...player, 
                club: action.payload.club
              };
            } else {
              return player;
            }
          })
        };
      },
      prepare(id, club) {
        return {
          payload: {
            id: id,
            club: club
          }
        }
      }
    },
    playerPosition: {
      reducer(state, action) {
        return {
          ...state,
          players: state.players.map(player => {
            if (player.id === action.payload.id) {
              return { 
                ...player, 
                position: action.payload.position
              };
            } else {
              return player;
            }
          })
        };
      },
      prepare(id, position) {
        return {
          payload: {
            id: id,
            position: position
          }
        }
      }
    },
    playerNumber: {
      reducer(state, action) {
        return {
          ...state,
          players: state.players.map(player => {
            if (player.id === action.payload.id) {
              return { 
                ...player, 
                number: action.payload.number
              };
            } else {
              return player;
            }
          })
        };
      },
      prepare(id, number) {
        return {
          payload: {
            id: id,
            number: number
          }
        }
      }
    },
    playerBirthYear: {
      reducer(state, action) {
        return {
          ...state,
          players: state.players.map(player => {
            if (player.id === action.payload.id) {
              return { 
                ...player, 
                birthYear: action.payload.birthYear
              };
            } else {
              return player;
            }
          })
        };
      },
      prepare(id, birthYear) {
        return {
          payload: {
            id: id,
            birthYear: birthYear
          }
        }
      }
    },
    playerContacts: {
      reducer(state, action) {
        return {
          ...state,
          players: state.players.map(player => {
            if (player.id === action.payload.id) {
              return { 
                ...player, 
                contacts: action.payload.contacts
              };
            } else {
              return player;
            }
          })
        };
      },
      prepare(id, contacts) {
        return {
          payload: {
            id: id,
            contacts: contacts
          }
        }
      }
    },
    removePlayer(state, action) {
      state.players = state.players.filter((current) => current.id !== action.payload);
    }
  },
  extraReducers: {
    [userLogin.pending]: (state, action) => {
      state.loading = true;
    },
    [userLogin.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.currentUser = {
        emailAddress: state.emailAddress
      }
    },
    [userLogin.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getCurrentUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
      state.emailAddress = action.payload.emailAddress;
    },
    [getCurrentUser.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [userRegister.pending]: (state, action) => {
      state.loading = true;
    },
    [userRegister.fulfilled]: (state, action) => {
      state.loading = false;
      state.isRegistered = true;
      state.error = null;
    },
    [userRegister.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getProfile.pending]: (state, action) => {
      state.loading = true;
    },
    [getProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.emailAddress = action.payload.emailAddress;
      state.id = action.payload.id;
      state.fullName = action.payload.fullName;
      state.userName = action.payload.userName;
      state.zipCode = action.payload.zipCode;
      state.address = action.payload.address;
      state.phoneNumber = action.payload.phoneNumber;
      if (action.payload.players) {
        state.players = action.payload.players;
      }
      state.error = null;
    },
    [getProfile.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateProfile.pending]: (state, action) => {
      state.loading = true;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    [updateProfile.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updatePlayerContact.pending]: (state, action) => {
      state.loading = true;
    },
    [updatePlayerContact.fulfilled]: (state, action) => {
      state.loading = false;
      state.userList = state.userList.map(user => {
        if (user.id === action.payload.toString()) {
          return {
            ...user,
            phoneNumber: state.phoneNumber,
            players: state.players
          }
        } else {
          return user;
        }
      });
      state.error = null;
    },
    [updatePlayerContact.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [changeToNewPassword.pending]: (state, action) => {
      state.loading = true;
    },
    [changeToNewPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    [changeToNewPassword.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [addRole.pending]: (state, action) => {
      state.loading = true;
    },
    [addRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.userList = state.userList.map(user => {
        if (user.emailAddress === state.emailAddress) {
          return {
            ...user,
            roles: (!user.roles) ? [state.role] : user.roles.concat(state.role)
          }
        } else {
          return user;
        }
      })
      state.error = null;
    },
    [addRole.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteRole.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.userList = state.userList.map(user => {
        if (user.emailAddress === state.emailAddress) {
          return {
            ...user,
            roles: (!user.roles) ? user.roles : user.roles.filter((role) => role !== state.role)
          }
        } else {
          return user;
        }
      })
      state.error = null;
    },
    [deleteRole.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteUser.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.userList = state.userList.filter((user) => user.emailAddress !== action.payload);
      state.error = null;
    },
    [deleteUser.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [listUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [listUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.userList = action.payload;
      state.error = null;
    },
    [listUsers.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
})

export const { changeId, changeEmail, changePassword, changeFullName, changeUserName, changePhoneNumber, changeZipCode, changeAddress, changeRole,
  changePlayers, resetError, resetAuthenticated, resetRegistered, resetCurrentUser, playerAdded, playerId, playerName, removePlayer, playerBirthDate,
  playerTeam, playerClub, playerPosition, playerNumber, playerBirthYear, playerContacts, changeOldPassword, changeNewPassword } = userSlice.actions
export default userSlice.reducer