import React from "react";
import { connect } from "react-redux";
import UserInfo from "components/Cards/UserInfo.js";
import UserTable from "components/Cards/UserTable.js";
import PlayerInfo from "components/Cards/PlayerInfo.js";
import { playerAdded } from "users/userSlice";
import { addRole } from "users/userSlice";
import { deleteRole } from "users/userSlice";
import { updatePlayerContact } from "users/userSlice";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      button: 1
    }; 
  }

  handlePlayerAdded = event => {
    event.preventDefault();
    this.props.dispatch(playerAdded());
  }

  handleSubmit = event => {
    event.preventDefault();
    const { emailAddress, role, phoneNumber, players } = this.props;
    if (this.state.button === 1) {
      this.props.dispatch(addRole({emailAddress: emailAddress, role: role }));
      } else if (this.state.button === 2) {
      this.props.dispatch(deleteRole({emailAddress: emailAddress, role: role }));
    } else {
      this.props.dispatch(updatePlayerContact({emailAddress: emailAddress, phoneNumber: phoneNumber, players: players }));
    }
  }

  render() {
    const { aError, players } = this.props;
    const playerInput = !players ? <div></div> : players.map(curPlayer => {
      return (<div key={curPlayer.id}><PlayerInfo id={curPlayer.id} player={curPlayer} /><hr className="mt-6 border-b-1 border-blueGray-300" /></div>);
    });
    return (
      <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">User Information</h6>
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={this.handlePlayerAdded}
                >
                  <i className="fas fa-user-plus text-white-400 mr-2 text-sm"></i>{" "}
                  Add Player
                </button>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={this.handleSubmit}>
                <UserInfo />
                <hr className="mt-6 border-b-1 border-blueGray-300" />
                {playerInput}
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-2 py-5">
                    <div className="relative w-full mb-1 text-center">
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
                        type="submit" name="add" onClick={() => (this.setState({button: 1}))}
                      >
                        Add
                      </button>
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ml-2 mr-2 ease-linear transition-all duration-150"
                        type="submit" name="add" onClick={() => (this.setState({button: 2}))}
                      >
                        Delete
                      </button>
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ml-2 mr-2 ease-linear transition-all duration-150"
                        type="submit" name="add" onClick={() => (this.setState({button: 3}))}
                      >
                        Update
                      </button>
                    </div>
                    <div className="text-red-500 text-center">{aError}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-4">
          <UserTable />
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  emailAddress: state.users.emailAddress,
  phoneNumber: state.users.phoneNumber,
  players: state.users.players,
  role: state.users.role,
  aError: state.users.aError
});

export default connect(mapStateToProps)(User);
