import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Cookies from 'universal-cookie';
import { getCurrentUser } from "users/userSlice";

// components
import CardTable from "components/Cards/CardTable.js";
import { eventAdded } from "events/eventSlice";
import { listVenues } from "venues/venueSlice";
import { listEvents } from "events/eventSlice";
import { showTeams } from "teams/teamSlice";
import ScheduleTable from "components/Cards/ScheduleTable";
import { getLocalDate } from "utils/utils";
import ParticipantTable from "components/Cards/ParticipantTable";
import StatsTable from "components/Cards/StatsTable";
import { scheduleEvent } from "events/eventSlice";

class Planning extends React.Component {

  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    if (token && (!currentUser || !currentUser.roles) ) {
      this.props.dispatch(getCurrentUser(token));
    }
    this.state = { 
      eventTime: getLocalDate(new Date()),
      eventLocation: '',
      eventTag: ''
    }; 
  }

  componentDidMount() {
    const { venueList, teams, currentUser } = this.props;
    if (currentUser) {
      if (currentUser.roles) {
        var date = new Date();
        if (currentUser.roles.includes('ADMIN')) {
          date.setMonth(date.getMonth() - 24);
        } else if (currentUser.roles.includes('MANAGER') 
                  || currentUser.roles.includes('COACH') 
                  || currentUser.roles.includes('FILMING')) {
          date.setMonth(date.getMonth() - 2);
        }
        this.props.dispatch(listEvents({ startTime: date.getTime(), tags: currentUser.teams }));
        if (!venueList || venueList.length === 0) {
          this.props.dispatch(listVenues());
        }
      }
      if (currentUser.teams && (!teams || !teams.length)) {
        this.props.dispatch(showTeams());
      }
      if (currentUser.teams) {
        this.setState({
          eventTag: currentUser.teams[0]
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { venueList, currentUser, eventList } = this.props;
    if (currentUser && currentUser !== prevProps.currentUser) {
      if (currentUser.roles) {
        var date = new Date();
        if (currentUser.roles.includes('ADMIN')) {
          date.setMonth(date.getMonth() - 24);
        } else if (currentUser.roles.includes('MANAGER') 
                || currentUser.roles.includes('COACH') 
                || currentUser.roles.includes('FILMING')) {
          date.setMonth(date.getMonth() - 2);
        }
        this.props.dispatch(listEvents({ startTime: date.getTime(), tags: currentUser.teams }));
        if (!venueList || venueList.length === 0) {
          this.props.dispatch(listVenues());
        }
      }
      if (currentUser.teams) {
        this.props.dispatch(showTeams());
      }
    }
    if (currentUser && eventList != prevProps.eventList && eventList.length > prevProps.eventList.length
      && eventList[eventList.length - 1] !== null && (eventList[eventList.length - 1].attendees == null 
        || eventList[eventList.length - 1].attendees.length === 0)) {
      this.props.dispatch(scheduleEvent(eventList[eventList.length-1]));
    }
  }
  
  handleChangeEventTime = event => {
    event.preventDefault();
    this.setState({
      eventTime: event.target.value
    });
  }
  
  handleChangeEventLocation = location => {
    const { venueList } = this.props;
    const currentLocation = venueList.find((venue) => venue.name === location.value);
    this.setState({
      eventLocation: currentLocation
    });
  }

  handleChangeTag = tag => {
    this.setState({
      eventTag: tag.value
    });
  }
  
  handleEventAdded = event => {
    event.preventDefault();
    const { eventTime, eventLocation, eventTag } = this.state;
    // const eventLocation = {name: 'greer'};
    // const eventTag = "11212";
    // const eventTime = (new Date()).getTime();
    if (!eventLocation || !eventTag) {
      alert("Please choose a location and team");
    } else {
      this.props.dispatch(eventAdded((new Date(eventTime)).getTime(), eventLocation, eventTag));
    }
  }

  render () {
    const { currentUser, teams } = this.props;
    if (!currentUser || !currentUser.roles || !currentUser.teams) {
      return (<div>Allow us 10 minutes to add you to the right team... please check your email and update your profile for us to better assist you</div>);
    }
    const { eventTime } = this.state;
    const { eventList, venueList, error } = this.props;
    const locationOptions = venueList ? venueList.map(venue => { return { label: venue.name, value: venue.name } }) : [];
    // const locationOptions = [{value: "JLS", label: "jls"}, {value: "Mayfield", label: "mayfield"}];
    const tagOptions = teams.map(team => { return {label: team.name, value: team.id} });
    // const tagOptions = [{value: "1001", label: "invitation"}];
    const { eventTag } = this.state;
    const selectTeam = teams.find((team) => team.id === eventTag);
    const teamSelected = (selectTeam && selectTeam !== undefined) ? {label: selectTeam.name, value: selectTeam.id} : {};
    const eventTable = eventList ? eventList.filter((event) => (!event.tags || event.tags.includes(eventTag))).map(curEvent => { if (curEvent.type === 'TOURNAMENT') {
        return (<div key={curEvent.id} className="w-full lg:w-6/12 mb-12 px-4 flex flex-wrap">
          <ScheduleTable id={curEvent.id} title={curEvent.name} schedules={curEvent.schedules} />
          {/* <ParticipantTable id={curEvent.id} title={curEvent.name} participants={curEvent.participants} /> */}
          <CardTable id={curEvent.id} />
          {/* <StatsTable id={curEvent.id} title={curEvent.name} stats={curEvent.teamStats} /> */}
          </div>)
      } else {
        return (<div key={curEvent.id} className="w-full lg:w-6/12 mb-12 px-4 flex flex-wrap"><CardTable id={curEvent.id} /></div>);
      }
    }) : [];
    return (
      <>
        <form onSubmit={this.handleEventAdded}>
          <div className="relative bg-lightBlue-100 :pt-3 pb-4 pt-4">
            <div className="px-4 md:px-10 mx-auto w-full text-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                    Time
                  </label>
                  <input
                    type="datetime-local"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Time"
                    name="eventTime"
                    value={eventTime} onChange={this.handleChangeEventTime} 
                    required
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                    Location
                  </label> 
                  <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <Select options={locationOptions} required onChange={this.handleChangeEventLocation} />
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                    Team
                  </label> 
                  <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <Select options={tagOptions} value={teamSelected} required onChange={this.handleChangeTag} />
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4 py-6">
                <div className="relative w-full mb-3">
                  <button
                    className="bg-lightBlue-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit" name="submit"
                  >
                    new game
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="px-4 md:px-10 mx-auto w-full">
          <div className="w-full flex flex-wrap">
            {eventTable}
          </div>
          <div className="text-red-500 text-center">{error}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  teams: state.teams.teams,
  eventList: state.events.eventList,
  venueList: state.venues.venueList,
  error: state.events.error
});

export default connect(mapStateToProps)(Planning);
