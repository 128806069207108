import React from "react";
import { useEffect, useRef } from "react";

export default function Preview({url}) {
  const videoRef = useRef();

  useEffect(() => {    
    videoRef.current?.load();
  }, [url]);

  return (
    <video controls width="853" height="600" ref={videoRef}>
      <source src={url} type="video/mp4" />
    </video> 
  )
}
