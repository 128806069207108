import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { customAlphabet } from 'nanoid'
import axios from 'axios';
import { stringify } from 'qs'
import Cookies from 'universal-cookie';
import { formatDate } from '../utils/utils';

const nanoid = customAlphabet('1234567890', 8)

export const listEngagements = createAsyncThunk("engagements/listEngagements", async (engagement, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const listAxios = axios.create({
      paramsSerializer: {
        serialize: stringify,
        indices: false
      }
    });
    const res = await listAxios.get("/api/list_engagements", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        startTime: engagement.startTime,
        endTime: engagement.endTime,
        teams: engagement.teams
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const showEngagements = createAsyncThunk("engagements/showEngagements", async (teams, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const listAxios = axios.create({
      paramsSerializer: {
        serialize: stringify,
        indices: false
      }
    });
    const res = await listAxios.get("/api/show_engagements", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        teams: teams
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const getEngagement = createAsyncThunk("engagements/getEngagement", async (engagement, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.get("/api/get_engagement", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        time: formatDate(new Date(engagement.timeKey)),
        teamId: engagement.teamId,
        id: engagement.id
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const updateEngagement = createAsyncThunk("engagements/updateEngagement", async (engagement, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/update_engagement", engagement, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const removeEngagement = createAsyncThunk("engagements/removeEngagement", async (engagement, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.delete("/api/remove_engagement/" + engagement.teamId + "/" + formatDate(new Date(engagement.month))+ "/" 
      + engagement.userId, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

const initialState = {
  id: '',
  userName: '',
  userEmail: '',
  userPhone: '',
  userId: '',
  month: 0,
  teamId: '',
  datesOfSignup: [],
  datesOfParticipation: [],
  payments: [],
  volunteers: [],
  numOfPlayers: [],
  engagements: [],
  engagementList: [],
  loading: false,
  uError: null,
  error: null
}

const engagementSlice = createSlice({
  name: 'engagements',
  initialState,
  reducers: {
    engagementAdded: {
      reducer(state, action) {
        state.engagements.push(action.payload);
      },
      prepare(teamId, month, userEmail) {
        return {
          payload: {
            id: nanoid(),
            userEmail: userEmail,
            teamId: teamId,
            month: month,
            userId: '',
            userName: '',
            userPhone: '',
            payments: [],
            volunteers: [],
            numOfPlayers: []
          }
        }
      }
    },
    changeId(state, action) {
      state.id = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeUserName(state, action) {
      state.userName = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeUserEmail(state, action) {
      state.userEmail = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeUserPhone(state, action) {
      state.userPhone = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeNumOfPlayers(state, action) {
      state.numOfPlayers = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeUserId(state, action) {
      state.userId = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeMonth(state, action) {
      state.month = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeTeamId(state, action) {
      state.teamId = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeSignup(state, action) {
      state.datesOfSignup = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeParticipation(state, action) {
      state.datesOfParticipation = action.payload;
      state.error = null;
      state.uError = null;
    },
    changePayments(state, action) {
      state.payments = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeVolunteers(state, action) {
      state.volunteers = action.payload;
      state.error = null;
      state.uError = null;
    },
    paymentAdded: {
      reducer(state, action) {
        if (!state.payments) {
          state.payments = [action.payload];
        } else {
          state.payments.push(action.payload);
        }
      },
      prepare() {
        return {
          payload: {
            id: nanoid(),
            amount: 10.0,
            method: 'PayPal',
            submitTime: 0
          }
        }
      }
    },
    paymentAmount: {
      reducer(state, action) {
        return {
          ...state,
          payments: state.payments.map(payment => {
            if (payment.id === action.payload.id) {
              return { 
                ...payment, 
                amount: action.payload.amount
              };
            } else {
              return payment;
            }
          })
        };
      },
      prepare(id, amount) {
        return {
          payload: {
            id: id,
            amount: amount
          }
        }
      }
    },
    paymentMethod: {
      reducer(state, action) {
        return {
          ...state,
          payments: state.payments.map(payment => {
            if (payment.id === action.payload.id) {
              return { 
                ...payment, 
                method: action.payload.method
              };
            } else {
              return payment;
            }
          })
        };
      },
      prepare(id, method) {
        return {
          payload: {
            id: id,
            method: method
          }
        }
      }
    },
    paymentSubmitTime: {
      reducer(state, action) {
        return {
          ...state,
          payments: state.payments.map(payment => {
            if (payment.id === action.payload.id) {
              return { 
                ...payment, 
                submitTime: action.payload.submitTime
              };
            } else {
              return payment;
            }
          })
        };
      },
      prepare(id, submitTime) {
        return {
          payload: {
            id: id,
            submitTime: submitTime
          }
        }
      }
    },
    removePayment(state, action) {
      state.payments = state.payments.filter((current) => current.id !== action.payload);
    },
    volunteerAdded: {
      reducer(state, action) {
        if (!state.volunteers) {
          state.volunteers = [action.payload];
        } else {
          state.volunteers.push(action.payload);
        }
      },
      prepare() {
        return {
          payload: {
            id: nanoid(),
            role: 'Ref',
            serveTime: 0
          }
        }
      }
    },
    volunteerRole: {
      reducer(state, action) {
        return {
          ...state,
          volunteers: state.volunteers.map(volunteer => {
            if (volunteer.id === action.payload.id) {
              return { 
                ...volunteer, 
                role: action.payload.role
              };
            } else {
              return volunteer;
            }
          })
        };
      },
      prepare(id, role) {
        return {
          payload: {
            id: id,
            role: role
          }
        }
      }
    },
    volunteerServeTime: {
      reducer(state, action) {
        return {
          ...state,
          volunteers: state.volunteers.map(volunteer => {
            if (volunteer.id === action.payload.id) {
              return { 
                ...volunteer, 
                serveTime: action.payload.serveTime
              };
            } else {
              return volunteer;
            }
          })
        };
      },
      prepare(id, serveTime) {
        return {
          payload: {
            id: id,
            serveTime: serveTime
          }
        }
      }
    },
    removeVolunteer(state, action) {
      state.volunteers = state.volunteers.filter((current) => current.id !== action.payload);
    }
  },
  extraReducers: {
    [listEngagements.pending]: (state, action) => {
      state.loading = true;
    },
    [listEngagements.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagementList = action.payload;
    },
    [listEngagements.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [showEngagements.pending]: (state, action) => {
      state.loading = true;
    },
    [showEngagements.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagements = action.payload;
    },
    [showEngagements.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagementList = state.engagementList.map(engagement => {
        if (engagement.userId === action.payload.userId) {
          return { 
            ...engagement, 
            payments: action.payload.payments,
            volunteers: action.payload.volunteers
          };
        } else {
          return engagement;
        }
      })
      state.error = null;
    },
    [getEngagement.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagements = state.engagements.map(engagement => {
        if (engagement.id === action.payload.id) {
          return {
            ...engagement,
            userId: action.payload.userId,
            userName: state.userName,
            userEmail: state.userEmail,
            userPhone: state.userPhone,
            month: state.month,
            teamId: state.teamId,
            payments: state.payments,
            volunteers: state.volunteers,
            datesOfSignup: state.datesOfSignup,
            datesOfParticipation: state.datesOfParticipation,
            numOfPlayers: state.numOfPlayers
          }
        } else {
          return engagement;
        }
      });
      state.error = null;
      state.uError = null;
    },
    [updateEngagement.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [removeEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [removeEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagements = state.engagements.filter((current) => current.userId !== action.payload.toString() );
      state.error = null;
      state.uError = null;
    },
    [removeEngagement.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
})

export const { engagementAdded, changeId, changeUserName, changeUserEmail, changeUserPhone, changeUserId, changeMonth, changeTeamId, 
  changeSignup, changeParticipation, changePayments, paymentAdded, paymentAmount, paymentMethod, paymentSubmitTime, removePayment, 
  changeVolunteers, volunteerAdded, volunteerRole, volunteerServeTime, removeVolunteer, changeNumOfPlayers } = engagementSlice.actions
export default engagementSlice.reducer