/*eslint-disable*/
import React from "react";
import { connect } from "react-redux";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Cookies from 'universal-cookie';
import { getCurrentUser } from "users/userSlice";

class Index extends React.Component {
  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    if ((!currentUser || !currentUser.roles) && token) {
      this.props.dispatch(getCurrentUser(token));
    }
  }

  render() {
    const { currentUser } = this.props;
    var welcomeMsg = "Welcome to join us";
    if (currentUser && currentUser.userName) {
      welcomeMsg = "Thanks for coming back " + currentUser.userName;
      if (!currentUser.roles) {
        welcomeMsg += " Please contact your team coach / manager to add you. Contact falinks.net@gmail.com for other issues";
      }
    }
    return (
      <>
        <IndexNavbar fixed />
        <section className="pb-16 bg-blueGray-200 relative pt-32">

          <div className="container mx-auto px-4 pb-32 pt-48">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
                <div className="md:pr-12">
                  <h3 className="text-3xl font-semibold">
                    Kids Soccer Games
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    {welcomeMsg}
                  </p>
              
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <h4 className="text-blueGray-500">
                            Organized by parents.
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <h4 className="text-blueGray-500">
                            Officiated and Video-taped.
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <h4 className="text-blueGray-500">
                            Competitive and fun.
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-xl"
                  style={{
                    transform:
                      "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                  }}
                  src={require("assets/img/falinks-cover.jpeg").default}
                />
              </div>
            </div>
          </div>

        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  error: state.users.error
});

export default connect(mapStateToProps)(Index);
