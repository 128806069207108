import React from "react";
import { connect } from "react-redux";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import { resetCurrentUser, resetError } from "users/userSlice";

class UserDropdown extends React.Component {

  logInUser = () => {
  };
  logOutUser = () => {
    const cookies = new Cookies();
    cookies.remove('fa-access-token', { path: '/' });
    this.props.dispatch(resetCurrentUser());
    this.props.dispatch(resetError());
  };

  render() {
    const { currentUser } = this.props;
    var userNew = !currentUser;
    return (
      <>
        <Link
          className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
          to={userNew ? '/auth/login' : '/'}
          // ref={btnClickRef}
          onClick={(e) => {
            userNew ? this.logInUser() : this.logOutUser();
          }}
        >
          <i className="text-blueGray-400 fas fa-user-circle text-lg leading-lg mr-2" />
          {userNew ? 'Login' : 'Logout'}
        </Link>
      </>
    );
  };
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser
});

export default connect(mapStateToProps)(UserDropdown);

