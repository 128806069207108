import React from "react";
import { connect } from "react-redux";
import { playerContacts } from "users/userSlice";
import { playerId } from "users/userSlice";
import { playerBirthYear } from "users/userSlice";
import { playerTeam,  playerClub } from "users/userSlice";
import { playerPosition, playerNumber } from "users/userSlice";
import { playerName, playerBirthDate } from "users/userSlice";
import { removePlayer } from "users/userSlice";

class PlayerInfo extends React.Component {

  handleRemovePlayer = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(removePlayer(id));
  }

  handleChangeName = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playerName(id, event.target.value));
  }

  handleChangeBirthDate = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playerBirthDate(id, event.target.value));
  }

  handleChangeTeam = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playerTeam(id, event.target.value));
  }

  handleChangeClub = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playerClub(id, event.target.value));
  }

  handleChangePosition = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playerPosition(id, event.target.value));
  }

  handleChangeNumber = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playerNumber(id, event.target.value));
  }

  handleChangeBirthYear = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playerBirthYear(id, event.target.value));
  }

  handleChangePlayerContacts = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playerContacts(id, [event.target.value]));
  }

  handleChangePlayerId = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(playerId(id, event.target.value));
  }

  render() {
    const { id, player, emailAddress, currentUser } = this.props;
    const { name, birthDate, club, team, position, number, birthYear, contacts } = player;
    const overwrite = !(currentUser && currentUser.roles && currentUser.roles.includes('ADMIN'));
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Player Information
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Name"
                name="name" 
                value={name} onChange={this.handleChangeName} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Birth Date
              </label>
              <input
                type="date"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="BirthDate"
                name="birthDate" 
                value={birthDate} onChange={this.handleChangeBirthDate} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Club
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="club"
                name="club" 
                value={club} onChange={this.handleChangeClub} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Team
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="team"
                name="team" 
                value={team} onChange={this.handleChangeTeam} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Position
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="position"
                name="position" 
                value={position} onChange={this.handleChangePosition} 
              />
            </div>
          </div>
          <div className="w-full lg:w-3/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Number
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="number"
                name="number" 
                value={number} onChange={this.handleChangeNumber} 
              />
            </div>
          </div>
          <div className="w-full lg:w-3/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Player ID
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="PlayerId"
                name="playerId" 
                disabled={overwrite}
                value={id} onChange={this.handleChangePlayerId} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Birth Year
              </label>
              <input
                type="number"
                min="1960" max="2060"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="BirthYear"
                name="birthYear" 
                value={birthYear} onChange={this.handleChangeBirthYear} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Contact
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="contacts"
                name="contacts" 
                defaultValue={emailAddress} 
                value={contacts} onChange={this.handleChangePlayerContacts} 
              />
            </div>
          </div>
          <div className="px-4 py-4 md:px-10 mx-auto w-full text-center">
            <button
                className="font-normal text-lightBlue-500 px-6"
                onClick={this.handleRemovePlayer}
            >
              <i className="fas fa-trash-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
              remove player
            </button>
         </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  emailAddress: state.users.emailAddress,
  players: state.users.players,
  error: state.users.error
});

export default connect(mapStateToProps)(PlayerInfo);
