import React from "react";
import { connect } from "react-redux";
import { changeMonth } from "developments/developmentSlice";
import { changePlayerId } from "developments/developmentSlice";
import { matchAdded } from "developments/developmentSlice";
import DevelopmentInfo from "components/Cards/DevelopmentInfo";
import DevelopmentTable from "components/Cards/DevelopmentTable";
import MatchInfo from "components/Cards/MatchInfo";
import { updateDevelopment } from "developments/developmentSlice";
import { developmentAdded } from "developments/developmentSlice";
import { changeContactEmail } from "developments/developmentSlice";

class Development extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      month: new Date(),
      contactEmail: '',
      playerId: ''
    }; 
  }
   
  handleChangeMonth = development => {
    development.preventDefault();
    this.setState({
      month: development.target.value
    });
    this.props.dispatch(changeMonth(development.target.value));
  }
  
  handleChangeContactEmail = development => {
    this.setState({
      contactEmail: development.target.value
    });
    this.props.dispatch(changeContactEmail(development.target.value));
  }
  
  handleChangePlayerId = development => {
    this.setState({
      playerId: development.target.value
    });
    this.props.dispatch(changePlayerId(development.target.value));
  }

  handleMatchAdded = e => {
    e.preventDefault();
    this.props.dispatch(matchAdded());
  }

  handleDevelopmentAdded = e => {
    e.preventDefault();
    const { month, contactEmail, playerId } = this.state;
    if (!playerId || !contactEmail || !month) {
      alert("Please choose a date / contact / playerId");
    } else {
      this.props.dispatch(developmentAdded((new Date(month)).getTime(), contactEmail, playerId));
    }
  }

  handleUpdateDevelopment = e => {
    e.preventDefault();
    const { id, playerId, playerName, contactEmail, birthYear, month, teamId, matches } = this.props;
    this.props.dispatch(updateDevelopment({
        id: id, playerId: playerId, playerName: playerName,  contactEmail: contactEmail, birthYear: birthYear, 
        month: (new Date(month)).getTime() + 86400 * 1000, teamId: teamId, matches: matches
      })
    );
  }

  render() {
    const { uError } = this.props;
    const { month, contactEmail, playerId } = this.state;
    const { matches } = this.props;
    const matchInput = !matches ? <div></div> : matches.map(cur => {
      return (<div key={cur.id}><MatchInfo id={cur.id} match={cur} /><hr className="mt-6 border-b-1 border-blueGray-300" /></div>);
    });
    return (
      <>
      <div className="flex flex-wrap">
        <form className="w-full lg:w-12/12 px-4" onSubmit={this.handleDevelopmentAdded}>
          <div className="relative bg-lightBlue-100 :pt-3 pb-4 pt-4">
            <div className="px-1 md:px-1 mx-auto w-full text-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                    Month
                  </label>
                  <input
                    type="month"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Time"
                    name="month"
                    value={month} onChange={this.handleChangeMonth} 
                    required
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                    Contact
                  </label> 
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Contact Email"
                    name="contactEmail" 
                    value={contactEmail} onChange={this.handleChangeContactEmail} 
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                    PlayerId
                  </label> 
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Player Id"
                    name="playerId" 
                    value={playerId} onChange={this.handleChangePlayerId} 
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4 py-6">
                <div className="relative w-full mb-3">
                  <button
                    className="bg-lightBlue-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit" name="submit"
                  >
                    new
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Development</h6>
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={this.handleMatchAdded}
                >
                  <i className="fas fa-user-plus text-white-400 mr-2 text-sm"></i>{" "}
                  Add Match
                </button>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={this.handleUpdateDevelopment}>
                <DevelopmentInfo />
                <hr className="mt-6 border-b-1 border-blueGray-300" />
                {matchInput}
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-2 py-5">
                    <div className="relative w-full mb-1 text-center">
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit" name="submit"
                      >
                        Update
                      </button>
                    </div>
                    <div className="text-red-500 text-center">{uError}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-4">
          <DevelopmentTable />
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  id: state.developments.id,
  playerId: state.developments.playerId,
  playerName: state.developments.playerName,
  contactEmail: state.developments.contactEmail,
  birthYear: state.developments.birthYear,
  month: state.developments.month,
  teamId: state.developments.teamId,
  matches: state.developments.matches,
  uError: state.developments.uError
});

export default connect(mapStateToProps)(Development);
