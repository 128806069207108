import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Planning from "views/admin/Planning.js";
import Profile from "views/admin/Profile.js";
import Venue from "views/admin/Venue.js";
import Team from "views/admin/Team.js";
import User from "views/admin/User.js";
import Event from "views/admin/Event.js";
import Engagement from "views/admin/Engagement.js";
import Development from "views/admin/Development.js";

export default function Admin() {
  return (
    <>
      {/* <Sidebar /> */}
      <div className="relative md:ml-0 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative bg-lightBlue-100 :pt-32 pb-32 pt-12">
          <div className="px-4 md:px-10 mx-auto w-full">
          </div>
        </div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/planning" exact component={Planning} />
            <Route path="/admin/profile" exact component={Profile} />
            <Route path="/admin/venue" exact component={Venue} />
            <Route path="/admin/team" exact component={Team} />
            <Route path="/admin/user" exact component={User} />
            <Route path="/admin/event" exact component={Event} />
            <Route path="/admin/engagement" exact component={Engagement} />
            <Route path="/admin/development" exact component={Development} />
            {/* <Route path="/admin/tables" exact component={Tables} /> */}
            <Redirect from="/admin" to="/admin/planning" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
