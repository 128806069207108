import React from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { forwardRef } from 'react';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

const tableIcons = {
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
};

class PlayerTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      selectedRow: null
    }; 
  }

  render() {
    const title = "Roster";
    const columns = [
      { title: 'name', field: 'name' },
      { title: 'club', field: 'club' },
      { title: 'team', field: 'team' },
      { title: 'number', field: 'number' },
      { title: 'birthYear', field: 'birthYear' },
      { title: 'playerId', field: 'id' },
      { title: 'contacts', field: 'contacts' }
    ];
    const { teamId, teamList, error } = this.props;
    let players = [];
    if (teamList) {
      for (let i=0;i<teamList.length;i++) {
        if (teamList[i].id === teamId) {
          players = teamList[i].players;
        }
      }
    }
    
    const data = players? players.map(player => {
      return {
        name: player.name,
        club: player.club,
        team: player.team,
        number: player.number,
        birthYear: player.birthYear,
        id: player.id,
        contacts: player.contacts
      }
    }) : [];
    
    const { selectedRow } = this.state;
    return (
      <div style={{ maxWidth: "100%", paddingTop: "12px" }}>
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        icons={tableIcons}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF"
          },
          rowStyle: 
            rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
          padding: 'dense',
          actionsColumnIndex: -1,
          pageSize: 10
        }}
      />
      <div className="text-red-500 text-center">{error}</div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  id: state.teams.id,
  teamList: state.teams.teamList,
  error: state.teams.error
});

export default connect(mapStateToProps)(PlayerTable);
