import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UserDropdown from "components/Dropdowns/UserDropdown.js";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      navbarOpen: false
    }; 
  }

  componentDidMount() {
  }

  setNavbarOpen() {
    this.setState({
      navbarOpen: !this.state.navbarOpen
    });
  }

  render() {
    const { navbarOpen }  = this.state;
    // console.log("admin " + navbarOpen);
    const { currentUser } = this.props;
    if (!currentUser || !currentUser.roles) {
      return (<div></div>);
    }
    var showPlanning = false;
    var showVenue = false;
    var showEvents = false;
    var showTeams = false;
    var showUsers = false;
    var showProfile = false;
    var showEngagements = false;
    var showDevelopments = false;
    if (currentUser) {
      if (currentUser.roles.includes('ROOT') || currentUser.roles.includes('ADMIN') || 
        currentUser.roles.includes('PARENT') || currentUser.roles.includes('MANAGER') || 
        currentUser.roles.includes('COACH') || currentUser.roles.includes('FILMING')) {
        showPlanning = true;
      }
      if (currentUser.roles.includes('ADMIN') || currentUser.roles.includes('FILMING')) {
        showVenue = true;
      }
      if (currentUser.roles.includes('ADMIN') || currentUser.roles.includes('MANAGER') || 
      currentUser.roles.includes('COACH') || currentUser.roles.includes('FILMING')) {
        showEvents = true;
      }
      if (currentUser.roles.includes('ADMIN') || currentUser.roles.includes('MANAGER') || 
        currentUser.roles.includes('COACH') ) {
        showTeams = true;
      }
      if (currentUser.roles.includes('ADMIN') || currentUser.roles.includes('MANAGER') || 
        currentUser.roles.includes('COACH') ) {
          showEngagements = true;
      }
      if (currentUser.roles.includes('ADMIN') || currentUser.roles.includes('FILMING') || 
        currentUser.roles.includes('COACH') ) {
          showDevelopments = true;
      }
      if (currentUser.roles.includes('ROOT') || currentUser.roles.includes('ADMIN')) {
        showUsers = true;
      }
      if (currentUser.roles.includes('ADMIN') || currentUser.roles.includes('MANAGER') || 
        currentUser.roles.includes('COACH') || currentUser.roles.includes('FILMING') ||
        currentUser.roles.includes('PARENT')) {
        showProfile = true;
      }
    }

    return (
      <>
        {/* Navbar */}
        <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
              <Link
                to="/"
                className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
              >
                Falinks Net
              </Link>
              {/* <li className="flex items-center">
                <IndexDropdown />
              </li> */}
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => this.setNavbarOpen()}
              >
                <i className="fas fa-bars"></i>
              </button>
            </div>
            <div
              className={
                "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
                (navbarOpen ? " block" : " hidden")
              }
              id="example-navbar-warning"
            >
              <ul className="flex flex-col lg:flex-row list-none mr-auto">
                {(showPlanning) && <li className="flex items-center">
                  <Link
                    to="/admin/planning"
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  >
                    <i className="text-blueGray-400 fas fa-calendar-check text-lg leading-lg mr-2" />{" "}
                    Planning
                  </Link>
                </li>}
                {(showVenue) && <li className="flex items-center">
                  <Link
                    to="/admin/venue"
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  >
                    <i className="text-blueGray-400 fas fa-globe text-lg leading-lg mr-2" />{" "}
                    Venue
                  </Link>
                </li>}
                {(showTeams) && <li className="flex items-center">
                  <Link
                    to="/admin/team"
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  >
                    <i className="text-blueGray-400 fas fa-users text-lg leading-lg mr-2" />{" "}
                    Teams
                  </Link>
                </li>}
                {(showUsers) && <li className="flex items-center">
                  <Link
                    to="/admin/user"
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  >
                    <i className="text-blueGray-400 fas fa-users text-lg leading-lg mr-2" />{" "}
                    Users
                  </Link>
                </li>}
                {(showEvents) && <li className="flex items-center">
                  <Link
                    to="/admin/event"
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  >
                    <i className="text-blueGray-400 fas fa-video text-lg leading-lg mr-2" />{" "}
                    Events
                  </Link>
                </li>}
                {(showEngagements) && <li className="flex items-center">
                  <Link
                    to="/admin/engagement"
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  >
                    <i className="text-blueGray-400 fas fa-video text-lg leading-lg mr-2" />{" "}
                    Engagements
                  </Link>
                </li>}
                {(showDevelopments) && <li className="flex items-center">
                  <Link
                    to="/admin/development"
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  >
                    <i className="text-blueGray-400 fas fa-video text-lg leading-lg mr-2" />{" "}
                    Developments
                  </Link>
                </li>}
                {(showProfile) && <li className="flex items-center">
                  <Link
                    to="/admin/profile"
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  >
                    <i className="text-blueGray-400 fas fa-address-card text-lg leading-lg mr-2" />{" "}
                    Profile
                  </Link>
                </li>}
              </ul>
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                  <li className="flex items-center">
                    <UserDropdown user={currentUser}/>
                  </li>
                </ul>
            </div>
          </div>
        </nav>
        {/* End Navbar */}
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser
});

export default connect(mapStateToProps)(AdminNavbar);
