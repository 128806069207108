import React from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import { forwardRef } from 'react';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { listEvents } from "events/eventSlice";
import { changeName } from "events/eventSlice";
import { changeEventState } from "events/eventSlice";
import { changeEventTime } from "events/eventSlice";
import { changeEventLocation } from "events/eventSlice";
import { changeUrl } from "events/eventSlice";
import { changeResult } from "events/eventSlice";
import { changeSummary } from "events/eventSlice";
import { removeEvent } from "events/eventSlice";
import { changeId } from "events/eventSlice";
import { changePlays } from "events/eventSlice";
import { changeAttendees } from "events/eventSlice";
import { changeEventType } from "events/eventSlice";
import { changeSchedules } from "events/eventSlice";
import { changeTeamStats } from "events/eventSlice";
import { changeTags } from "events/eventSlice";
import { changeParticipants } from "events/eventSlice";

const tableIcons = {
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
};

class EventTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      selectedRow: null
    }; 
  }

  componentDidMount() {
    const { currentUser } = this.props;
    if (currentUser) {
      if (currentUser.roles) {
        var date = new Date();
        if (currentUser.roles.includes('ADMIN')  || currentUser.roles.includes('FILMING')) {
          date.setMonth(date.getMonth() - 24);
        } else if (currentUser.roles.includes('MANAGER') || currentUser.roles.includes('COACH')) {
          date.setMonth(date.getMonth() - 2);
        }
        this.props.dispatch(listEvents({ startTime: date.getTime(), tags: currentUser.teams }));
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    if (currentUser && currentUser !== prevProps.currentUser) {
      if (currentUser.roles) {
        var date = new Date();
        if (currentUser.roles.includes('ADMIN')  || currentUser.roles.includes('FILMING')) {
          date.setMonth(date.getMonth() - 24);
        } else if (currentUser.roles.includes('MANAGER') || currentUser.roles.includes('COACH')) {
          date.setMonth(date.getMonth() - 2);
        }
        this.props.dispatch(listEvents({ startTime: date.getTime(), tags: currentUser.teams }));
      }
    }
  }

  setSelectedRow = row => {
    this.setState({
      selectedRow: row
    });
    const { eventList } = this.props;
    const event = eventList[row];
    this.props.dispatch(changeId(event.id));
    this.props.dispatch(changeEventType(event.type));
    this.props.dispatch(changeName(event.name));
    this.props.dispatch(changeEventState(event.eventState));
    this.props.dispatch(changeEventTime(event.eventTime));
    this.props.dispatch(changeEventLocation(event.eventLocation));
    this.props.dispatch(changeUrl(event.url));
    this.props.dispatch(changeResult(event.result));
    this.props.dispatch(changeSummary(event.summary));
    this.props.dispatch(changePlays(event.plays));
    this.props.dispatch(changeParticipants(event.participants));
    this.props.dispatch(changeSchedules(event.schedules));
    this.props.dispatch(changeTeamStats(event.teamStats));
    this.props.dispatch(changeTags(event.tags));
    this.props.dispatch(changeAttendees(event.attendees));
  }

  clickDelete = event => {
    this.props.dispatch(removeEvent(event))
  }

  render() {
    const title = "Events";
    const columns = [
      { title: 'id', field: 'id' },
      { title: 'time', field: 'time' },
      { title: 'location', field: 'location' },
      { title: 'eventState', field: 'eventState' },
      { title: 'name', field: 'name' },
      { title: 'eventTime', field: 'eventTime', hidden: true },
      { title: 'eventLocation', field: 'eventLocation', hidden: true },
      { title: 'tags', field: 'tags', hidden: true },
      { title: 'url', field: 'url' },
      { title: 'result', field: 'result' },
      { title: 'summary', field: 'summary' }
    ];
    const { eventList, error } = this.props;
    const data = eventList ? eventList.map(event => {
      return {
        id: event.id,
        time: (new Date(event.eventTime)).toLocaleString(),
        location: event.eventLocation.name + ' ' + event.eventLocation.id,
        eventState: event.eventState,
        name: event.name,
        eventTime: event.eventTime,
        eventLocation: event.eventLocation,
        tags: event.tags,
        url: event.url,
        result: event.result,
        summary: event.summary
      }
    }) : [];
    const tableActions = [
      {
        icon: () => <DeleteIcon />,
        tooltip: 'Delete Entry',
        onClick: (event, rowData) => this.clickDelete(rowData)
      }
    ];
    
    const { selectedRow } = this.state;
    return (
      <div style={{ maxWidth: "100%", paddingTop: "12px" }}>
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        // detailPanel={rowData => {
        //   return (
        //     <iframe
        //       width="100%"
        //       height="315"
        //       src={rowData.url}
        //       frameborder="0"
        //       allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        //       allowfullscreen
        //     />
        //   )
        // }}
        icons={tableIcons}
        actions={tableActions}
        onRowClick={((evt, selectedRow) => this.setSelectedRow(selectedRow.tableData.id))}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF"
          },
          rowStyle: 
            rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
          padding: 'dense',
          actionsColumnIndex: -1,
          pageSize: 10
        }}
      />
      <div className="text-red-500 text-center">{error}</div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  eventList: state.events.eventList,
  error: state.events.error
});

export default connect(mapStateToProps)(EventTable);
