import React from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import { forwardRef } from 'react';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { formatDate } from "utils/utils";
import { formatDateArray } from "utils/utils";
import { formatPaymentArray } from "utils/utils";
import { formatVolunteerArray } from "utils/utils";
import { formatNumArray } from "utils/utils";
import { changeId } from "developments/developmentSlice";
import { changePlayerId } from "developments/developmentSlice";
import { changePlayerName } from "developments/developmentSlice";
import { changeContactEmail } from "developments/developmentSlice";
import { changeBirthYear } from "developments/developmentSlice";
import { changeMatches } from "developments/developmentSlice";
import { listDevelopments } from "developments/developmentSlice";
import { changeMonth } from "developments/developmentSlice";
import { changeTeamId } from "developments/developmentSlice";
import { removeDevelopment } from "developments/developmentSlice";

const tableIcons = {
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
};

class DevelopmentTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      selectedRow: null
    }; 
  }

  componentDidMount() {
    const { currentUser } = this.props;
    if (currentUser) {
      if (currentUser.roles) {
        var date = new Date();
        if (currentUser.roles.includes('ADMIN')) {
          date.setMonth(date.getMonth() - 24);
          this.props.dispatch(listDevelopments({ startTime: date.getTime(), endTime: (new Date()).getTime() + 86400 * 1000 * 30 }));
        } else if (currentUser.roles.includes('COACH') || currentUser.roles.includes('FILMING')) {
          date.setMonth(date.getMonth() - 2);
          this.props.dispatch(listDevelopments({ startTime: date.getTime(), endTime: (new Date()).getTime() + 86400 * 1000 * 30 }));
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    if (currentUser && currentUser !== prevProps.currentUser) {
      if (currentUser.roles) {
        var date = new Date();
        if (currentUser.roles.includes('ADMIN')  || currentUser.roles.includes('FILMING')) {
          date.setMonth(date.getMonth() - 24);
          this.props.dispatch(listDevelopments({ startTime: date.getTime(), endTime: (new Date()).getTime() + 86400 * 1000 * 30 }));
        } else if (currentUser.roles.includes('COACH')) {
          date.setMonth(date.getMonth() - 2);
          this.props.dispatch(listDevelopments({ startTime: date.getTime(), endTime: (new Date()).getTime() + 86400 * 1000 * 30 }));
        }
      }
    }
  }

  setSelectedRow = row => {
    this.setState({
      selectedRow: row
    });
    const { developmentList } = this.props;
    const development = developmentList[row];
    this.props.dispatch(changeId(development.id));
    this.props.dispatch(changePlayerId(development.playerId));
    this.props.dispatch(changePlayerName(development.playerName));
    this.props.dispatch(changeContactEmail(development.contactEmail));
    this.props.dispatch(changeBirthYear(development.birthYear));
    this.props.dispatch(changeMonth(development.month));
    this.props.dispatch(changeTeamId(development.teamId));
    this.props.dispatch(changeMatches(development.matches));
  }

  clickDelete = event => {
    this.props.dispatch(removeDevelopment(event))
  }

  render() {
    const title = "Developments";
    const columns = [
      { title: 'id', field: 'id', hidden: true },
      { title: 'playerId', field: 'playerId' },
      { title: 'playerName', field: 'playerName' },
      { title: 'contactEmail', field: 'contactEmail' },
      { title: 'birthYear', field: 'birthYear' },
      { title: 'month', field: 'month' },
      { title: 'teamId', field: 'teamId', hidden: true },
      { title: 'matches', field: 'matches' }
    ];
    const { developmentList, error } = this.props;
    const data = developmentList ? developmentList.map(development => {
      return {
        id: development.id,
        playerId: development.playerId,
        playerName: development.playerName,
        contactEmail: development.contactEmail,
        birthYear: development.birthYear,
        month: formatDate(development.month + 86400 * 1000),
        teamId: development.teamId,
        matches: development.matches ? development.matches.length : 0
      }
    }) : [];
    const tableActions = [
      {
        icon: () => <DeleteIcon />,
        tooltip: 'Delete Entry',
        onClick: (event, rowData) => this.clickDelete(rowData)
      }
    ];
    
    const { selectedRow } = this.state;
    return (
      <div style={{ maxWidth: "100%", paddingTop: "12px" }}>
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        icons={tableIcons}
        actions={tableActions}
        onRowClick={((evt, selectedRow) => this.setSelectedRow(selectedRow.tableData.id))}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF"
          },
          rowStyle: 
            rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
          padding: 'dense',
          actionsColumnIndex: -1,
          pageSize: 10
        }}
      />
      <div className="text-red-500 text-center">{error}</div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  developmentList: state.developments.developmentList,
  error: state.developments.error
});

export default connect(mapStateToProps)(DevelopmentTable);
