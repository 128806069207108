import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const listVideos = createAsyncThunk("videos/listVideos", async (startDate, { rejectWithValue }) => {
  try {
    const res = await axios.get("/videos", {})
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

const initialState = {
  viewStart: null,
  videoList: [],
  loading: false,
  error: null
}

const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    startView(state, action) {
      state.viewStart = action.payload
    },
    uploadVideo(state, action) {
    }
  },
  extraReducers: {
    [listVideos.pending]: (state, action) => {
      state.loading = true;
    },
    [listVideos.fulfilled]: (state, action) => {
      state.loading = false;
      state.videoList = action.payload;
    },
    [listVideos.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
})

export const { startView, uploadVideo } = videosSlice.actions
export default videosSlice.reducer