import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";

class IndexNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      navbarOpen: false
    }; 
  }

  componentDidMount() {
    // const { currentUser } = this.props;
    // const cookies = new Cookies();
    // const token = cookies.get('fa-access-token');
    // if (token && (!currentUser || !currentUser.roles) ) {
    //   this.props.dispatch(getCurrentUser(token));
    // }
  }

  setNavbarOpen() {
    this.setState({
      navbarOpen: !this.state.navbarOpen
    });
  }

  render() {
    const { navbarOpen }  = this.state;
    const { currentUser } = this.props;
    var showAdmin = false;
    if (currentUser && currentUser.roles) {
      if (currentUser.roles.includes('ADMIN') || currentUser.roles.includes('PARENT') || 
        currentUser.roles.includes('MANAGER') || currentUser.roles.includes('COACH') || 
        currentUser.roles.includes('FILMING')) {
        showAdmin = true;
      }
    }
    return (
      <>
        <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
          <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
              <Link
                to="/"
                className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
              >
                Falinks Net
              </Link>
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => this.setNavbarOpen()}
              >
                <i className="fas fa-bars"></i>
              </button>
            </div>
            <div
              className={
                "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
                (navbarOpen ? " block" : " hidden")
              }
              id="example-navbar-warning"
            >
              <ul className="flex flex-col lg:flex-row list-none mr-auto">
                <li className="flex items-center">
                  <Link
                    to="/rules"
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  >
                    <i className="text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2" />{" "}
                    Rules
                  </Link>
                </li>
                {/* <li className="flex items-center">
                  <Link
                    to="/admin/event"
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  >
                    <i className="text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2" />{" "}
                    Planning
                  </Link>
                </li> */}
                {(showAdmin) && <li className="flex items-center">
                  <Link
                    to="/admin"
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  >
                    <i className="text-blueGray-400 fas fa-clipboard-list text-lg leading-lg mr-2" />{" "}
                    Tasks
                  </Link>
                </li>}
                <li className="flex items-center">
                  <IndexDropdown />
                </li>
              </ul>
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                <li className="flex items-center">
                  <UserDropdown user={currentUser}/>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser
});

export default connect(mapStateToProps)(IndexNavbar);
