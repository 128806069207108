import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { resetAuthenticated, resetError } from "users/userSlice";
import { forgotPassword } from "users/userSlice";
import { userLogin } from "users/userSlice";
import { changeEmail, changePassword } from "users/userSlice";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(resetError());
  }

  handleChangeEmail = event => {
    event.preventDefault();
    this.props.dispatch(changeEmail(event.target.value));
  }

  handleChangePassword = event => {
    event.preventDefault();
    this.props.dispatch(changePassword(event.target.value));
  }

  handleForgotPassword = event => {
    event.preventDefault();
    const { emailAddress } = this.props;
    if (String(emailAddress).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      this.props.dispatch(forgotPassword(emailAddress));
      alert("Please check your email to reset your password");
    } else {
      alert("Please fillin the email address you registered");
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    const { emailAddress, password } = this.props;
    this.props.dispatch(userLogin({
      userName: emailAddress, 
      password: password, 
      emailAddress: emailAddress
    }));
  }

  render() {
    const { emailAddress, password, isAuthenticated, error } = this.props;
    if (isAuthenticated) {
      this.props.dispatch(resetAuthenticated());
      this.props.dispatch(resetError());
      return <Redirect to={`/admin`} />;
    }
    return (
      <>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                {/* <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Sign in with
                    </h6>
                  </div>
                  <div className="btn-wrapper text-center">
                    <button
                      className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                      type="button"
                    >
                      <img
                        alt="..."
                        className="w-5 mr-1"
                        src={require("assets/img/google.svg").default}
                      />
                      Google
                    </button>
                  </div>
                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div> */}
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  {/* <div className="text-blueGray-400 text-center mb-3 font-bold">
                    <small>Or sign in with credentials</small>
                  </div> */}
                  <form onSubmit={this.handleSubmit}>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        name="email"
                        value={emailAddress} onChange={this.handleChangeEmail} 
                        required
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                        name="password"
                        value={password} onChange={this.handleChangePassword} 
                        required
                      />
                    </div>
                    <div>
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          id="customCheckLogin"
                          type="checkbox"
                          className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        />
                        <span className="ml-2 text-sm font-semibold text-blueGray-600">
                          Remember me
                        </span>
                      </label>
                    </div>
                    <div className="text-red-500">{error}</div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit" name="submit"
                      >
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="flex flex-wrap mt-6 relative">
                <div className="w-1/2">
                  <a
                    href="#pablo"
                    onClick={this.handleForgotPassword}
                    className="text-blueGray-200"
                  >
                    <small>Forgot password?</small>
                  </a>
                </div>
                <div className="w-1/2 text-right">
                  <Link to="/auth/register" className="text-blueGray-200">
                    <small>Create new account</small>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  emailAddress: state.users.emailAddress,
  password: state.users.password,
  isAuthenticated: state.users.isAuthenticated,
  error: state.users.error
});

export default connect(mapStateToProps)(Login);
