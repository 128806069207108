import React from "react";
import { connect } from "react-redux";
import { playActionRemove } from "events/eventSlice";
import { playActionShirtColor } from "events/eventSlice";
import { playActionShirtNumber } from "events/eventSlice";
import { playActionCode } from "events/eventSlice";

class ActionInfo extends React.Component {

  handleRemoveActions = event => {
    event.preventDefault();
    const { id, acid } = this.props;
    this.props.dispatch(playActionRemove(id, acid));
  }

  handleChangeShirtColor = event => {
    event.preventDefault();
    const { id, acid } = this.props;
    this.props.dispatch(playActionShirtColor(id, acid, event.target.value));
  }

  handleChangeShirtNumber = event => {
    event.preventDefault();
    const { id, acid } = this.props;
    this.props.dispatch(playActionShirtNumber(id, acid, event.target.value));
  }

  handleChangeCode = event => {
    event.preventDefault();
    const { id, acid } = this.props;
    this.props.dispatch(playActionCode(id, acid, event.target.value));
  }


  render() {
    const { actionCode } = this.props;
    const { shirtColor, shirtNumber, code } = actionCode;
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Action Codes
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Shirt Color
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="ShirtColor"
                name="shirtColor" 
                value={shirtColor} onChange={this.handleChangeShirtColor} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Shirt Number
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="ShirtNumber"
                name="shirtNumber" 
                value={shirtNumber} onChange={this.handleChangeShirtNumber} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Code
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Code"
                name="code" 
                value={code} onChange={this.handleChangeCode} 
              />
            </div>
          </div>
          <div className="px-4 py-4 md:px-10 mx-auto w-full text-center">
            <button
                className="font-normal text-lightBlue-500 px-6"
                onClick={this.handleRemoveActions}
            >
              <i className="fas fa-trash-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
              remove actions
            </button>
         </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  error: state.events.error
});

export default connect(mapStateToProps)(ActionInfo);
