import React from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import { forwardRef } from 'react';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { changeEmail } from "users/userSlice";
import { listUsers } from "users/userSlice";
import { deleteUser } from "users/userSlice";
import { changePhoneNumber } from "users/userSlice";
import { changePlayers } from "users/userSlice";

const tableIcons = {
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
};

class UserTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      selectedRow: null
    }; 
  }

  componentDidMount() {
    this.props.dispatch(listUsers());
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    if (currentUser !== prevProps.currentUser) {
      this.props.dispatch(listUsers());
    }
  }

  setSelectedRow = row => {
    this.setState({
      selectedRow: row
    });
    const { userList } = this.props;
    const user = userList[row];
    this.props.dispatch(changeEmail(user.emailAddress));
    this.props.dispatch(changePhoneNumber(user.phoneNumber));
    this.props.dispatch(changePlayers(user.players));
  }

  clickDelete = user => {
    this.props.dispatch(deleteUser(user.emailAddress))
  }

  render() {
    const title = "Users";
    const columns = [
      { title: 'id', field: 'id' },
      { title: 'fullName', field: 'fullName' },
      { title: 'emailAddress', field: 'emailAddress' },
      { title: 'roles', field: 'roles', render: rowData => rowData.roles ? rowData.roles.map(role => <div>{role}</div>) : '' },
      { title: 'players', field: 'players', render: rowData => rowData.players ? rowData.players.map(player => <div>{player.name + ' ' + player.club + ' ' + player.team}</div>) : '' }
    ];
    const { userList, error } = this.props;
    const data = userList.map(user => {
      return {
        id: user.id,
        fullName: user.fullName,
        emailAddress: user.emailAddress,
        roles: user.roles,
        players: user.players
      }
    });
    const tableActions = [
      {
        icon: () => <DeleteIcon />,
        tooltip: 'Delete Entry',
        onClick: (event, rowData) => this.clickDelete(rowData)
      }
    ];
    
    const { selectedRow } = this.state;
    return (
      <div style={{ maxWidth: "100%", paddingTop: "12px" }}>
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        icons={tableIcons}
        actions={tableActions}
        onRowClick={((evt, selectedRow) => this.setSelectedRow(selectedRow.tableData.id))}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF"
          },
          rowStyle: 
            rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
          padding: 'dense',
          actionsColumnIndex: -1,
          pageSize: 10
        }}
      />
      <div className="text-red-500 text-center">{error}</div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  userList: state.users.userList,
  error: state.users.error
});

export default connect(mapStateToProps)(UserTable);
