import { changeUserName } from "engagements/engagementSlice";
import { changeSignup } from "engagements/engagementSlice";
import { changeParticipation } from "engagements/engagementSlice";
import React from "react";
import { connect } from "react-redux";
import DatePicker from "react-multi-date-picker"
import { changeUserEmail } from "engagements/engagementSlice";
import { changeUserPhone } from "engagements/engagementSlice";
import { changeNumOfPlayers } from "engagements/engagementSlice";

class EngagementInfo extends React.Component {

  handleChangeUserEmail = engagement => {
    engagement.preventDefault();
    this.props.dispatch(changeUserEmail(engagement.target.value));
  }

  handleChangeUserName = engagement => {
    engagement.preventDefault();
    this.props.dispatch(changeUserName(engagement.target.value));
  }

  handleChangeUserPhone = engagement => {
    engagement.preventDefault();
    this.props.dispatch(changeUserPhone(engagement.target.value));
  }

  handleChangeNumOfPlayers = engagement => {
    engagement.preventDefault();
    this.props.dispatch(changeNumOfPlayers((engagement.target.value).split(',')));
  }

  handleChangeSignups = engagement => {
    const days = engagement.map(date => (new Date(date)).getTime());
    this.props.dispatch(changeSignup(days));
  }

  handleChangeParticipation = engagement => {
    const days = engagement.map(date => (new Date(date)).getTime());
    this.props.dispatch(changeParticipation(days));
  }

  render() {
    const { userName, userEmail, userPhone, numOfPlayers, datesOfSignup, datesOfParticipation } = this.props;
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Engagement Information
        </h6>
        <div className="flex flex-wrap">
           <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                User Name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="UserName"
                name="userName" 
                value={userName} onChange={this.handleChangeUserName} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                User Email
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="UserEmail"
                name="userEmail" 
                value={userEmail} onChange={this.handleChangeUserEmail} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                User Phone
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="UserPhone"
                name="userPhone" 
                value={userPhone} onChange={this.handleChangeUserPhone} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Sign Ups
              </label>
              <DatePicker 
                inputClass="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                multiple
                format="YYYY-MM-DD"
                sort
                value={datesOfSignup} 
                onChange={this.handleChangeSignups}
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Participations
              </label>
              <DatePicker 
                inputClass="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                multiple
                format="YYYY-MM-DD"
                value={datesOfParticipation} 
                onChange={this.handleChangeParticipation}
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Num Of Players
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="NumOfPlayers"
                name="numOfPlayers" 
                value={numOfPlayers} onChange={this.handleChangeNumOfPlayers} 
              />
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  userName: state.engagements.userName,
  userEmail: state.engagements.userEmail,
  userPhone: state.engagements.userPhone,
  numOfPlayers: state.engagements.numOfPlayers,
  datesOfSignup: state.engagements.datesOfSignup,
  datesOfParticipation: state.engagements.datesOfParticipation,
  error: state.engagements.error
});

export default connect(mapStateToProps)(EngagementInfo);
