import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { customAlphabet } from 'nanoid'
import axios from 'axios';
import { stringify } from 'qs'
import Cookies from 'universal-cookie';
import Geohash from 'ngeohash';
import { formatDate } from '../utils/utils';

const nanoid = customAlphabet('1234567890', 9)

export const listEvents = createAsyncThunk("events/listEvents", async (event, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const listAxios = axios.create({
      paramsSerializer: {
        serialize: stringify,
        indices: false
      }
    });
    const res = await listAxios.get("/api/list_events", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        startTime: event.startTime,
        tags: event.tags
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const showEvents = createAsyncThunk("events/showEvents", async (tag, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.get("/api/show_events", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        tag: tag
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const getEvent = createAsyncThunk("events/getEvent", async (event, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.get("/api/get_event", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        time: formatDate(new Date(event.eventTime)),
        hash: Geohash.encode(event.eventLocation.latitude, event.eventLocation.longitude, 4),
        id: event.id
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const scheduleEvent = createAsyncThunk("events/scheduleEvent", async (event, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/schedule_event", event, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateEvent = createAsyncThunk("events/updateEvent", async (event, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/update_event", event, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const addAllPlayers = createAsyncThunk("events/addAllPlayers", async (event, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const listAxios = axios.create({
      paramsSerializer: {
        serialize: stringify,
        indices: false
      }
    });
    const res = await listAxios.get("/api/add_all_players", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        time: formatDate(new Date(event.eventTime)),
        hash: Geohash.encode(event.eventLocation.latitude, event.eventLocation.longitude, 4),
        id: event.id,
        teams: event.tags
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const removeEvent = createAsyncThunk("events/removeEvent", async (event, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    var res;
    if (event.tags && event.tags.length > 0) {
      for (let i = 0; i < event.tags.length; i++) {
        var eventTag = event.tags[i];
        res = await axios.delete("/api/remove_event/" + formatDate(new Date(event.eventTime))+ "/" 
          + Geohash.encode(event.eventLocation.latitude, event.eventLocation.longitude, 4) + "/" 
          + event.id + "/" + eventTag, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        if (res.status !== 200) {
          return rejectWithValue("Wrong status " + res.status);
        }
      }
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const addAttendee = createAsyncThunk("events/addAttendee", async (event, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/add_attendee/" + formatDate(new Date(event.eventTime))+ "/" 
    + Geohash.encode(event.eventLocation.latitude, event.eventLocation.longitude, 4) + "/" 
    + event.id, event.attendee, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateAttendee = createAsyncThunk("events/updateAttendee", async (event, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/update_attendee/" + formatDate(new Date(event.eventTime))+ "/" 
    + Geohash.encode(event.eventLocation.latitude, event.eventLocation.longitude, 4) + "/" 
    + event.id + "/" + event.index, event.attendee, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const removeAttendee = createAsyncThunk("events/removeAttendee", async (event, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    var res = await axios.delete("/api/remove_attendee/" + formatDate(new Date(event.eventTime))+ "/" 
      + Geohash.encode(event.eventLocation.latitude, event.eventLocation.longitude, 4) + "/" 
      + event.id + "/" + event.index, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});


const initialState = {
  type: '',
  name: '',
  id: '',
  eventState: '',
  eventTime: 0,
  eventLocation: {},
  url: '',
  result: '',
  summary: '',
  tags: [],
  participants: [],
  teamPlayers: [],
  plays: [],
  schedules: [],
  teamStats: [],
  attendees: [],
  eventList: [],
  events: [],
  gamefilter: '',
  filteredGames: [],
  curView: 0,
  viewTotal: 0,
  viewType: 'Highlights',
  playerSelected: '',
  loading: false,
  uError: null,
  error: null
}

const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    eventAdded: {
      reducer(state, action) {
        state.eventList.push(action.payload);
      },
      prepare(time, location, tag) {
        return {
          payload: {
            id: nanoid(),
            attendees: [],
            eventLocation: location,
            location: location.name,
            eventTime: time,
            tags: [tag],
            version: 0
          }
        }
      }
    },
    eventAttendees: {
      reducer(state, action) {
        return {
          ...state,
          eventList: state.eventList.map(event => {
            if (event.id === action.payload.id) {
              return { 
                ...event, 
                attendees: action.payload.attendees
              };
            } else {
              return event;
            }
          })
        };
      },
      prepare(id, attendees) {
        return {
          payload: {
            id: id,
            attendees: attendees
          }
        }
      }
    },
    changeName(state, action) {
      state.name = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeId(state, action) {
      state.id = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeEventType(state, action) {
      state.type = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeEventState(state, action) {
      state.eventState = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeEventTime(state, action) {
      state.eventTime = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeEventLocation(state, action) {
      state.eventLocation = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeUrl(state, action) {
      state.url = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeResult(state, action) {
      state.result = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeSummary(state, action) {
      state.summary = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeTags(state, action) {
      state.tags = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeParticipants(state, action) {
      state.participants = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeTeamPlayers(state, action) {
      state.teamPlayers = action.payload;
      state.error = null;
      state.uError = null;
    },
    changePlays(state, action) {
      state.plays = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeSchedules(state, action) {
      state.schedules = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeTeamStats(state, action) {
      state.teamStats = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeAttendees(state, action) {
      state.attendees = action.payload;
      state.error = null;
      state.uError = null;
    },
    participantAdded: {
      reducer(state, action) {
        if (!state.participants) {
          state.participants = [action.payload];
        } else {
          state.participants.push(action.payload);
        }
      },
      prepare() {
        return {
          payload: {
            id: nanoid(),
            playerId: '',
            playerName: '',
            teamId: '',
            teamName: '',
            shirtColor: '',
            shirtNumber: 0,
            guest: false
          }
        }
      }
    },
    participantPlayerId: {
      reducer(state, action) {
        return {
          ...state,
          participants: state.participants.map(participant => {
            if (participant.id === action.payload.id) {
              return { 
                ...participant, 
                playerId: action.payload.playerId
              };
            } else {
              return participant;
            }
          })
        };
      },
      prepare(id, playerId) {
        return {
          payload: {
            id: id,
            playerId: playerId
          }
        }
      }
    },
    participantPlayerName: {
      reducer(state, action) {
        return {
          ...state,
          participants: state.participants.map(participant => {
            if (participant.id === action.payload.id) {
              return { 
                ...participant, 
                playerName: action.payload.playerName
              };
            } else {
              return participant;
            }
          })
        };
      },
      prepare(id, playerName) {
        return {
          payload: {
            id: id,
            playerName: playerName
          }
        }
      }
    },
    participantTeamId: {
      reducer(state, action) {
        return {
          ...state,
          participants: state.participants.map(participant => {
            if (participant.id === action.payload.id) {
              return { 
                ...participant, 
                teamId: action.payload.teamId
              };
            } else {
              return participant;
            }
          })
        };
      },
      prepare(id, teamId) {
        return {
          payload: {
            id: id,
            teamId: teamId
          }
        }
      }
    },
    participantTeamName: {
      reducer(state, action) {
        return {
          ...state,
          participants: state.participants.map(participant => {
            if (participant.id === action.payload.id) {
              return { 
                ...participant, 
                teamName: action.payload.teamName
              };
            } else {
              return participant;
            }
          })
        };
      },
      prepare(id, teamName) {
        return {
          payload: {
            id: id,
            teamName: teamName
          }
        }
      }
    },
    participantShirtColor: {
      reducer(state, action) {
        return {
          ...state,
          participants: state.participants.map(participant => {
            if (participant.id === action.payload.id) {
              return { 
                ...participant, 
                shirtColor: action.payload.shirtColor
              };
            } else {
              return participant;
            }
          })
        };
      },
      prepare(id, shirtColor) {
        return {
          payload: {
            id: id,
            shirtColor: shirtColor
          }
        }
      }
    },
    participantShirtNumber: {
      reducer(state, action) {
        return {
          ...state,
          participants: state.participants.map(participant => {
            if (participant.id === action.payload.id) {
              return { 
                ...participant, 
                shirtNumber: action.payload.shirtNumber
              };
            } else {
              return participant;
            }
          })
        };
      },
      prepare(id, shirtNumber) {
        return {
          payload: {
            id: id,
            shirtNumber: shirtNumber
          }
        }
      }
    },
    participantIsGuest: {
      reducer(state, action) {
        return {
          ...state,
          participants: state.participants.map(participant => {
            if (participant.id === action.payload.id) {
              return { 
                ...participant, 
                guest: action.payload.guest
              };
            } else {
              return participant;
            }
          })
        };
      },
      prepare(id, guest) {
        return {
          payload: {
            id: id,
            guest: guest
          }
        }
      }
    },
    participantGroupName: {
      reducer(state, action) {
        return {
          ...state,
          participants: state.participants.map(participant => {
            if (participant.id === action.payload.id) {
              return { 
                ...participant, 
                groupName: action.payload.groupName
              };
            } else {
              return participant;
            }
          })
        };
      },
      prepare(id, groupName) {
        return {
          payload: {
            id: id,
            groupName: groupName
          }
        }
      }
    },
    removeParticipant(state, action) {
      state.participants = state.participants.filter((current) => current.id !== action.payload);
    },
    playAdded: {
      reducer(state, action) {
        if (!state.plays) {
          state.plays = [action.payload];
        } else {
          state.plays.push(action.payload);
        }
      },
      prepare() {
        return {
          payload: {
            id: nanoid(),
            name: '',
            playType: 'OTHER',
            source: '',
            link: '',
            players: [],
            startOffset: 0,
            endOffset: 0,
            call: '',
            suggestion: '',
            rating: ''
          }
        }
      }
    },
    playName: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                name: action.payload.name
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, name) {
        return {
          payload: {
            id: id,
            name: name
          }
        }
      }
    },
    playLink: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                link: action.payload.link
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, link) {
        return {
          payload: {
            id: id,
            link: link
          }
        }
      }
    },
    playPlayers: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                players: action.payload.players
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, players) {
        return {
          payload: {
            id: id,
            players: players
          }
        }
      }
    },
    playSuggestion: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                suggestion: action.payload.suggestion
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, suggestion) {
        return {
          payload: {
            id: id,
            suggestion: suggestion
          }
        }
      }
    },
    playCall: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                call: action.payload.call
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, call) {
        return {
          payload: {
            id: id,
            call: call
          }
        }
      }
    },
    playSource: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                source: action.payload.source
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, source) {
        return {
          payload: {
            id: id,
            source: source
          }
        }
      }
    },
    playStart: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                startOffset: action.payload.startOffset
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, startOffset) {
        return {
          payload: {
            id: id,
            startOffset: startOffset
          }
        }
      }
    },
    playEnd: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                endOffset: action.payload.endOffset
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, endOffset) {
        return {
          payload: {
            id: id,
            endOffset: endOffset
          }
        }
      }
    },
    playChangeType: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                playType: action.payload.playType
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, playType) {
        return {
          payload: {
            id: id,
            playType: playType
          }
        }
      }
    },
    playRating: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                rating: action.payload.rating
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, rating) {
        return {
          payload: {
            id: id,
            rating: rating
          }
        }
      }
    },
    playActionAdded: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                actionCodes: !play.actionCodes ? [action.payload.action] : play.actionCodes.concat(action.payload.action)
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id) {
        return {
          payload: {
            id: id,
            action: {
              acid: nanoid(),
              shirtColor: '',
              shirtNumber: 0,
              code: 0
            }
          }
        }
      }
    },
    playActionShirtColor: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                actionCodes: play.actionCodes.map(cur => {
                  if (cur.acid === action.payload.acid) {
                    return {
                      ...cur,
                      shirtColor: action.payload.shirtColor
                    }
                  } else {
                    return cur;
                  }
                })
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, acid, shirtColor) {
        return {
          payload: {
            id: id,
            acid: acid,
            shirtColor: shirtColor
          }
        }
      }
    },    
    playActionShirtNumber: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                actionCodes: play.actionCodes.map(cur => {
                  if (cur.acid === action.payload.acid) {
                    return {
                      ...cur,
                      shirtNumber: action.payload.shirtNumber
                    }
                  } else {
                    return cur;
                  }
                })
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, acid, shirtNumber) {
        return {
          payload: {
            id: id,
            acid: acid,
            shirtNumber: shirtNumber
          }
        }
      }
    },    
    playActionCode: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                actionCodes: play.actionCodes.map(cur => {
                  if (cur.acid === action.payload.acid) {
                    return {
                      ...cur,
                      code: action.payload.code
                    }
                  } else {
                    return cur;
                  }
                })
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, acid, code) {
        return {
          payload: {
            id: id,
            acid: acid,
            code: code
          }
        }
      }
    },    
    playActionRemove: {
      reducer(state, action) {
        return {
          ...state,
          plays: state.plays.map(play => {
            if (play.id === action.payload.id) {
              return { 
                ...play, 
                actionCodes: play.actionCodes.filter((current) => current.acid !== action.payload.acid)
              };
            } else {
              return play;
            }
          })
        };
      },
      prepare(id, acid) {
        return {
          payload: {
            id: id,
            acid: acid
          }
        }
      }
    },    
    removePlay(state, action) {
      state.plays = state.plays.filter((current) => current.id !== action.payload);
    },
    scheduleAdded: {
      reducer(state, action) {
        if (!state.schedules) {
          state.schedules = [action.payload];
        } else {
          state.schedules.push(action.payload);
        }
      },
      prepare() {
        return {
          payload: {
            id: nanoid(),
            name: '',
            time: 0,
            location: '',
            teams: [],
            theme: ''
          }
        }
      }
    },    
    scheduleName: {
      reducer(state, action) {
        return {
          ...state,
          schedules: state.schedules.map(schedule => {
            if (schedule.id === action.payload.id) {
              return { 
                ...schedule, 
                name: action.payload.name
              };
            } else {
              return schedule;
            }
          })
        };
      },
      prepare(id, name) {
        return {
          payload: {
            id: id,
            name: name
          }
        }
      }
    },   
    scheduleTime: {
      reducer(state, action) {
        return {
          ...state,
          schedules: state.schedules.map(schedule => {
            if (schedule.id === action.payload.id) {
              return { 
                ...schedule, 
                time: action.payload.time
              };
            } else {
              return schedule;
            }
          })
        };
      },
      prepare(id, time) {
        return {
          payload: {
            id: id,
            time: time
          }
        }
      }
    },   
    scheduleLocation: {
      reducer(state, action) {
        return {
          ...state,
          schedules: state.schedules.map(schedule => {
            if (schedule.id === action.payload.id) {
              return { 
                ...schedule, 
                location: action.payload.location
              };
            } else {
              return schedule;
            }
          })
        };
      },
      prepare(id, location) {
        return {
          payload: {
            id: id,
            location: location
          }
        }
      }
    },   
    scheduleTeams: {
      reducer(state, action) {
        return {
          ...state,
          schedules: state.schedules.map(schedule => {
            if (schedule.id === action.payload.id) {
              return { 
                ...schedule, 
                teams: action.payload.teams
              };
            } else {
              return schedule;
            }
          })
        };
      },
      prepare(id, teams) {
        return {
          payload: {
            id: id,
            teams: teams
          }
        }
      }
    }, 
    scheduleTheme: {
      reducer(state, action) {
        return {
          ...state,
          schedules: state.schedules.map(schedule => {
            if (schedule.id === action.payload.id) {
              return { 
                ...schedule, 
                theme: action.payload.theme
              };
            } else {
              return schedule;
            }
          })
        };
      },
      prepare(id, theme) {
        return {
          payload: {
            id: id,
            theme: theme
          }
        }
      }
    }, 
    removeSchedule(state, action) {
      state.schedules = state.schedules.filter((current) => current.id !== action.payload);
    },     
    statsAdded: {
      reducer(state, action) {
        if (!state.teamStats) {
          state.teamStats = [action.payload];
        } else {
          state.teamStats.push(action.payload);
        }
      },
      prepare() {
        return {
          payload: {
            id: nanoid(),
            teamId: '',
            teamName: '',
            matchesPlayed: 0,
            wins: 0,
            losses: 0,
            draws: 0,
            goalsFor: 0,
            goalsAgainst: 0,
            yellowCards: 0,
            redCards: 0,
            points: 0
          }
        }
      }
    },   
    statsTeamId: {
      reducer(state, action) {
        return {
          ...state,
          teamStats: state.teamStats.map(stats => {
            if (stats.id === action.payload.id) {
              return { 
                ...stats, 
                teamId: action.payload.teamId
              };
            } else {
              return stats;
            }
          })
        };
      },
      prepare(id, teamId) {
        return {
          payload: {
            id: id,
            teamId: teamId
          }
        }
      }
    },  
    statsTeamName: {
      reducer(state, action) {
        return {
          ...state,
          teamStats: state.teamStats.map(stats => {
            if (stats.id === action.payload.id) {
              return { 
                ...stats, 
                teamName: action.payload.teamName
              };
            } else {
              return stats;
            }
          })
        };
      },
      prepare(id, teamName) {
        return {
          payload: {
            id: id,
            teamName: teamName
          }
        }
      }
    },  
    statsMatchesPlayed: {
      reducer(state, action) {
        return {
          ...state,
          teamStats: state.teamStats.map(stats => {
            if (stats.id === action.payload.id) {
              return { 
                ...stats, 
                matchesPlayed: action.payload.matchesPlayed
              };
            } else {
              return stats;
            }
          })
        };
      },
      prepare(id, matchesPlayed) {
        return {
          payload: {
            id: id,
            matchesPlayed: matchesPlayed
          }
        }
      }
    },  
    statsWins: {
      reducer(state, action) {
        return {
          ...state,
          teamStats: state.teamStats.map(stats => {
            if (stats.id === action.payload.id) {
              return { 
                ...stats, 
                numOfWins: action.payload.numOfWins
              };
            } else {
              return stats;
            }
          })
        };
      },
      prepare(id, numOfWins) {
        return {
          payload: {
            id: id,
            numOfWins: numOfWins
          }
        }
      }
    },  
    statsLosses: {
      reducer(state, action) {
        return {
          ...state,
          teamStats: state.teamStats.map(stats => {
            if (stats.id === action.payload.id) {
              return { 
                ...stats, 
                numOfLosses: action.payload.numOfLosses
              };
            } else {
              return stats;
            }
          })
        };
      },
      prepare(id, numOfLosses) {
        return {
          payload: {
            id: id,
            numOfLosses: numOfLosses
          }
        }
      }
    },  
    statsDraws: {
      reducer(state, action) {
        return {
          ...state,
          teamStats: state.teamStats.map(stats => {
            if (stats.id === action.payload.id) {
              return { 
                ...stats, 
                numOfDraws: action.payload.numOfDraws
              };
            } else {
              return stats;
            }
          })
        };
      },
      prepare(id, numOfDraws) {
        return {
          payload: {
            id: id,
            numOfDraws: numOfDraws
          }
        }
      }
    },  
    statsGoalsFor: {
      reducer(state, action) {
        return {
          ...state,
          teamStats: state.teamStats.map(stats => {
            if (stats.id === action.payload.id) {
              return { 
                ...stats, 
                goalsFor: action.payload.goalsFor
              };
            } else {
              return stats;
            }
          })
        };
      },
      prepare(id, goalsFor) {
        return {
          payload: {
            id: id,
            goalsFor: goalsFor
          }
        }
      }
    },  
    statsGoalsAgainst: {
      reducer(state, action) {
        return {
          ...state,
          teamStats: state.teamStats.map(stats => {
            if (stats.id === action.payload.id) {
              return { 
                ...stats, 
                goalsAgainst: action.payload.goalsAgainst
              };
            } else {
              return stats;
            }
          })
        };
      },
      prepare(id, goalsAgainst) {
        return {
          payload: {
            id: id,
            goalsAgainst: goalsAgainst
          }
        }
      }
    },  
    statsYellowCards: {
      reducer(state, action) {
        return {
          ...state,
          teamStats: state.teamStats.map(stats => {
            if (stats.id === action.payload.id) {
              return { 
                ...stats, 
                numOfYellowCards: action.payload.numOfYellowCards
              };
            } else {
              return stats;
            }
          })
        };
      },
      prepare(id, numOfYellowCards) {
        return {
          payload: {
            id: id,
            numOfYellowCards: numOfYellowCards
          }
        }
      }
    },  
    statsRedCards: {
      reducer(state, action) {
        return {
          ...state,
          teamStats: state.teamStats.map(stats => {
            if (stats.id === action.payload.id) {
              return { 
                ...stats, 
                numOfRedCards: action.payload.numOfRedCards
              };
            } else {
              return stats;
            }
          })
        };
      },
      prepare(id, numOfRedCards) {
        return {
          payload: {
            id: id,
            numOfRedCards: numOfRedCards
          }
        }
      }
    },  
    statsPoints: {
      reducer(state, action) {
        return {
          ...state,
          teamStats: state.teamStats.map(stats => {
            if (stats.id === action.payload.id) {
              return { 
                ...stats, 
                points: action.payload.points
              };
            } else {
              return stats;
            }
          })
        };
      },
      prepare(id, points) {
        return {
          payload: {
            id: id,
            points: points
          }
        }
      }
    },  
    removeStats(state, action) {
      state.teamStats = state.teamStats.filter((current) => current.id !== action.payload);
    },   
    changeCurView(state, action) {
      state.curView = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeGameFilter(state, action) {
      state.gamefilter = action.payload;
      state.filteredGames = action.payload ? state.events.filter((current) => formatDate(new Date(current.eventTime)) === action.payload) : state.events;
      state.viewTotal = state.filteredGames.length;
      state.error = null;
      state.uError = null;
    },
    changeViewType(state, action) {
      state.viewType = action.payload;
      state.error = null;
      state.uError = null;
    },
    changePlayerSelected(state, action) {
      state.playerSelected = action.payload;
      state.error = null;
      state.uError = null;
    }
  },
  extraReducers: {
    [listEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [listEvents.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventList = action.payload;
    },
    [listEvents.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [showEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [showEvents.fulfilled]: (state, action) => {
      state.loading = false;
      state.events = action.payload;
      state.filteredGames = action.payload;
      state.viewTotal = action.payload.length;
    },
    [showEvents.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getEvent.pending]: (state, action) => {
      state.loading = true;
    },
    [getEvent.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventList = state.eventList.map(event => {
        if (event.id === action.payload.id) {
          return { 
            ...event, 
            attendees: action.payload.attendees,
            version: action.payload.version
          };
        } else {
          return event;
        }
      })
      state.error = null;
    },
    [getEvent.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [scheduleEvent.pending]: (state, action) => {
      state.loading = true;
    },
    [scheduleEvent.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventList = state.eventList.map(event => {
        if (event.id === action.payload.toString()) {
          return {
            ...event,
            version: event.version+1,
          }
        } else {
          return event;
        }
      });
      state.error = null;
    },
    [scheduleEvent.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [addAttendee.pending]: (state, action) => {
      state.loading = true;
    },
    [addAttendee.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventList = state.eventList.map(event => {
        if (event.id === action.payload.id) {
          return {
            ...event,
            attendees: action.payload.attendees,
          }
        } else {
          return event;
        }
      });
      state.error = null;
    },
    [addAttendee.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateAttendee.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAttendee.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventList = state.eventList.map(event => {
        if (event.id === action.payload.id) {
          return {
            ...event,
            attendees: action.payload.attendees,
          }
        } else {
          return event;
        }
      });
      state.error = null;
    },
    [updateAttendee.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [removeAttendee.pending]: (state, action) => {
      state.loading = true;
    },
    [removeAttendee.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventList = state.eventList.map(event => {
        if (event.id === action.payload.id) {
          return {
            ...event,
            attendees: action.payload.attendees,
          }
        } else {
          return event;
        }
      });
      state.error = null;
    },
    [removeAttendee.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateEvent.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEvent.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventList = state.eventList.map(event => {
        if (event.id === action.payload.toString()) {
          return {
            ...event,
            name: state.name,
            type: state.type,
            url: state.url,
            eventState: state.eventState,
            result: state.result,
            summary: state.summary,
            plays: state.plays,
            tags: state.tags
          }
        } else {
          return event;
        }
      });
      state.error = null;
      state.uError = null;
    },
    [updateEvent.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [removeEvent.pending]: (state, action) => {
      state.loading = true;
    },
    [removeEvent.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventList = state.eventList.filter((current) => current.id !== action.payload.toString() );
      state.error = null;
      state.uError = null;
    },
    [removeEvent.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
})

export const { eventAdded, eventAttendees, changeName, changeId, changeEventState, changeEventTime, changeEventLocation, changeUrl, changeResult, changeTags,
  changeEventType, changeSummary, changePlays, changeAttendees, participantAdded, participantPlayerId, participantPlayerName, participantTeamId, participantTeamName,
  participantShirtColor, participantShirtNumber, participantIsGuest, participantGroupName, removeParticipant, changeParticipants, playAdded, playName, playLink, 
  playPlayers, playSuggestion, playRating, playChangeType, playStart, playEnd, playCall, playSource, removePlay, playActionAdded, playActionShirtColor, playActionShirtNumber, 
  playActionCode, playActionRemove, scheduleAdded, scheduleName, scheduleTime, scheduleLocation, scheduleTheme, scheduleTeams, changeSchedules, removeSchedule, changeTeamStats, 
  statsAdded, statsTeamId, statsTeamName, statsMatchesPlayed, statsWins, statsLosses, statsDraws, statsGoalsFor, statsGoalsAgainst, statsYellowCards, statsRedCards, 
  statsPoints, removeStats, changeTeamPlayers, changeCurView, changeGameFilter, changeViewType, changePlayerSelected } = eventSlice.actions
export default eventSlice.reducer