import React from "react";

// components

export default function ParentRule() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
        <div className="px-6">
          <div className="text-center mt-12">
            <ul>
              <li className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                 Signup and plan early
              </li>
              <li className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                 Volunteer appreciated
              </li>
              <li className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                 Arrive 10 minutes early 
              </li>
            </ul>
          </div>
          <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-9/12 px-4">
                <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                  Thanks a lot for the parents' help to keep this going, without your continuous support
                  and help, we would not be able to carry on. <br></br>
                  
                  We encourage parents to plan early and treat it seariously like a regular competitive NorCal game.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
