import React from "react";
import { connect } from "react-redux";
import { changeNewPassword } from "users/userSlice";
import { changeZipCode } from "users/userSlice";
import { changeOldPassword } from "users/userSlice";
import { changeAddress } from "users/userSlice";
import { changePhoneNumber } from "users/userSlice";
import { changeUserName, changeFullName } from "users/userSlice";

class ParentInfo extends React.Component {

  handleChangeUserName = event => {
    event.preventDefault();
    this.props.dispatch(changeUserName(event.target.value));
  }

  handleChangeFullName = event => {
    event.preventDefault();
    this.props.dispatch(changeFullName(event.target.value));
  }

  handleChangePhoneNumber = event => {
    event.preventDefault();
    this.props.dispatch(changePhoneNumber(event.target.value));
  }

  handleChangeZipCode = event => {
    event.preventDefault();
    this.props.dispatch(changeZipCode(event.target.value));
  }

  handleChangeAddress = event => {
    event.preventDefault();
    this.props.dispatch(changeAddress(event.target.value));
  }

  handleChangeOldPassword = event => {
    event.preventDefault();
    this.props.dispatch(changeOldPassword(event.target.value));
  }

  handleChangeNewPassword = event => {
    event.preventDefault();
    this.props.dispatch(changeNewPassword(event.target.value));
  }

  render() {
    const { userName, emailAddress, fullName, phoneNumber, zipCode, address, oldPassword, newPassword } = this.props;
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Parent Information
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Username
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="UserName"
                name="userName" 
                value={userName} onChange={this.handleChangeUserName} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Email address
              </label>
              <input
                type="email"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Email"
                name="emailAddress" 
                disabled={true}
                defaultValue={emailAddress} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Full Name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="FullName"
                name="fullName" 
                value={fullName} onChange={this.handleChangeFullName} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Phone NUmber
              </label>
              <input
                type="tel"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="PhoneNumber"
                name="phoneNumber" 
                value={phoneNumber} onChange={this.handleChangePhoneNumber} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Zip Code
              </label>
              <input
                type="text"
                pattern="[0-9]{5,6}"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="ZipCode"
                name="zipCode" 
                value={zipCode} onChange={this.handleChangeZipCode} 
              />
            </div>
          </div>
          <div className="w-full lg:w-8/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Address
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Address"
                name="address" 
                value={address} onChange={this.handleChangeAddress} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Old Password
              </label>
              <input
                type="password"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Old Password"
                name="oldPassword"
                value={oldPassword} onChange={this.handleChangeOldPassword} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                New Password
              </label>
              <input
                type="password"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="New Password"
                name="newPassword"
                value={newPassword} onChange={this.handleChangeNewPassword} 
              />
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  emailAddress: state.users.emailAddress,
  fullName: state.users.fullName,
  phoneNumber: state.users.phoneNumber,
  zipCode: state.users.zipCode,
  address: state.users.address,
  userName: state.users.userName,
  oldPassword: state.users.oldPassword,
  newPassword: state.users.newPassword,
  isRegistered: state.users.isRegistered,
  error: state.users.error
});

export default connect(mapStateToProps)(ParentInfo);
