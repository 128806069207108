import React from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import { forwardRef } from 'react';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { changeName } from "teams/teamSlice";
import { changeCity } from "teams/teamSlice";
import { changeState } from "teams/teamSlice";
import { changePlayerBirthYear } from "teams/teamSlice";
import { changePracticeField } from "teams/teamSlice";
import { changeCompetitiveLevel } from "teams/teamSlice";
import { changeCoach } from "teams/teamSlice";
import { changeCoachEmail } from "teams/teamSlice";
import { changeManager } from "teams/teamSlice";
import { changeManagerEmail } from "teams/teamSlice";
import { removeTeam } from "teams/teamSlice";
import { listTeams } from "teams/teamSlice";
import { changeId } from "teams/teamSlice";
import { listMyTeams } from "teams/teamSlice";
import { changePlayers } from "teams/teamSlice";

const tableIcons = {
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
};

class TeamTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      selectedRow: null
    }; 
  }

  componentDidMount() {
    const { currentUser } = this.props;
    if (currentUser) {
      if (currentUser.roles) {
        if (currentUser.roles.includes('ROOT') || currentUser.roles.includes('ADMIN')) {
          this.props.dispatch(listTeams());
        }
        if (currentUser.roles.includes('MANAGER') || currentUser.roles.includes('COACH') || currentUser.roles.includes('FILMING')) {
          this.props.dispatch(listMyTeams());
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    if (currentUser && currentUser !== prevProps.currentUser) {
      if (currentUser.roles) {
        if (currentUser.roles.includes('ROOT') || currentUser.roles.includes('ADMIN')) {
          this.props.dispatch(listTeams());
        }
        if (currentUser.roles.includes('MANAGER') || currentUser.roles.includes('COACH') || currentUser.roles.includes('FILMING')) {
          this.props.dispatch(listMyTeams());
        }
      }
    }
  }

  setSelectedRow = row => {
    this.setState({
      selectedRow: row
    });
    const { teamList } = this.props;
    const team = teamList[row];
    this.props.dispatch(changeName(team.name));
    this.props.dispatch(changeId(team.id));
    this.props.dispatch(changeCity(team.city));
    this.props.dispatch(changeState(team.state));
    this.props.dispatch(changePlayerBirthYear(team.playerBirthYear));
    this.props.dispatch(changePracticeField(team.practiceField));
    this.props.dispatch(changeCompetitiveLevel(team.competitiveLevel));
    this.props.dispatch(changeCoach(team.coach));
    this.props.dispatch(changeCoachEmail(team.coachEmail));
    this.props.dispatch(changeManager(team.manager));
    this.props.dispatch(changeManagerEmail(team.managerEmail));
    this.props.dispatch(changePlayers(team.players));
  }

  clickDelete = team => {
    this.props.dispatch(removeTeam(team))
  }

  render() {
    const title = "Teams";
    const columns = [
      { title: 'id', field: 'id' },
      { title: 'members', field: 'members', hidden: true },
      { title: 'name', field: 'name' },
      { title: 'city', field: 'city' },
      { title: 'state', field: 'state' },
      { title: 'playerBirthYear', field: 'playerBirthYear' },
      { title: 'practiceField', field: 'practiceField', hidden: true },
      { title: 'competitiveLevel', field: 'competitiveLevel', hidden: true },
      { title: 'coach', field: 'coach', hidden: true },
      { title: 'coachEmail', field: 'coachEmail', hidden: true },
      { title: 'manager', field: 'manager', hidden: true },
      { title: 'managerEmail', field: 'managerEmail', hidden: true }
    ];
    const { teamList, error } = this.props;
    const data = teamList.map(team => {
      return {
        id: team.id,
        members: team.members,
        name: team.name,
        city: team.city,
        state: team.state,
        playerBirthYear: team.playerBirthYear,
        practiceField: team.practiceField,
        competitiveLevel: team.competitiveLevel,
        coach: team.coach,
        coachEmail: team.coachEmail,
        manager: team.manager,
        managerEmail: team.managerEmail
      }
    });
    // const data = [{id:'123', members:['zhi','eli'], name:'invitation', city: 'pa', state: 'ca', playerBirthYear: '2012', practiceField: 'greer', 
    // competitiveLevel:'gold', coach: 'zhi', coachEmail: '0', manager: '', managerEmail:''}];
    const tableActions = [
      {
        icon: () => <DeleteIcon />,
        tooltip: 'Delete Entry',
        onClick: (event, rowData) => this.clickDelete(rowData)
      }
    ];
    
    const { selectedRow } = this.state;
    return (
      <div style={{ maxWidth: "100%", paddingTop: "12px" }}>
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        detailPanel={rowData => {
          if (rowData.members) {
            return rowData.members.map((member) => <p>{member}</p>)
          } else {
            return '';
          }
        }}
        icons={tableIcons}
        actions={tableActions}
        onRowClick={((evt, selectedRow) => this.setSelectedRow(selectedRow.tableData.id))}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF"
          },
          rowStyle: 
            rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
          padding: 'dense',
          actionsColumnIndex: -1,
          pageSize: 10
        }}
      />
      <div className="text-red-500 text-center">{error}</div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  teamList: state.teams.teamList,
  error: state.teams.error
});

export default connect(mapStateToProps)(TeamTable);
