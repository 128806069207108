import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import Cookies from 'universal-cookie';

export const listVenues = createAsyncThunk("venues/listVenues", async (zipCode, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.get("/api/show_venues", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        zip: zipCode
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const addVenue = createAsyncThunk("venues/addVenue", async (venue, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/add_venue", venue, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const removeVenue = createAsyncThunk("venues/removeVenue", async (venue, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.delete("/api/remove_venue/" + venue.id.substring(0,3) + "/" + venue.zip + "/" + venue.id, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

const initialState = {
  name: '',
  type: '',
  id: '',
  zip: '',
  latitude: '',
  longitude: '',
  url: '',
  address: '',
  description: '',
  venueList: [],
  loading: false,
  aError: null,
  error: null
}

const eventSlice = createSlice({
  name: 'venues',
  initialState,
  reducers: {
    changeName(state, action) {
      state.name = action.payload;
      state.aError = null;
    },
    changeType(state, action) {
      state.type = action.payload;
      state.aError = null;
    },
    changeZip(state, action) {
      state.zip = action.payload;
      state.aError = null;
    },
    changeLatitude(state, action) {
      state.latitude = action.payload;
      state.aError = null;
    },
    changeLongitude(state, action) {
      state.longitude = action.payload;
      state.aError = null;
    },
    changeUrl(state, action) {
      state.url = action.payload;
      state.aError = null;
    },
    changeAddress(state, action) {
      state.address = action.payload;
      state.aError = null;
    },
    changeDescription(state, action) {
      state.description = action.payload;
      state.aError = null;
    },
    resetError(state, action) {
      state.aError = null;
      state.error = null;
    }
  },
  extraReducers: {
    [listVenues.pending]: (state, action) => {
      state.loading = true;
    },
    [listVenues.fulfilled]: (state, action) => {
      state.loading = false;
      state.venueList = action.payload;
    },
    [listVenues.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [addVenue.pending]: (state, action) => {
      state.loading = true;
    },
    [addVenue.fulfilled]: (state, action) => {
      state.loading = false;
      state.venueList = state.venueList.concat({
        name: state.name, id: action.payload, zip: state.zip, 
        latitude: state.latitude, longitude: state.longitude, 
        url: state.url, address: state.address, description: state.description
      });
    },
    [addVenue.rejected]: (state, action) => {
      state.aError = action.payload;
      state.loading = false;
    },
    [removeVenue.pending]: (state, action) => {
      state.loading = true;
    },
    [removeVenue.fulfilled]: (state, action) => {
      state.loading = false;
      state.venueList = state.venueList.filter((current) => current.id !== action.payload);
    },
    [removeVenue.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
})

export const { changeName, changeType, changeZip, changeLatitude, changeLongitude, changeUrl, 
  changeAddress, changeDescription, resetError } = eventSlice.actions
export default eventSlice.reducer