import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { changeTeamId } from "developments/developmentSlice";
import { changePlayerId } from "developments/developmentSlice";
import { changePlayerName } from "developments/developmentSlice";
import { changeContactEmail } from "developments/developmentSlice";
import { changeBirthYear } from "developments/developmentSlice";

class DevelopmentInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      teamName: '',
      teamId: '',
    }; 
  }

  handleChangePlayerId = development => {
    development.preventDefault();
    this.props.dispatch(changePlayerId(development.target.value));
  }

  handleChangePlayerName = development => {
    development.preventDefault();
    this.props.dispatch(changePlayerName(development.target.value));
  }

  handleChangeContactEmail = development => {
    development.preventDefault();
    this.props.dispatch(changeContactEmail(development.target.value));
  }

  handleChangeTeam = team => {
    this.setState({
      teamName: team.label,
      teamId: team.value
    });
    this.props.dispatch(changeTeamId(team.value));
  }

  handleChangeBirthYear = development => {
    development.preventDefault();
    this.props.dispatch(changeBirthYear(development.target.value));
  }

  render() {
    const { playerName, playerId, contactEmail, teamId, birthYear, teams } = this.props;
    const { teamName } = this.state;
    const teamSelected = {label: teamName, value: teamId};
    const teamOptions = teams.map(team => { return {label: team.name, value: team.id} });
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Development Information
        </h6>
        <div className="flex flex-wrap">
           <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Player Name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="PlayerName"
                name="playerName" 
                value={playerName} onChange={this.handleChangePlayerName} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Player Id
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="PlayerId"
                name="playerId" 
                value={playerId} onChange={this.handleChangePlayerId} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Contact Email
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="ContactEmail"
                name="contactEmail" 
                value={contactEmail} onChange={this.handleChangeContactEmail} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Team
              </label>
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={teamOptions} value={teamSelected} required onChange={this.handleChangeTeam} />
               </div>
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Birth Year
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="BirthYear"
                name="birthYear" 
                value={birthYear} onChange={this.handleChangeBirthYear} 
              />
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  teams: state.teams.teams,
  playerId: state.developments.playerId,
  playerName: state.developments.playerName,
  contactEmail: state.developments.contactEmail,
  birthYear: state.developments.birthYear,
  error: state.developments.error
});

export default connect(mapStateToProps)(DevelopmentInfo);
