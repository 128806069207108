import React from "react";
import { connect } from "react-redux";
import VenueInfo from "components/Cards/VenueInfo.js";
import VenueTable from "components/Cards/VenueTable.js";
import { addVenue } from "venues/venueSlice";

class Venue extends React.Component {

  handleAddVenue = event => {
    event.preventDefault();
    const { name, id, zip, latitude, longitude, url, address, description } = this.props;
    this.props.dispatch(addVenue({
      name: name, id: id, zip: zip, latitude: latitude, longitude: longitude, url: url, address: address, description: description}));
  }

  render() {
    const { aError } = this.props;
    return (
      <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={this.handleAddVenue}>
                <VenueInfo />
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-2 py-5">
                    <div className="relative w-full mb-1 text-center">
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit" name="submit"
                      >
                        Add
                      </button>
                    </div>
                    <div className="text-red-500 text-center">{aError}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-4">
          <VenueTable />
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  name: state.venues.name,
  id: state.venues.id,
  zip: state.venues.zip,
  latitude: state.venues.latitude,
  longitude: state.venues.longitude,
  url: state.venues.url,
  address: state.venues.address,
  description: state.venues.description,
  aError: state.venues.aError
});

export default connect(mapStateToProps)(Venue);
