import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-multi-date-picker"
import { volunteerServeTime } from "engagements/engagementSlice";
import { volunteerRole } from "engagements/engagementSlice";
import { removeVolunteer } from "engagements/engagementSlice";

class VolunteerInfo extends React.Component {
  handleRemoveVolunteer = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(removeVolunteer(id));
  }

  handleChangeVolunteerType = volunteerType => {
    const { id } = this.props;
    this.props.dispatch(volunteerRole(id, volunteerType.value));
  }

  handleChangeServeTime = e => {
    const { id } = this.props;
    const day = (new Date(e)).getTime();
    this.props.dispatch(volunteerServeTime(id, day));
  }

  render() {
    const { volunteer } = this.props;
    const { role, serveTime } = volunteer;
    const volunteerSelected = {label: role, value: role};
    const volunteerOptions = [{label: 'Ref', value: 'Ref'}, {label: 'Coach', value: 'Coach'}, {label: 'Goalie', value: 'Goalie'}, {label: 'Filming', value: 'Filming'}, {label: 'AR', value: 'AR'}, {label: 'OTHER', value: 'OTHER'}];
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Volunteer Information
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                date
              </label>
              <DatePicker 
                inputClass="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                format="YYYY-MM-DD"
                value={serveTime} 
                onChange={this.handleChangeServeTime}
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Role
              </label>
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={volunteerOptions} value={volunteerSelected} onChange={this.handleChangeVolunteerType} />
              </div>
            </div>
          </div>
          <div className="px-4 py-4 md:px-10 mx-auto w-full text-center">
            <button
                className="font-normal text-lightBlue-500 px-6"
                onClick={this.handleRemoveVolunteer}
            >
              <i className="fas fa-trash-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
              remove volunteer
            </button>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  error: state.engagements.error
});

export default connect(mapStateToProps)(VolunteerInfo);
