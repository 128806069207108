import React from "react";
import { useEffect, useRef } from "react";

export default function Canvas() {
  const canvasRef = useRef(null);

  const saveImage = () => {
    const canvas = canvasRef.current;
    const dataUrl = canvas.toDataURL('image/png');
    
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = 'canvas.png';
    link.click();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const draw = (event) => {
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      
      context.beginPath();
      context.arc(x, y, 10, 0, 2 * Math.PI);
      context.fillStyle = 'green';
      context.fill();
    };

    canvas.addEventListener('mousedown', draw);
    return () => {
      canvas.removeEventListener('mousedown', draw);
    };
    
    // context.beginPath();
    // context.arc(100, 100, 50, 0, 2 * Math.PI);
    // context.fillStyle = 'red';
    // context.fill();
  }, []);
    
  return (
    <div>
      <canvas ref={canvasRef} />
      <button onClick={saveImage}>Save Image</button>
    </div>
  )
}
