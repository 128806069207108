export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month].join('-');
}

export function formatDateToDay(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

export function getLocalDate(date) {
  const offset = new Date().getTimezoneOffset() * 1000 * 60;
  const offsetDate = new Date(date).valueOf() - offset;
  const newDate = new Date(offsetDate).toISOString();
  return newDate.substring(0, 16);
}

export function getLocalMonth(date) {
  const offset = new Date().getTimezoneOffset() * 1000 * 60;
  const offsetDate = new Date(date).valueOf() - offset;
  const newDate = new Date(offsetDate).toISOString();
  return newDate.substring(0, 7);
}

export function formatToDay(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate()
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [month, day].join('-');
}

export function formatDateArray(inputArray) {
  if (!inputArray) {
    return '';
  }
  var days = inputArray.map(day => formatToDay(day));
  return days.join('/');
}

export function formatPaymentArray(inputArray) {
  if (!inputArray) {
    return '';
  }
  var pays = inputArray.map(pay => pay.amount);
  return pays.join('/');
}

export function formatVolunteerArray(inputArray) {
  if (!inputArray) {
    return '';
  }
  var volunteers = inputArray.map(volunteer => volunteer.role);
  return volunteers.join('/');
}

export function formatNumArray(inputArray) {
  if (!inputArray) {
    return '';
  }
  return inputArray.join('/');
}