import { changeEventState } from "events/eventSlice";
import { changeUrl } from "events/eventSlice";
import { changeSummary } from "events/eventSlice";
import { changeEventType } from "events/eventSlice";
import { changeTags } from "events/eventSlice";
import { changeResult } from "events/eventSlice";
import { changeName } from "events/eventSlice";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

class EventInfo extends React.Component {

  handleChangeName = event => {
    event.preventDefault();
    this.props.dispatch(changeName(event.target.value));
  }

  handleChangeTag = tag => {
    const tagSelected = tag.map(cur => cur.value);
    this.props.dispatch(changeTags(tagSelected));
  }

  handleChangeEventType = eventType => {
    this.props.dispatch(changeEventType(eventType.value));
  }

  handleChangeEventState = eventState => {
    this.props.dispatch(changeEventState(eventState.value));
  }

  handleChangeUrl = event => {
    event.preventDefault();
    this.props.dispatch(changeUrl(event.target.value));
  }

  handleChangeResult = event => {
    event.preventDefault();
    this.props.dispatch(changeResult(event.target.value));
  }

  handleChangeSummary = event => {
    event.preventDefault();
    this.props.dispatch(changeSummary(event.target.value));
  }

  render() {
    const { name, eventState, type, url, result, summary, teams, tags } = this.props;
    const eventSelected = {label: eventState, value: eventState};
    const stateOptions = [{label: 'PENDING', value: 'PENDING'}, {label: 'FINISHED', value: 'FINISHED'}, {label: 'CANCELLED', value: 'CANCELLED'}, {label: 'ONGOING', value: 'ONGOING'}];
    const typeOptions = [{label: 'GAME', value: 'GAME'}, {label: 'TOURNAMENT', value: 'TOURNAMENT'}, {label: 'PRACTICE', value: 'PRACTICE'}];
    const typeSelected = {label: type, value: type};
    const tagOptions = teams.map(team => { return {label: team.name, value: team.id} });
    const tagSelected = tags ? tags.map(cur => { return {label: cur, value: cur} }) : [];
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold px-4 uppercase">
          Event Information
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Event Type
              </label>
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={typeOptions} value={typeSelected} required onChange={this.handleChangeEventType} />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Event State
              </label>
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={stateOptions} value={eventSelected} required onChange={this.handleChangeEventState} />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Result
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Result"
                name="result" 
                value={result} onChange={this.handleChangeResult} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Name"
                name="name" 
                value={name} onChange={this.handleChangeName} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                Tag Teams
              </label> 
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={tagOptions} value={tagSelected} required isMulti onChange={this.handleChangeTag} />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Url
              </label>
              <input
                type="url"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Url"
                name="url" 
                value={url} onChange={this.handleChangeUrl} 
              />
            </div>
          </div>
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Summary
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Summary"
                name="summary" 
                value={summary} onChange={this.handleChangeSummary} 
              />
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  teams: state.teams.teams,
  name: state.events.name,
  type: state.events.type,
  eventState: state.events.eventState,
  eventTime: state.events.eventTime,
  eventLocation: state.events.eventLocation,
  url: state.events.url,
  result: state.events.result,
  summary: state.events.summary,
  tags: state.events.tags,
  error: state.events.error
});

export default connect(mapStateToProps)(EventInfo);
