import { paymentSubmitTime } from "engagements/engagementSlice";
import { paymentMethod } from "engagements/engagementSlice";
import { removePayment } from "engagements/engagementSlice";
import { paymentAmount } from "engagements/engagementSlice";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-multi-date-picker"

class PaymentInfo extends React.Component {
  handleRemovePayment = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(removePayment(id));
  }

  handleChangeAmount = e => {
    e.preventDefault();
    const { id } = this.props;
    this.props.dispatch(paymentAmount(id, e.target.value));
  }

  handleChangePayType = payType => {
    const { id } = this.props;
    this.props.dispatch(paymentMethod(id, payType.value));
  }

  handleChangeSubmitTime = e => {
    const { id } = this.props;
    const day = (new Date(e)).getTime();
    this.props.dispatch(paymentSubmitTime(id, day));
  }

  render() {
    const { payment } = this.props;
    const { amount, method, submitTime } = payment;
    const paymentSelected = {label: method, value: method};
    const paymentOptions = [{label: 'PayPal', value: 'PayPal'}, {label: 'AppleCash', value: 'AppleCash'}, {label: 'Wechat', value: 'Wechat'}, {label: 'Cash', value: 'Cash'}, {label: 'OTHER', value: 'OTHER'}];
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Payment Information
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                date
              </label>
              <DatePicker 
                inputClass="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                format="YYYY-MM-DD"
                value={submitTime} 
                onChange={this.handleChangeSubmitTime}
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Amount
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Amount"
                name="amount" 
                value={amount} onChange={this.handleChangeAmount} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Method
              </label>
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={paymentOptions} value={paymentSelected} onChange={this.handleChangePayType} />
              </div>
            </div>
          </div>
          <div className="px-4 py-4 md:px-10 mx-auto w-full text-center">
            <button
                className="font-normal text-lightBlue-500 px-6"
                onClick={this.handleRemovePayment}
            >
              <i className="fas fa-trash-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
              remove payment
            </button>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  error: state.engagements.error
});

export default connect(mapStateToProps)(PaymentInfo);
