import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { changeCurrentMatch } from "developments/developmentSlice";
import { changeCurrentMonth } from "developments/developmentSlice";

class DevSide extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      collapseShow: "hidden"
    }; 
  }

  componentDidMount() {
  }

  setCollapseShow(collapse) {
    this.setState({
      collapseShow: collapse
    });
  }

  nextMatch() {
    const { curMatch } = this.props;
    this.props.dispatch(changeCurrentMatch(curMatch-1));
  }

  prevMatch() {
    const { curMatch } = this.props;
    this.props.dispatch(changeCurrentMatch(curMatch+1));
  }

  handleChangeMonth = event => {
    event.preventDefault();
    this.props.dispatch(changeCurrentMonth(event.target.value));
    this.props.dispatch(changeCurrentMatch(0));
  }

  render() {
    const {curMonth, curMatch, curMatches } = this.props;
    var disableNext = false;
    var disablePrev = false;
    if (!curMatches) {
      disableNext = true;
      disablePrev = true;
    }
    if (curMatches && curMatches.length) {
      disablePrev = curMatch >= curMatches.length - 1;
      disableNext = curMatch <= 0;
    }
    var curGame;
    if (curMatches && curMatches.length > 0 && curMatch < curMatches.length) {
      curGame = curMonth + ' Match ' + (curMatch + 1) + ' / ' + curMatches.length;
    }

    return (
      <>
        <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
          <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
            {/* Toggler */}
            <button
              className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              type="button"
              onClick={() => this.setCollapseShow("bg-white m-2 py-3 px-6")}
            >
              <i className="fas fa-bars"></i>
            </button>
            <Link
              className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
              to="/"
            >
              Falinks Net
            </Link>
   
            {/* Collapse */}
            <div
              className={
                "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                this.state.collapseShow
              }
            >
              {/* Collapse header */}
              <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                <div className="flex flex-wrap">
                  <div className="w-6/12">
                    <Link
                      className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                      to="/"
                    >
                      Falinks Net
                    </Link>
                  </div>
                  <div className="w-6/12 flex justify-end">
                    <button
                      type="button"
                      className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                      onClick={() => this.setCollapseShow("hidden")}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            
              {/* Divider */}
              <hr className="my-4 md:min-w-full" />
              <div className="md:min-w-full px-2">
                <div className="relative w-full mb-3">
                  <input
                    type="month"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Month"
                    name="month" 
                    value={curMonth} onChange={this.handleChangeMonth} 
                  />
                </div>
              </div>
              {/* Heading */}
              <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                {curGame}
              </h6>
              {/* Navigation */}
              <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                {(disablePrev === false) && <li className="items-center">
                  <Link
                    className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                    to={`/players`} onClick={() => this.prevMatch()}
                  >
                    <i className="fas fa-angle-left text-blueGray-400 mr-2 text-sm"></i>{" "}
                    Prev
                  </Link>
                </li>}

                {(disableNext === false) && <li className="items-center">
                  <Link
                    className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                    to="/players" onClick={() => this.nextMatch()}
                  >
                    <i className="fas fa-angle-right text-blueGray-400 mr-2 text-sm"></i>{" "}
                    Next
                  </Link>
                </li>}
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  curMonth: state.developments.currentMonth,
  curMatch: state.developments.currentMatch,
  curMatches: state.developments.curMatches,
});

export default connect(mapStateToProps)(DevSide);
