import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { participantTeamId } from "events/eventSlice";
import { participantTeamName } from "events/eventSlice";
import { changeTeamPlayers } from "events/eventSlice";
import { participantPlayerId } from "events/eventSlice";
import { participantShirtColor } from "events/eventSlice";
import { participantShirtNumber } from "events/eventSlice";
import { participantIsGuest } from "events/eventSlice";
import { removeParticipant } from "events/eventSlice";
import { participantPlayerName } from "events/eventSlice";
import { participantGroupName } from "events/eventSlice";

class ParticipantInfo extends React.Component {

  handleRemoveParticipant = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(removeParticipant(id));
  }

  handleChangeTeam = team => {
    const { id, teams } = this.props;
    const curTeam = teams.find((cur) => cur.id === team.value);
    if (curTeam && curTeam.players) {
      this.props.dispatch(changeTeamPlayers(curTeam.players));
    }
    this.props.dispatch(participantTeamName(id, team.label));
    this.props.dispatch(participantTeamId(id, team.value));
  }

  handleChangeParticipant = part => {
    const { id } = this.props;
    this.props.dispatch(participantPlayerId(id, part.value));
    this.props.dispatch(participantPlayerName(id, part.label));
  }

  handleChangeShirtColor = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(participantShirtColor(id, event.target.value));
  }

  handleChangeShirtNumber = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(participantShirtNumber(id, event.target.value));
  }

  handleChangeIsGuest = event => {
    const { id } = this.props;
    this.props.dispatch(participantIsGuest(id, event.value));
  }

  handleChangeGroupName = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(participantGroupName(id, event.target.value));
  }

  render() {
    const { participant, teamPlayers, teams } = this.props;
    const { playerId, playerName, teamId, teamName, shirtColor, shirtNumber, guest, groupName } = participant;

    const teamOptions = teams.map(team => { return {label: team.name, value: team.id} });
    const teamSelected = {label: teamName, value: teamId};
    const partOptions = teamPlayers.map(part => { return {label: part.name, value: part.id} });
    const partSelected = {label: playerName, value: playerId};
    const guestOptions = [{label: 'true', value: true}, {label: 'false', value: false}];
    const guestSelected = {label: (guest) ? 'true' : 'false', value: guest};
    
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Participant Information
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                Team
              </label> 
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={teamOptions} value={teamSelected} required onChange={this.handleChangeTeam} />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                Player
              </label> 
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={partOptions} value={partSelected} required onChange={this.handleChangeParticipant} />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Shirt Color
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="ShirtColor"
                name="shirtColor" 
                value={shirtColor} onChange={this.handleChangeShirtColor} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Shirt Number
              </label>
              <input
                type="number"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="ShirtNumber"
                name="shirtNumber" 
                value={shirtNumber} onChange={this.handleChangeShirtNumber} 
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Is Guest
              </label>
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={guestOptions} value={guestSelected} required onChange={this.handleChangeIsGuest} />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Group Name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="GroupName"
                name="groupName" 
                value={groupName} onChange={this.handleChangeGroupName} 
              />
            </div>
          </div>
          <div className="px-4 py-4 md:px-10 mx-auto w-full text-center">
            <button
                className="font-normal text-lightBlue-500 px-6"
                onClick={this.handleRemoveParticipant}
            >
              <i className="fas fa-trash-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
              remove participant
            </button>
         </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.teams.teams,
  teamPlayers: state.events.teamPlayers,
  participants: state.events.participants,
  error: state.events.error
});

export default connect(mapStateToProps)(ParticipantInfo);
